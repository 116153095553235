import React from "react";
import Header from "../../Header";
import PlaneCalender from "./PlaneCalender";

import Container from 'react-bootstrap/Container';
import PlaneCalendarRightWindow from "./PlaneCalendarRightWindow";
import PlaneAdminMenu from "./PlaneAdminMenu";

class AdminPlanePage extends React.Component {

    render() {
        return (
            <Header>
                <Container fluid={true}>
                    <PlaneAdminMenu planeId={this.props.planeId} selected="Calendar"></PlaneAdminMenu>
                    <PlaneCalender>
                    </PlaneCalender>
                    <PlaneCalendarRightWindow>
                    </PlaneCalendarRightWindow>
                </Container>
            </Header>
        )
    }
}

export default AdminPlanePage;