import React from "react";
import Header from './Header';
import Container from 'react-bootstrap/Container';
import Form from "react-bootstrap/Form";
import FormError from "./FormError";
import AutoCompleteInput from './AutoCompleteInput';
import connect from "react-redux/es/connect/connect";
import Button from "react-bootstrap/Button";
import validateFields from "./utils";
import SwaggerUtility from './SwaggerUtility';
import poweredByGoogleLogo from './images/powered_by_google_on_white.png';
import {getFormattedDate, logger} from './Utilities';
import {FilePond} from "react-filepond";
import DatePicker from "react-datepicker";
import {withRouter} from "react-router";

class ListYourPlaneComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            errors:{},
            type:"Single Engine Land",
            year:null,
            make:null,
            model:null,
            totalTime:null,
            locationId:null,
            steamGauges:null,
            description:null,
            avionics:null,
            performanceSpecifications:null,
            latitude:null,
            longitude:null,
            locationName:null,
            interior:[],
            exterior:[],
            medical:[],
            arrow:[],
            av1aes:[],
            license: [],
            owner:[],
            eltDate:null,
            eltDatePickerDate:null,
            eltPicture:null,
            oneHundredHourInspectionDatePickerDate:null,
            oneHundredHourInspectionDate:null,
            oneHundredHourInspectionImage:null,
            altimeterImage:null,
            vorInspectionImage:null,
            staticInspectionImage:null,
            transponderImage:null,
            altimeterDatePickerDate:null,
            vorInspectionDatePickerDate:null,
            staticInspectionDatePickerDate:null,
            transponderPickerDate:null,
            altimeterDate:null,
            vorInspectionDate:null,
            staticInspectionDate:null,
            transponderDate:null,
            checkRequired:"Yes",
            place_id:null,
        }
    }

    createImagesFromArray = (imageArray,imageType,aircraftId) => {
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        logger("image array");
        logger(imageArray);
        for (var pics = 0; pics < imageArray.length; pics++) {
            let pic = imageArray[pics];
            let imageData = {
                image: pic.file,
                type: imageType,
                aircraft: aircraftId
            };
            logger("imageData");
            logger(imageData);
                swaggerClient.then((client) => {
                    client.apis
                        .aircraftImage.aircraftImage_create(
                        imageData
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Aircraft Image");
                                logger(result);
                                logger(result.obj);
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
        }
    };

    onChangePilotAirport = (locationInfo) => {
        this.setState({locationId:locationInfo.id});
        this.setState({place_id:locationInfo.place_id});
        this.setState({locationName:locationInfo.description});
    };

    createImages = (airCraftId) => {
        this.createImagesFromArray(this.state.interior,"interior",airCraftId);
        this.createImagesFromArray(this.state.exterior,"exterior",airCraftId);
        this.createImagesFromArray(this.state.medical,"medical",airCraftId);
        this.createImagesFromArray(this.state.arrow,"arrow",airCraftId);
        this.createImagesFromArray(this.state.av1aes,"av1aes",airCraftId);
        this.createImagesFromArray(this.state.license,"license",airCraftId);
    };

    eltDateChange = (date) => {
        this.setState({eltDate:getFormattedDate(date),eltDatePickerDate:date});
    };

    oneHundredHourInspectionChange = (date) => {
        this.setState({oneHundredHourInspectionDate:getFormattedDate(date),oneHundredHourInspectionDatePickerDate:date});
    };

    altimeter = (date) => {
        this.setState({altimeterDate:getFormattedDate(date),altimeterDatePickerDate:date});
    };

    vorInspection = (date) => {
        this.setState({vorInspectionDate:getFormattedDate(date),vorInspectionDatePickerDate:date});
    };

    staticInspection = (date) => {
        this.setState({staticInspectionDate:getFormattedDate(date),staticInspectionDatePickerDate:date});
    };

    transponder = (date) => {
        this.setState({transponderDate:getFormattedDate(date),transponderDatePickerDate:date});
    };

    createPlane = () => {
        console.log(this.state);
        let result = validateFields(this.state, "aircraftInfoForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if (result[0]) {
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            let aircraftCreationData = Object.assign({},this.state);
            aircraftCreationData["owner"] = this.props.profile.id;
            aircraftCreationData["year"] = parseInt(aircraftCreationData["year"]);
            aircraftCreationData["eltPicture"] = aircraftCreationData["eltPicture"].file;
            aircraftCreationData["oneHundredHourInspectionImage"] = aircraftCreationData["oneHundredHourInspectionImage"].file;
            aircraftCreationData["altimeterImage"] = aircraftCreationData["altimeterImage"].file;
            aircraftCreationData["vorInspectionImage"] = aircraftCreationData["vorInspectionImage"].file;
            aircraftCreationData["staticInspectionImage"] = aircraftCreationData["staticInspectionImage"].file;
            aircraftCreationData["transponderImage"] = aircraftCreationData["transponderImage"].file;
            logger("aircraftCreationData");
            logger(aircraftCreationData);
            swaggerClient.then((client) => {
                client.apis
                    .aircraft.aircraft_create(
                    aircraftCreationData
                )
                    .then(function (result) {
                        if (result.ok) {
                            logger("Created Aircraft");
                            logger(result);
                            logger(result.obj);
                            componentSelf.createImages(result.obj.id);
                            const newRoute = "/planeAdmin/"+ result.obj.id.toString();
                            componentSelf.props.history.push(newRoute);
                        }
                    }).catch(function (error) {
                    logger(error)
                })
            }).catch(function (error) {
                logger(error)
            })
        }
    };
    render(){
        return(
            <Header>
                <Container>
                    <h3 className="formBorderTitle spaced blueText">Aircraft Information</h3>
                    <Form.Group>
                        <Form.Label>
                            Aircraft Type
                        </Form.Label>
                        <Form.Control as="select" onChange={(e) => this.setState({type:e.target.value})}>
                            <option disabled={true}>Airplane</option>
                            <option>Single Engine Land</option>
                            <option>Multi Engine Land</option>
                            <option>Single Engine Sea</option>
                            <option>Multi Engine Sea</option>
                            <option disabled={true}>Rotorcraft</option>
                            <option>Helicopter</option>
                            <option disabled={true}>Glider</option>
                            <option>Glider</option>
                            <option disabled={true}>Lighter Than Air</option>
                            <option>Balloon</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Aircraft Year
                        </Form.Label>
                        <Form.Control type="text" name="year" placeholder="2000" onChange={(e) => this.setState({year:e.target.value})} />
                        <FormError formField="year" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Aircraft Make
                        </Form.Label>
                        <Form.Control type="text" name="make" placeholder="Pilatus" onChange={(e) => this.setState({make:e.target.value})}  />
                        <FormError formField="make" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Aircraft Model
                        </Form.Label>
                        <Form.Control type="text" name="model" placeholder="PC-12 NG" onChange={(e) => this.setState({model:e.target.value})}  />
                        <FormError formField="model" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Aircraft Total Time
                        </Form.Label>
                        <Form.Control type="text" name="totalTime" placeholder="9483" onChange={(e) => this.setState({totalTime:e.target.value})}  />
                        <FormError formField="totalTime" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Aircraft Gauges or Glass Cockpit
                        </Form.Label>
                        <Form.Control type="text" name="steamGauges" placeholder="Gauge" onChange={(e) => this.setState({steamGauges:e.target.value})}  />
                        <FormError formField="steamGauges" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Description
                        </Form.Label>
                        <Form.Control as="textarea" rows="3"  type="text" name="description" placeholder="Describe your plane here" onChange={(e) => this.setState({description:e.target.value})} />
                        <FormError formField="description" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Avionics/Equipment
                        </Form.Label>
                        <Form.Control type="text" name="avionics" as="textarea" rows="3"  placeholder="Avionics" onChange={(e) => this.setState({avionics:e.target.value})}  />
                        <FormError formField="avionics" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Performance Specifications (Hidden Until Booked)
                        </Form.Label>
                        <Form.Control type="text" name="performanceSpecifications" as="textarea" rows="3"  placeholder="Specifications" onChange={(e) => this.setState({performanceSpecifications:e.target.value})} />
                        <FormError formField="performanceSpecifications" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="formLabel greyText">
                            Home Airport
                        </Form.Label>
                        <AutoCompleteInput initValue={this.state.locationName} onChange={this.onChangePilotAirport} parentElement="profile"/>
                        <img src={poweredByGoogleLogo}></img>
                        <FormError formField="locationName" errors = {this.state.errors} />
                    </Form.Group>
                    <h3 className="formBorderTitle spaced blueText">Aircraft Pictures</h3>
                    <Form.Group>
                        <Form.Label>
                            Interior
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        interior: fileItems
                                    });
                                }
                            }}
                            allowMultiple={true}
                            maxFiles={5}/>
                        <FormError formField="interior" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Exterior
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        exterior: fileItems
                                    });
                                }
                            }}
                            allowMultiple={true}
                            maxFiles={5}/>
                        <FormError formField="exterior" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Arrow
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                       arrow: fileItems
                                    });
                                }
                            }}
                            allowMultiple={true}
                            maxFiles={5}/>
                        <FormError formField="arrow" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                            <Form.Label>
                                Av1AES
                            </Form.Label>
                            <FilePond
                                allowFileTypeValidation={true}
                                allowImageExifOrientation={true}
                                acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                                onupdatefiles={fileItems => {
                                    if (fileItems.length > 0){
                                        this.setState({
                                            av1aes: fileItems
                                        });
                                    }
                                }}
                                allowMultiple={true}
                                maxFiles={5}/>
                        <FormError formField="av1aes" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                             License
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        license: fileItems
                                    });
                                }
                            }}
                            allowMultiple={true}
                            maxFiles={5}/>
                        <FormError formField="license" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Medical
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        medical: fileItems
                                    });
                                }
                            }}
                            allowMultiple={true}
                            maxFiles={5}/>
                        <FormError formField="medical" errors = {this.state.errors} />
                    </Form.Group>
                    <h3 className="formBorderTitle spaced blueText">Aircraft Inspection Information</h3>
                    <Form.Group>
                        <Form.Label>
                            ELT Picture
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        eltPicture: fileItems[0]
                                    });
                                }
                            }}
                            allowMultiple={false}
                            maxFiles={1}/>
                        <FormError formField="eltPicture" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            ELT Date
                        </Form.Label>
                        <br/>
                        <DatePicker selected={this.state.eltDatePickerDate} onChange={this.eltDateChange} />
                        <FormError formField="eltDate" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            100 Hour Inspection Picture
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        oneHundredHourInspectionImage: fileItems[0]
                                    });
                                }
                            }}
                            allowMultiple={false}
                            maxFiles={1}/>
                        <FormError formField="oneHundredHourInspectionImage" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            100 Hour Inspection Date
                        </Form.Label>
                        <br/>
                        <DatePicker selected={this.state.oneHundredHourInspectionDatePickerDate} onChange={this.oneHundredHourInspectionChange} />
                        <FormError formField="oneHundredHourInspectionDate" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Altimeter Picture
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        altimeterImage: fileItems[0]
                                    });
                                }
                            }}
                            allowMultiple={false}
                            maxFiles={1}/>
                        <FormError formField="altimeterImage" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Altimeter Date
                        </Form.Label>
                        <br/>
                        <DatePicker selected={this.state.altimeterDatePickerDate} onChange={this.altimeter} />
                        <FormError formField="altimeterDate" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>
                        VOR Inspection Picture
                    </Form.Label>
                    <FilePond
                        allowFileTypeValidation={true}
                        allowImageExifOrientation={true}
                        acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                        onupdatefiles={fileItems => {
                            if (fileItems.length > 0){
                                this.setState({
                                    vorInspectionImage: fileItems[0]
                                });
                            }
                        }}
                        allowMultiple={false}
                        maxFiles={1}/>
                        <FormError formField="vorInspectionImage" errors = {this.state.errors} />
                </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            VOR Inspection Inspection Date
                        </Form.Label>
                        <br/>
                        <DatePicker selected={this.state.vorInspectionDatePickerDate} onChange={this.vorInspection} />
                        <FormError formField="vorInspectionDate" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Static/Pitot Inspection Picture
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        staticInspectionImage: fileItems[0]
                                    });
                                }
                            }}
                            allowMultiple={false}
                            maxFiles={1}/>
                        <FormError formField="staticInspectionImage" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Static/Pitot Inspection Date
                        </Form.Label>
                        <br/>
                        <DatePicker selected={this.state.staticInspectionDatePickerDate} onChange={this.staticInspection} />
                        <FormError formField="staticInspectionDate" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Transponder Picture
                        </Form.Label>
                        <FilePond
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                            onupdatefiles={fileItems => {
                                if (fileItems.length > 0){
                                    this.setState({
                                        transponderImage: fileItems[0]
                                    });
                                }
                            }}
                            allowMultiple={false}
                            maxFiles={1}/>
                        <FormError formField="transponderImage" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Transponder Date
                        </Form.Label>
                        <br/>
                        <DatePicker selected={this.state.transponderDatePickerDate} onChange={this.transponder} />
                        <FormError formField="transponderDate" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            SkyKandy Self Checkout
                        </Form.Label>
                        <Form.Control as="select" onChange={(e) => this.setState({checkoutRequired:e.target.value})}>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" onClick={this.createPlane}>List Plane</Button>
                </Container>
            </Header>
        )
    }
}


const mapStateToProps = state => {
    logger("state update!");
    logger(state);
    return {
        profile: state.profile
    }
};


const ListYourPlane = connect(
    mapStateToProps
)(ListYourPlaneComponent);
export default withRouter(ListYourPlane);