import React from 'react';
import {handleFailedRequest,getMultiCheckValues,logger,getStringFromNull,getFilePondMediaUrl,getBlobOrFile,getFormattedDate} from '../Utilities';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormError from '../FormError'
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../Header';
import AutoCompleteInput from '../AutoCompleteInput';
import SwaggerUtility from '../SwaggerUtility';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import poweredByGoogleLogo from '../images/powered_by_google_on_white.png';
import Alerter from '../Components/Alerter';

import {
    setSignupBirthdayMonth,
    setSignUpPassword,
    setSignupFirstName,
    setSignUpConfirmPassword,
    setSignUpZipcode,
    setSignupBirthdayDay,
    setSignupUsername,
    setSignupUseBirthdayYear,
    setSignupState,
    setSignupMobileNumber,
    setSignupLastName,
    setSignupCity,
    setSignupAddress,
    setSignupAddressTwo,
    setToken, setAuthenticated,
    setPilotTotalInCommandTime,setPilotHasInsurance,
    setAircraftCategory,setMedicalClass,setMedicalClassIssueDay,setMedicalClassIssueMonth,setMedicalClassIssueYear,setPilotTotalTime,setPilotCertifications,setPilotRatingsAndEndorsements,setPilotInsurancePolicyNumber,setPilotInsuranceIssueMonth,setPilotInsuranceIssueDay,setPilotInsuranceIssueYear,setPilotInsuranceExpirationMonth,setPilotInsuranceExpirationDay,setPilotInsuranceCarrier,setPilotInsuranceExpirationYear
} from '../actions.tsx';
import { connect } from 'react-redux';
import validateFields from '../utils'
import {withRouter} from "react-router";
import {FilePond} from "react-filepond";
import {configs} from "../configurations";

class PilotSignupComp extends React.Component{

    constructor(props){
      super(props);
      this.state = {
        errors: {},
        pilotsLicenseValid:false,
        medicalPk:null,
        medicalValid:false,
        credentialsValid:false,
        insuranceValid:false,
        insuranceMethod:"POST",
        credentialsMethod:"POST",
        insurancePk:null,
        insurer:null,
        insuranceNumber:null,
        issueDate:null,
        issueDatePickerDate:null,
        expirationDate:null,
        expirationDatePickerDate:null,
        medicalExpirationDate:null,
        medicalIssueDate:null,
        medicalIssueDatePickerDate:null,
        medicalExpirationDatePickerDate:null,
        medicalCertificationNumber:null,
        medicalClass:"Class A",
        hasInsurance:null,
        insuranceErrors:[],
        focused:true,
        date:null,
        user:null,
        showInsuranceAlert:false,
        showCredentialAlert:false,
        ratingsAndEndorsements:[],
        certifications:[],
        aircraftCategory:[],
        totalTime:null,
        totalPilotInCommandTime:null,
        credentialsErrors:[],
        credentialsPk:null,
        sportsPilotChecked:false,
        medicalErrors:[],
        certificationsErrors:[],
        medicalMethod:"POST",
        initialized:false,
        locationId:null,
        place_id:null,
        locationName:null,
        homeAirportMethod:"POST",
        homeAirportErrors:[],
        homeAirportAlertHeading:null,
        homeAirportAlertVariant:null,
        homeAirportAlertMessage:null,
        driversLicenseErrors:[],
        driversLicenseFront:null,
        driversLicenseFrontView:null,
        driversLicenseBackView:null,
        driversLicenseBack:null,
        driversLicenseMethod:"POST",
        driversLicenseAlertHeading:null,
        driversLicenseAlertVariant:null,
        driversLicenseAlertMessage:null,
        pilotsLicenseErrors:[],
        pilotsLicenseFront:null,
        pilotsLicenseFrontView:null,
        pilotsLicenseBackView:null,
        pilotsLicenseBack:null,
        pilotsLicenseMethod:"POST",
        pilotsLicenseAlertHeading:null,
        pilotsLicenseAlertVariant:null,
        pilotsLicenseAlertMessage:null,
        showPilotsLicenseAlert:false,
        pilotsLicensePk:null
      };
      this.swaggerClient = SwaggerUtility(localStorage.getItem('token'));
    }

    initPage = () => {
        const componentSelf = this;
        this.setState({user:this.props.profile.id});
        this.getUserDriversLicenseState();
        this.getUserPilotsLicenseState();
        this.getUserMedicalState();
            logger("Getting insurance information");
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            swaggerClient.then((client) => {
                client.apis
                    .insurance.insurance_get_user_insurance(
                    {id: componentSelf.props.profile.id}
                )
                    .then(function (result) {
                        if (result.ok) {
                            logger("SETTING INSURANCE INFO");
                            logger(result.obj);
                            componentSelf.setInsuranceStateAfterSave(result);
                            logger("Insurance set");
                        }
                    }).catch(function (error) {
                    // logger(error)
                })
            }).catch(function (error) {
                // logger(error)
            });
            logger("setting credentials");
            SwaggerUtility(localStorage.getItem('token')).then((client) => {
                client.apis
                    .credentials.credentials_get_user_object(
                    {id: componentSelf.props.profile.id,data:componentSelf.state}
                )
                    .then(function (result) {
                        if (result.ok) {
                            logger("Settings Credentials");
                            logger(result.obj);
                            componentSelf.setCredentialsStateAfterSave(result);
                            logger("Credentials set");
                            // this.setState({});
                            // this.setState({});
                            // this.setState({});
                        }
                    }).catch(function (error) {
                })
            }).catch(function (error) {
            });
            SwaggerUtility(localStorage.getItem('token')).then((client) => {
            client.apis
                .homeAirport.homeAirport_get_user_object(
                {id: componentSelf.props.profile.id,data:componentSelf.state}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Settings Home Airport");
                        logger(result.obj);
                        componentSelf.setHomeAirportStateAfterSave(result);
                        logger("Home Airport set");
                        // this.setState({});
                        // this.setState({});
                        // this.setState({});
                    }
                }).catch(function (error) {
            })
        }).catch(function (error) {
        });
    };

    componentDidUpdate(prevProps) {
        if(this.props.profile.id !== prevProps.profile.id) {
            if(!this.state.initialized){
                this.initPage();
            }
    }}

    getStatusHtml = (pk,valid) => {
        if(pk === null){
            return <p className="incomplete signup-status">INCOMPLETE - </p>
        }
        else if (!valid){
            return <p className="inReview signup-status">IN REVIEW - </p>
        }
        else{
            return <p className="complete signup-status">COMPLETE - </p>
        }
    }

    onChangePilotAirport = (locationInfo) => {
        this.setState({locationId:locationInfo.id});
        this.setState({place_id:locationInfo.place_id});
        this.setState({locationName:locationInfo.description});
    };

    getUserMedicalState = () => {
        const componentSelf = this;
        SwaggerUtility(localStorage.getItem('token')).then((client) => {
            client.apis
                .medical.medical_get_user_object(
                {id: componentSelf.props.profile.id,data:componentSelf.state}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Settings Medical");
                        logger(result.obj);
                        componentSelf.setMedicalStateAfterSave(result);
                        logger("Medical set");
                    }
                }).catch(function (error) {

            })
        }).catch(function (error) {

        })
    };

    getUserDriversLicenseState = () => {
        const componentSelf = this;
        SwaggerUtility(localStorage.getItem('token')).then((client) => {
            client.apis
                .driversLicense.driversLicense_get_user_object(
                {id: componentSelf.props.profile.id,data:componentSelf.state}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Settings Drivers License");
                        logger(result.obj);
                        componentSelf.setDriversLicenseStateAfterSave(result);
                        logger("Drivers license state set");
                    }
                }).catch(function (error) {

            })
        }).catch(function (error) {

        })
    };

    getUserPilotsLicenseState = () => {
        const componentSelf = this;
        SwaggerUtility(localStorage.getItem('token')).then((client) => {
            client.apis
                .pilotsLicense.pilotsLicense_get_user_object(
                {id: componentSelf.props.profile.id,data:componentSelf.state}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Settings pilot License");
                        logger(result.obj);
                        componentSelf.setPilotsLicenseStateAfterSave(result);
                        logger("pilot license state set");
                    }
                }).catch(function (error) {

            })
        }).catch(function (error) {

        })
    };

    setPilotsLicenseAlertStateToFalse = () => {
        this.setState({showPilotsLicenseAlert:false})
    };

    setInsuranceAlertStateToFalse = () => {
      this.setState({showInsuranceAlert:false})
    };

    setMedicalAlertStateToFalse = () => {
        this.setState({showMedicalAlert:false})
    };

    setCredentialAlertToFalse = () => {
        this.setState({showCredentialAlert:false})
    };

    setHomeAirportAlertToFalse = () => {
        this.setState({showHomeAirportAlert:false})
    };

    setDriversLicenseAlertStateToFalse = () => {
        this.setState({showDriversLicenseAlert:false})
    };

    setDriversLicenseStateAfterSave = (result) => {
        this.setState({driversLicensePk:result.obj.id,
        driversLicenseMethod:"PUT",
        driversLicenseFront:result.obj.driversLicenseFront,
        driversLicenseFrontView:result.obj.driversLicenseFront,
        driversLicenseBack:result.obj.driversLicenseBack,
        driversLicenseBackView:result.obj.driversLicenseBack});
    };

    setPilotsLicenseStateAfterSave = (result) => {
        this.setState({pilotsLicensePk:result.obj.id,
            pilotsLicenseMethod:"PUT",
            pilotsLicenseValid:result.obj.valid,
            pilotsLicenseFront:result.obj.pilotsLicenseFront,
            pilotsLicenseFrontView:result.obj.pilotsLicenseFront,
            pilotsLicenseBack:result.obj.pilotsLicenseBack,
            pilotsLicenseBackView:result.obj.pilotsLicenseBack});
    };

    setInsuranceStateAfterSave = (result) => {
        if(result.obj.hasInsurance){
            this.setState({insurer:result.obj.insurer,
            insuranceNumber:result.obj.insuranceNumber,
            issueDatePickerDate:new Date(result.obj.issueDate),
            issueDate:result.obj.issueDate,
                insuranceValid:result.obj.valid,
            expirationDate:result.obj.expirationDate,
            expirationDatePickerDate:new Date(result.obj.expirationDate)})
        }
        else {
            this.setState({insurer:result.obj.insurer,
                insuranceNumber:result.obj.insuranceNumber,
                issueDatePickerDate:new Date(result.obj.issueDate),
                issueDate:result.obj.issueDate,
                expirationDate:result.obj.expirationDate,
                expirationDatePickerDate:new Date(result.obj.expirationDate),
                hasInsurance:result.obj.hasInsurance,
                insurancePk:result.obj.id,
                insuranceValid:result.obj.valid,
                insuranceMethod:"PUT"})
        }
    };

    setMedicalStateAfterSave = (result) => {
        console.log(result);
        this.setState({
        medicalClass:result.obj.medicalClass,
        medicalPk:result.obj.id,
        medicalIssueDatePickerDate:new Date(result.obj.medicalIssueDate),
        medicalIssueDate:result.obj.medicalIssueDate,
        medicalExpirationDate:result.obj.medicalExpirationDate,
        medicalExpirationDatePickerDate:new Date(result.obj.medicalExpirationDate),
        medicalCertificationNumber:result.obj.medicalCertificationNumber,
        medicalValid:result.obj.valid,
        medicalMethod:"PUT"}
        )
    };

    setCredentialsStateAfterSave = (result) => {
        this.setState({totalTime:result.obj.totalTime,
        credentialsPk:result.obj.id,
        totalPilotInCommandTime:result.obj.totalPilotInCommandTime,
        credentialsMethod:"PUT",
        certifications:result.obj.certifications,
        credentialsValid:result.obj.valid,
        ratingsAndEndorsements:result.obj.ratingsAndEndorsements,
        aircraftCategory:result.obj.aircraftCategory});
    };

    setHomeAirportStateAfterSave = (result) => {
        this.setState({locationName:result.obj.locationName,
        place_id:result.obj.place_id,
        locationId:result.obj.locationId,
        homeAirportPk:result.obj.id,
        homeAirportMethod:"PUT"})
    };

    showDriversLicenseSuccessMessage = () => {
        this.setState({
            driversLicenseAlertHeading: "Drivers License Successfully Uploaded",
            driversLicenseAlertVariant: "success",
            driversLicenseAlertMessage: "Your Drivers License information was successfully updated",
            showDriversLicenseAlert: true
        });
    };

    showPilotsLicenseSuccessMessage = () => {
        this.setState({
            pilotsLicenseAlertHeading: "Pilots License Successfully Uploaded",
            pilotsLicenseAlertVariant: "success",
            pilotsLicenseAlertMessage: "Your Pilots License information was successfully updated",
            showPilotsLicenseAlert: true
        });
    };

    showInsuranceSuccessMessage = () => {
        this.setState({insuranceAlertHeading:"Insurance Information Successfully Updated",
        insuranceAlertVariant:"success",
        insuranceAlertMessage:"Your insurance information was successfully updated",
        showInsuranceAlert:true});
    };

    showMedicalSuccessMessage = () => {
        this.setState({medicalAlertHeading:"Medical Class Information Successfully Updated",
        medicalAlertVariant:"success",
        medicalAlertMessage:"Your medical class information was successfully updated",
        showMedicalAlert:true});
    };

    showCredentialsSuccessMessage = () => {
        this.setState({credentialsAlertHeading:"Certifications Successfully Updated",
        credentialsAlertVariant:"success",
        credentialsAlertMessage:"Your certification information was successfully updated",
        showCredentialAlert:true});
    };

    showDriversLicenseuccessMessage = () => {
        this.setState({credentialsAlertHeading:"Certifications Successfully Updated",
        credentialsAlertVariant:"success",
        credentialsAlertMessage:"Your certification information was successfully updated",
        showCredentialAlert:true});
    };

    showHomeAirportSuccessMessage = () => {
        this.setState({homeAirportAlertHeading:"Home Airport Successfully Updated",
        homeAirportAlertVariant:"success",
        homeAirportAlertMessage:"Your home airport was successfully updated",
        showHomeAirportAlert:true});
    };

    expirationDateChange = (date) => {
        this.setState({expirationDate:getFormattedDate(date),
        expirationDatePickerDate:date});
    };

    medicalIssueDateChange = (date) => {
        this.setState({medicalIssueDate:getFormattedDate(date),
        medicalIssueDatePickerDate:date});
    };

    medicalExpirationDateChange = (date) => {
        this.setState({medicalExpirationDate:getFormattedDate(date),
        medicalExpirationDatePickerDate:date});
    };

    issueDateChange = (date) => {
        this.setState({issueDate:getFormattedDate(date),
        issueDatePickerDate:date});
    };

    saveHomeAirport = () => {
        console.log(this.state);
        logger("Saving home airport information");
        let result = validateFields(this.state,"homeAirportForm");
        logger("Form result");
        logger(result);
        this.setState({homeAirportErrors:result[1]});
        const componentSelf = this;
        if(result[0]) {
            if (this.state.homeAirportMethod === "POST") {
                this.swaggerClient.then((client) => {
                    client.apis
                        .homeAirport.homeAirport_create(
                        {data: componentSelf.state}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                componentSelf.setHomeAirportStateAfterSave(result);
                                componentSelf.showHomeAirportSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
            else{
                this.swaggerClient.then((client) => {
                    client.apis
                        .homeAirport.homeAirport_update(
                        {data:this.state,id:this.state.homeAirportPk}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                componentSelf.setHomeAirportStateAfterSave(result);
                                componentSelf.showHomeAirportSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    saveCredentials = () => {
        console.log(this.state);
        logger("Saving insurance information");
        let result = validateFields(this.state,"certificationsForm");
        logger("Form result");
        logger(result);
        this.setState({certificationsErrors:result[1]});
        const componentSelf = this;
        if(result[0]) {
            if (this.state.credentialsMethod === "POST") {
                this.swaggerClient.then((client) => {
                    client.apis
                        .credentials.credentials_create(
                        {data: componentSelf.state}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                componentSelf.setCredentialsStateAfterSave(result);
                                componentSelf.showCredentialsSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
            else{
                this.swaggerClient.then((client) => {
                    client.apis
                        .credentials.credentials_update(
                        {data:this.state,id:this.state.credentialsPk}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                componentSelf.setCredentialsStateAfterSave(result);
                                componentSelf.showCredentialsSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    saveDriversLicense = () => {
        logger("Validating drivers license state");
        let result = validateFields(this.state,"driverLicensePhotoForm");
        logger("Drivers license Form result");
        logger(result);
        logger(this.state);
        const compSelf = this;
        this.setState({driversLicenseErrors:result[1]});
        if(result[0]){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            if(this.state.driversLicenseMethod === "POST"){
                swaggerClient.then((client) => {
                    client.apis
                        .driversLicense.driversLicense_create(
                        compSelf.state
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.getUserDriversLicenseState();
                                compSelf.showDriversLicenseSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
            else if(this.state.driversLicenseMethod === "PUT"){
                const medicalPutData = Object.assign({}, this.state);
                medicalPutData["id"] = this.state.driversLicensePk;
                swaggerClient.then((client) => {
                    client.apis
                        .driversLicense.driversLicense_update(
                        (medicalPutData)
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.getUserDriversLicenseState();
                                compSelf.showDriversLicenseSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    savePilotsLicense = () => {
        logger("Validating pilots license state");
        let result = validateFields(this.state,"pilotLicensePhotoForm");
        logger("Pilot license Form result");
        logger(result);
        logger(this.state);
        const compSelf = this;
        this.setState({pilotsLicenseErrors:result[1]});
        if(result[0]){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            if(this.state.pilotsLicenseMethod === "POST"){
                swaggerClient.then((client) => {
                    client.apis
                        .pilotsLicense.pilotsLicense_create(
                        compSelf.state
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.getUserPilotsLicenseState();
                                compSelf.showPilotsLicenseSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
            else if(this.state.pilotsLicenseMethod === "PUT"){
                const medicalPutData = Object.assign({}, this.state);
                medicalPutData["id"] = this.state.pilotsLicensePk;
                swaggerClient.then((client) => {
                    client.apis
                        .pilotsLicense.pilotsLicense_update(
                        (medicalPutData)
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.getUserPilotsLicenseState();
                                compSelf.showPilotsLicenseSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    saveMedicalInformation = () => {
        logger("Validating medical state");
        logger(this.state);
        let result = validateFields(this.state,"medicalClassForm");
        logger("Medical Form result");
        logger(result);
        logger(this.state);
        const compSelf = this;
        this.setState({medicalErrors:result[1]});
        if(result[0]){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            if(this.state.medicalMethod === "POST"){
                swaggerClient.then((client) => {
                    client.apis
                        .medical.medical_create(
                        compSelf.state
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.getUserMedicalState();
                                compSelf.showMedicalSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
            else if(this.state.medicalMethod === "PUT"){
                const medicalPutData = Object.assign({}, this.state);
                medicalPutData["id"] = this.state.medicalPk;
                swaggerClient.then((client) => {
                    client.apis
                        .medical.medical_update(
                        (medicalPutData)
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.getUserMedicalState();
                                compSelf.showMedicalSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    saveInsuranceInformation = () => {
        console.log("this.state");
        console.log(this.state);
        const compSelf = this;
        const skippedFields = {};
        if(this.state.hasInsurance === false){
            skippedFields["insurer"] = true;
            skippedFields["insuranceNumber"] = true;
            skippedFields["issueDate"] = true;
            skippedFields["expirationDate"] = true;
        }
        logger("Saving insurance information");
        let result = validateFields(this.state,"pilotInsuranceForm",skippedFields);
        logger("Form result");
        logger(result);
        this.setState({insuranceErrors:result[1]});
        if(result[0]){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            if(this.state.insuranceMethod === "POST"){
                swaggerClient.then((client) => {
                    client.apis
                        .insurance.insurance_create(
                        {data:compSelf.state}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.setInsuranceStateAfterSave(result);
                                compSelf.showInsuranceSuccessMessage();
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
            else if(this.state.insuranceMethod === "PUT"){
                swaggerClient.then((client) => {
                    client.apis
                        .insurance.insurance_update(
                        {data:this.state,id:this.state.insurancePk}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger(result);
                                logger(result.obj);
                                compSelf.showInsuranceSuccessMessage();
                                compSelf.setInsuranceStateAfterSave(result);
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    register = () => {
        const componentSelf = this;
        const skippedFields = {};
        if(this.props.profileType !== "pilot"){
            skippedFields["totalTime"] = true;
            skippedFields["pilotAirportLocationId"] = true;
            skippedFields["pilotAirportLocationName"] = true;
            skippedFields["totalPilotInCommandTime"] = true;
            skippedFields["insuranceCarrier"] = true;
            skippedFields["insurancePolicyNumber"] = true;
            skippedFields["hasInsurance"] = true;
        }
        else{
            if(this.props.signUpInformation.hasInsurance === false){
                skippedFields["insuranceCarrier"] = true;
                skippedFields["insurancePolicyNumber"] = true;
            }
        }
        let result = validateFields(this.props.signUpInformation,"signUpForm",skippedFields);
        logger("form is");
        logger(result[0]);
        this.setState({errors:result[1]});
        if(result[0]){
            if(this.props.profileType === "pilot"){
                if(this.props.signUpInformation.pilotAirportLocationName === null || this.props.signUpInformation.pilotAirportLocationName === undefined){
                    this.setState({errors:{homeAirport:["This Field is required"]}});
                    result[0] = false;
                }
            }
        }
        if(result[0]){
            this.sendRegistration(componentSelf);
        }
    };

    sendRegistration = (componentSelf) => {
        logger("profile info");
        const swaggerClient =  SwaggerUtility();
        let signUpInformation = this.props.signUpInformation;
        signUpInformation["profileType"] = this.props.profileType;
        swaggerClient.then((client) => {
          logger(signUpInformation);
          logger(client);
        client.apis
        .users.users_create(
        {data:signUpInformation}
        )
      .then(function(result){
        logger(result);
        logger(result.ok);
        if(result.ok){
            const createdUser = result.obj;
            logger(createdUser);
            const token = createdUser.token;
            logger(token);
            setToken(token);
            localStorage.setItem('token', token);
            componentSelf.props.history.push("/Profile");
            setAuthenticated(true);
        }
      }).catch(function(error){logger(error);handleFailedRequest()})
        }).catch(function(error){logger(error);handleFailedRequest()})
    };
    render() {
        const pilotLicenseStatus = this.getStatusHtml(this.state.pilotsLicensePk,this.state.pilotsLicenseValid);
        const medicalClassStatus = this.getStatusHtml(this.state.medicalPk,this.state.medicalValid);
        const credentialsStatus =  this.getStatusHtml(this.state.credentialsPk,this.state.credentialsValid);
        const insuranceStatus = this.getStatusHtml(this.state.pilotsLicensePk,this.state.insuranceValid);
        let seeFrontDriversLicenseHtml = null;
        let seeFrontPilotsLicenseHtml = null;
        let seeBackDriversLicenseHtml = null;
        let seeBackPilotsLicenseHtml = null;
        if(this.state.driversLicenseFrontView != null){
            seeFrontDriversLicenseHtml = (
                <div>
                <p className="formLabel blueText">Drivers License Front</p>
                <img class="driversLicensePreview" src={configs.pictureUrl + this.state.driversLicenseFrontView}/>
                </div>
            )
        }
        if(this.state.driversLicenseBackView != null){
            seeBackDriversLicenseHtml = (
                <div>
                <p className="formLabel blueText">Drivers License Back</p>
                <img class="driversLicensePreview" src={configs.pictureUrl + this.state.driversLicenseBackView}/>
                </div>
            )
        }
        if(this.state.pilotsLicenseFrontView != null){
            seeFrontPilotsLicenseHtml = (
                <div>
                    <p className="formLabel blueText">Pilots License Front</p>
                    <img class="driversLicensePreview" src={configs.pictureUrl + this.state.pilotsLicenseFrontView}/>
                </div>
            )
        }
        if(this.state.pilotsLicenseBackView != null){
            seeBackPilotsLicenseHtml = (
                <div>
                    <p className="formLabel blueText">Pilots License Back</p>
                    <img class="driversLicensePreview" src={configs.pictureUrl + this.state.pilotsLicenseBackView}/>
                </div>
            )
        }
        if(!this.state.initialized && this.props.profile.id !== null){
            this.setState({initialized:true});
            this.initPage();
        }
        const disableInsurance = this.state.hasInsurance === false;
            let driverLicenseFrontFilePondObject = (
                <FilePond
                    allowImageExifOrientation={true}
                    acceptedFileTypes={['image/png', 'image/jpeg']}
                    onupdatefiles={fileItems => {
                        if (fileItems.length > 0){
                            this.setState({
                                driversLicenseFront: getBlobOrFile(fileItems[0].file)
                            })
                        }

                    }}
                    allowMultiple={false} maxFiles={1}/>
            );
            let driverLicenseBackFilePondObject = (
                <FilePond
                    allowImageExifOrientation={true}
                    acceptedFileTypes={['image/png', 'image/jpeg']}
                    onupdatefiles={fileItems => {
                        if (fileItems.length > 0){
                            this.setState({
                                driversLicenseBack :getBlobOrFile(fileItems[0].file)
                            })
                        }

                    }}  allowMultiple={false} maxFiles={1}/>
            );
        let pilotsLicenseFrontFilePondObject = (
            <FilePond
                allowImageExifOrientation={true}
                acceptedFileTypes={['image/png', 'image/jpeg']}
                onupdatefiles={fileItems => {
                    if (fileItems.length > 0){
                        this.setState({
                            pilotsLicenseFront: getBlobOrFile(fileItems[0].file)
                        })
                    }

                }}
                allowMultiple={false} maxFiles={1}/>
        );
        let pilotsLicenseBackFilePondObject = (
            <FilePond
                allowImageExifOrientation={true}
                acceptedFileTypes={['image/png', 'image/jpeg']}
                onupdatefiles={fileItems => {
                    if (fileItems.length > 0){
                        this.setState({
                            pilotsLicenseBack :getBlobOrFile(fileItems[0].file)
                        })
                    }

                }}  allowMultiple={false} maxFiles={1}/>
        );
        return (
          <Header>
            <Container>
                <Container>
                    <Row>
                        {pilotLicenseStatus}
                        <a className="greyText signup-link" href="#pilotsLicense">Pilots License</a>
                    </Row>
                <Row>
                    {medicalClassStatus}
                    <a className="greyText signup-link" href="#medicalClass"> Medical Class</a>
                </Row>
                <Row>
                    {insuranceStatus}
                    <a className="greyText signup-link" href="#rentersInsurance">Renters Insurance</a>
                </Row>
                <Row>
                    {credentialsStatus}
                    <a className="greyText signup-link" href="#certifications">Credentials</a>
                </Row>
                </Container>
              <Row>
                  <Col>
                      <h3 id="pilotsLicense" className="formBorderTitle blueText">Pilots License</h3>
                      {seeFrontPilotsLicenseHtml}
                      {seeBackPilotsLicenseHtml}
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Upload Pilots License Front
                          </Form.Label>
                          {pilotsLicenseFrontFilePondObject}
                          <FormError formField="pilotsLicenseFront" errors = {this.state.pilotsLicenseErrors} />
                      </Form.Group>
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Upload Pilots License Back
                          </Form.Label>
                          {pilotsLicenseBackFilePondObject}
                          <FormError formField="pilotsLicenseBack" errors = {this.state.pilotsLicenseErrors} />
                      </Form.Group>
                      <Alerter
                          heading={this.state.pilotsLicenseAlertHeading}
                          variant={this.state.pilotsLicenseAlertVariant}
                          message={this.state.pilotsLicenseAlertMessage}
                          show={this.state.showPilotsLicenseAlert}
                          handleClick = {this.setPilotsLicenseAlertStateToFalse}
                      />
                      <Button onClick={this.savePilotsLicense}>Save Pilots License</Button>

                      <hr className="profileHr"/>
                      <h3 className="formBorderTitle blueText">Drivers License</h3>
                      {seeFrontDriversLicenseHtml}
                      {seeBackDriversLicenseHtml}
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Upload Drivers License Front
                          </Form.Label>
                          {driverLicenseFrontFilePondObject}
                          <FormError formField="driversLicenseFront" errors = {this.state.driversLicenseErrors} />
                      </Form.Group>
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Upload Drivers License Back
                          </Form.Label>
                          {driverLicenseBackFilePondObject}
                          <FormError formField="driversLicenseBack" errors = {this.state.driversLicenseErrors} />
                      </Form.Group>
                      <Alerter
                          heading={this.state.driversLicenseAlertHeading}
                          variant={this.state.driversLicenseAlertVariant}
                          message={this.state.driversLicenseAlertMessage}
                          show={this.state.showDriversLicenseAlert}
                          handleClick = {this.setDriversLicenseAlertStateToFalse}
                      />
                      <Button onClick={this.saveDriversLicense}>Save Drivers License</Button>
                      <hr className="profileHr"/>

                          <h3  id="medicalClass" className="formBorderTitle blueText">Medical Class</h3>
                          <Form.Row>

                              <Form.Group as={Col} controlId="formGridBDay">
                                  <Form.Label className="formLabel greyText">Medical Class</Form.Label>
                                  <Form.Control as="select" value={this.state.medicalClass} onChange={(e) => this.setState({medicalClass:e.target.value})}>
                                      <option>Class A</option>
                                      <option>Class B</option>
                                      <option>Class C</option>
                                  </Form.Control>
                              </Form.Group>
                          </Form.Row>
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Medical Issue Date
                          </Form.Label>
                          <Form.Row>
                              <DatePicker selected={this.state.medicalIssueDatePickerDate} onChange={this.medicalIssueDateChange} />
                          </Form.Row>
                          <FormError formField="medicalIssueDate" errors = {this.state.medicalErrors} />
                      </Form.Group>
                      <Form.Group>

                              <Form.Label className="formLabel greyText">
                                  Expiration Date
                              </Form.Label>
                              <Form.Row>
                                  <DatePicker selected={this.state.medicalExpirationDatePickerDate} onChange={this.medicalExpirationDateChange} />
                              </Form.Row>
                              <FormError formField="medicalExpirationDate" errors = {this.state.medicalErrors} />
                      </Form.Group>
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Certification Number
                          </Form.Label>
                          <Form.Row>
                              <Form.Control type="text" value={this.state.medicalCertificationNumber} onChange={(e) => this.setState({medicalCertificationNumber:e.target.value})} />
                          </Form.Row>
                          <FormError formField="medicalCertificationNumber" errors = {this.state.medicalErrors} />
                      </Form.Group>
                          <Alerter
                              heading={this.state.medicalAlertHeading}
                              variant={this.state.medicalAlertVariant}
                              message={this.state.medicalAlertMessage}
                              show={this.state.showMedicalAlert}
                              handleClick = {this.setMedicalAlertStateToFalse}
                          />
                          <br />
                          <Button onClick={this.saveMedicalInformation}>Save Medical Certification</Button>
                          <Form.Group>
                              <hr className="profileHr"/>
                              <h3 id="rentersInsurance" className="formBorderTitle blueText">Renters Insurance (must include a minimum of $25,000 in aircraft damage liability)</h3>
                              <div>
                                  Flying rented aircraft? Avemco Insurance Company has coverages designed to protect you. Click on the <a href="https://avemco.com/SK01">Avemco​®​ link</a> to learn more, or to buy online.
                              </div>
                              <div className="insuranceDiv"><br/>Click Here: <a href="https://avemco.com/SK01">https://avemco.com/SK01</a><br/></div>
                              <Form.Label className="formLabel greyText"> Do you have renters insurance?</Form.Label>
                              <br/>
                              <br/>
                              <Form.Check inline checked={this.state.hasInsurance === true} value={true} label="Yes" name="hasInsurance" type="radio" onChange={(e) => {
                                  var inputValue;
                                  if(e.target.value === "true"){
                                      inputValue = true;
                                  }
                                  else{
                                      inputValue = false
                                  }
                                  logger("inputValue");
                                  logger(inputValue);
                                  this.setState({hasInsurance:inputValue});
                              }} id="inline-radio-1" />
                              <Form.Check inline checked={this.state.hasInsurance === false} value={false} label="No" name="hasInsurance" type="radio" onChange={(e) => { var inputValue;
                                  if(e.target.value === "true"){
                                      inputValue = true;
                                  }
                                  else{
                                      inputValue = false
                                  }
                                  logger("inputValue");
                                  logger(inputValue);
                                  this.setState({hasInsurance:inputValue});}} id="inline-radio-2" />
                              <FormError formField="hasInsurance" errors = {this.state.insuranceErrors} />
                              <br/>
                              <br/>
                              <Form.Label className="formLabel greyText">Aircraft Insurer</Form.Label>
                              <Form.Control type="text" disabled={disableInsurance} name="insuranceCarrier" value={getStringFromNull(this.state.insurer)} onChange={(e) => this.setState({insurer:e.target.value})} />
                              <FormError formField="insurer" errors = {this.state.insuranceErrors} />
                          </Form.Group>
                          <Form.Group>
                              <Form.Label className="formLabel greyText"> Insurance Number </Form.Label>
                              <Form.Control disabled={disableInsurance} type="text" name="insurancePolicyNumber" value={getStringFromNull(this.state.insuranceNumber)} onChange={(e) => this.setState({insuranceNumber:e.target.value})} />
                              <FormError formField="insuranceNumber" errors = {this.state.insuranceErrors} />
                          </Form.Group>
                          <Form.Group>
                              <Form.Label className="formLabel greyText">
                                  Date Issued
                              </Form.Label>
                              <Form.Row>
                                  <DatePicker disabled={disableInsurance} selected={this.state.issueDatePickerDate} onChange={this.issueDateChange} />
                              </Form.Row>
                              <FormError formField="issueDate" errors = {this.state.insuranceErrors} />
                          </Form.Group>
                          <Form.Group>
                              <Form.Label className="formLabel greyText">
                                  Expiration Date
                              </Form.Label>
                              <Form.Row>
                                  <DatePicker disabled={disableInsurance}  selected={this.state.expirationDatePickerDate} onChange={this.expirationDateChange} />
                              </Form.Row>
                              <FormError formField="expirationDate" errors = {this.state.insuranceErrors} />
                          </Form.Group>
                      <Alerter
                          heading={this.state.insuranceAlertHeading}
                          variant={this.state.insuranceAlertVariant}
                          message={this.state.insuranceAlertMessage}
                          show={this.state.showInsuranceAlert}
                          handleClick = {this.setInsuranceAlertStateToFalse}
                      />
                      <Button onClick={this.saveInsuranceInformation}>Save Insurance Information</Button>
                      <hr className="profileHr"/>
                      <h3 className="formBorderTitle blueText" id="certifications">Credentials</h3>
                          <Form.Group value={this.state.certifications} id="pilotCertifications" name="pilotCertifications"  onChange={
                              (e) => {
                                  const checkedCertifications = getMultiCheckValues("pilotCertifications");
                                  logger(checkedCertifications);
                                  this.setState({certifications:checkedCertifications});
                                  }}
                                      controlId="pilotCertifications">
                              <Form.Label className="formLabel greyText">Certifications</Form.Label>
                              <Form.Check className="pilotCertifications" type="checkbox" label="Sports Pilot" checked={this.state.certifications.indexOf("Sports Pilot") >= 0}/>
                              <Form.Check className="pilotCertifications" type="checkbox"  label="Recreational Pilot" checked={this.state.certifications.indexOf("Recreational Pilot") >= 0}/>
                              <Form.Check className="pilotCertifications" type="checkbox"  label="Private Pilot" checked={this.state.certifications.indexOf("Private Pilot") >= 0} />
                              <Form.Check className="pilotCertifications" type="checkbox"  label="Commercial Pilot" checked={this.state.certifications.indexOf("Commercial Pilot") >= 0} />
                              <Form.Check className="pilotCertifications" type="checkbox"  label="Certified Flight Instructor" checked={this.state.certifications.indexOf("Certified Flight Instructor") >= 0} />
                              <Form.Check className="pilotCertifications" type="checkbox"  label="Certified Flight Instructor 2 (CFII)" checked={this.state.certifications.indexOf("Certified Flight Instructor 2 (CFII)") >= 0}/>
                              <Form.Check className="pilotCertifications" type="checkbox"  label="Airline Transport Pilot" checked={this.state.certifications.indexOf("Airline Transport Pilot") >= 0}/>
                              <FormError formField="pilotCertifications" errors = {this.state.errors} />
                          </Form.Group>

                          <Form.Group id="ratingsAndEndorsements" name="ratingsAndEndorsements"  onChange={(e) => this.setState({ratingsAndEndorsements:getMultiCheckValues("ratingsAndEndorsements")})} controlId="ratingsAndEndorsements">
                              <Form.Label className="formLabel greyText">Ratings and Endorsements</Form.Label>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox" label="Instrument Ratings" checked={this.state.ratingsAndEndorsements.indexOf("Instrument Ratings") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="Multi Engine Rating" checked={this.state.ratingsAndEndorsements.indexOf("Multi Engine Rating") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="Tail Wheel Endorsement" checked={this.state.ratingsAndEndorsements.indexOf("Tail Wheel Endorsement") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="High Altitude Endorsement" checked={this.state.ratingsAndEndorsements.indexOf("High Altitude Endorsement") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="Glider Endorsement" checked={this.state.ratingsAndEndorsements.indexOf("Glider Endorsement") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="High Performance Endorsement" checked={this.state.ratingsAndEndorsements.indexOf("High Performance Endorsement") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="Tail Wheel Endorsement" checked={this.state.ratingsAndEndorsements.indexOf("Tail Wheel Endorsement") >= 0}/>
                              <Form.Check className="ratingsAndEndorsements" type="checkbox"  label="Complex Airplane Endorsement" checked={this.state.ratingsAndEndorsements.indexOf("Complex Airplane Endorsement") >= 0}/>
                              <FormError formField="ratingsAndEndorsements" errors = {this.state.errors} />
                          </Form.Group>

                          <Form.Group id="aircraftCategory" name="aircraftCategory"  onChange={(e) => this.setState({aircraftCategory:(getMultiCheckValues("aircraftCategory"))})} controlId="aircraftCategory">
                              <Form.Label className="formLabel greyText">Aircraft Category</Form.Label>
                              <Form.Check  className="aircraftCategory" type="checkbox" value="Single Engine Land" label="Single Engine Land" checked={this.state.aircraftCategory.indexOf("Single Engine Land") >= 0}/>
                              <Form.Check className="aircraftCategory" type="checkbox" value="Multi Engine Land" label="Multi Engine Land" checked={this.state.aircraftCategory.indexOf("Multi Engine Land") >= 0}/>
                              <Form.Check className="aircraftCategory" type="checkbox" value="Single Engine Sea" label="Single Engine Sea" checked={this.state.aircraftCategory.indexOf("Single Engine Sea") >= 0}/>
                              <Form.Check className="aircraftCategory" type="checkbox" value="Multi Engine Sea" label="Multi Engine Sea" checked={this.state.aircraftCategory.indexOf("Multi Engine Sea") >= 0}/>
                              <FormError formField="aircraftCategory" errors = {this.state.errors} />
                          </Form.Group>
                          <Form.Group>
                              <Form.Label className="formLabel greyText"> Total Time (Hours) </Form.Label>
                              <Form.Control value={this.state.totalTime} type="text" name="totalTime" placeholder="2000" onChange={(e) => this.setState({totalTime:e.target.value})} />
                              <FormError formField="totalTime" errors = {this.state.certificationsErrors} />
                          </Form.Group>
                          <Form.Group>
                              <Form.Label className="formLabel greyText">
                                  Total Pilot in Command Time (Hours)
                              </Form.Label>
                              <Form.Control type="text" value={this.state.totalPilotInCommandTime} name="totalPilotInCommandTime" placeholder="1000" onChange={(e) => this.setState({totalPilotInCommandTime:e.target.value})} />
                              <FormError formField="totalPilotInCommandTime" errors = {this.state.certificationsErrors} />
                          </Form.Group>
                      <Alerter
                          heading={this.state.credentialsAlertHeading}
                          variant={this.state.credentialsAlertVariant}
                          message={this.state.credentialsAlertMessage}
                          show={this.state.showCredentialAlert}
                          handleClick = {this.setCredentialAlertToFalse}
                      />
                      <Button onClick={this.saveCredentials}>Save Credentials</Button>
                      <hr className="profileHr"/>
                      <h3 id="homeAirportSection" className="formBorderTitle blueText">Home Airport</h3>
                      <Form.Group>
                          <Form.Label className="formLabel greyText">
                              Home Airport
                          </Form.Label>
                          <AutoCompleteInput initValue={this.state.locationName} onChange={this.onChangePilotAirport} parentElement="profile"/>
                          <img src={poweredByGoogleLogo}></img>
                          <FormError formField="locationName" errors = {this.state.homeAirportErrors} />
                      </Form.Group>
                      <Alerter
                          heading={this.state.homeAirportAlertHeading}
                          variant={this.state.homeAirportAlertVariant}
                          message={this.state.homeAirportAlertMessage}
                          show={this.state.showHomeAirportAlert}
                          handleClick = {this.setHomeAirportAlertToFalse}
                      />
                      <Button onClick={this.saveHomeAirport}>Save Home Airport</Button>
                      <div style={{"margin-bottom":"80px"}} />
                  </Col>
                </Row>
            </Container>
        </Header>
        )
    }

}

const mapStateToProps = state => {
    logger("state update!");
    logger(state);
    return {
      profile: state.profile
    }
};

const mapDispatchToProps = dispatch => {
    return {
      onChangeUsername: text => {  
        dispatch(setSignupUsername(text))
      },
      onChangePassword: text => {
        dispatch(setSignUpPassword(text))
      },
      onChangeConfirmPassword: text => {
        dispatch(setSignUpConfirmPassword(text))
      },
      onChangeFirstName: text => {
        dispatch(setSignupFirstName(text))
      },
      onChangeLastName: text => {
        dispatch(setSignupLastName(text))
      },
      onChangeBirthdayDay: text => {  
        dispatch(setSignupBirthdayDay(text))
      },
      onChangeBirthdayMonth: text => {
        dispatch(setSignupBirthdayMonth(text))
      },
      onChangeBirthdayYear: text => {
        dispatch(setSignupUseBirthdayYear(text))
      },
      onChangeState: text => {
        dispatch(setSignupState(text))
      },
      onChangeCity: text => {
        dispatch(setSignupCity(text))
      },
      onChangeAddress: text => {
        dispatch(setSignupAddress(text))
      },
      onChangeNumber: text => {
        dispatch(setSignupMobileNumber(text))
      },
      onChangeZipcode: text => {
        dispatch(setSignUpZipcode(text))
      },
      onChangeAddressTwo: text => {
        dispatch(setSignupAddressTwo(text))
      },
      onChangePilotTotalTime: input => {
          dispatch(setPilotTotalTime(input))
      },
      onChangePilotTotalInCommandTime: input => {
          dispatch(setPilotTotalInCommandTime(input))
      },
        onChangeRentersInsurance: input => {
            dispatch(setPilotInsuranceCarrier(input))
        },
        onChangeInsurancePolicyNumber: input => {
            dispatch(setPilotInsurancePolicyNumber(input))
        },
        onChangeInsuranceDateIssuedDay: input => {
            dispatch(setPilotInsuranceIssueDay(input))
        },
        onChangeInsuranceDateIssuedMonth: input => {
            dispatch(setPilotInsuranceIssueMonth(input))
        },
        onChangeInsuranceDateIssuedYear: input => {
            dispatch(setPilotInsuranceIssueYear(input))
        },
        onChangeInsuranceDateExpireDay: input => {
            dispatch(setPilotInsuranceExpirationDay(input))
        },
        onChangeInsuranceDateExpireMonth: input => {
            dispatch(setPilotInsuranceExpirationMonth(input))
        },
        onChangeInsuranceDateExpireYear: input => {
            dispatch(setPilotInsuranceExpirationYear(input))
        },
        onChangeRatingsAndEndorsements: input => {
            dispatch(setPilotRatingsAndEndorsements(input))
        },
        onChangeCertifications: input => {
            dispatch(setPilotCertifications(input))
        },
        onChangeHasInsurance: input => {
            dispatch(setPilotHasInsurance(input))
        },
        onChangeMedicalClassIssueDay: input => {
            dispatch(setMedicalClassIssueDay(input))
        },
        onChangeMedicalClassIssueMonth: input => {
            dispatch(setMedicalClassIssueMonth(input))
        },
        onChangeMedicalClassIssueYear: input => {
            dispatch(setMedicalClassIssueYear(input))
        },
        onChangeMedicalClass: input => {
            dispatch(setMedicalClass(input))
        },
        onChangeAircraftCategory: input => {
            dispatch(setAircraftCategory(input))
        },
    }
};

const PilotSignup = connect(
    mapStateToProps,
    mapDispatchToProps
)(PilotSignupComp);

export default withRouter(PilotSignup);