import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PlaneCalenderDay from "./PlaneCalenderDay";
import React from "react";
import {connect} from "react-redux";
import {getApiDateFromDateObject, logger} from "../../Utilities";
import SwaggerUtility from "../../SwaggerUtility";
import {setCalenderDay, setCustomPlanePrice, setCustomPlaneUnavail } from "../../actions";
import {withRouter} from "react-router";


class PlaneCalenderComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            date: new Date(),
            days: [],
            initialized: false,
            pricesInitialized:false,
            datesInitialized:false,
            planeId: null,
            defaultPrice: 125
        }
    }

    componentDidMount() {
        this.updatePlaneCustomPrices();
        this.updatePlaneCustomUnavail();
        this.updatePlaneDefaultPrice();
    }

    updatePlaneDefaultPrice = () => {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft
                .aircraft_getPrice(
                    {id:parseInt(this.props.match.params.planeId)})
                .then(function (result) {
                    if (result.ok) {
                        console.error("got default price", result);
                        componentSelf.setState({defaultPrice:parseFloat(result.obj.price)})
                    }
                }).catch(function (error) {
                console.error(error)
            })
                .catch(function (error) {
                    console.error(error)
                })})
    };

    updatePlaneCustomPrices = () => {
        let planeId = parseInt(this.props.match.params.planeId);
        // if(this.state.match !== null && this.state.match !== undefined){
        //     if(this.state.planeId === null){
        //         planeId = parseInt(this.state.match.params.planeId);
        //         this.setState({planeId:planeId})
        //     }
        // } else {
        //     planeId = this.state.planeId;
        // }
        const self=this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .customPrice.customPrice_read({id:planeId})
                .then(function (result) {
                    if (result.ok) {
                        logger("Recevied Aircraft Custom Prices");
                        logger(result);
                        let customDates = {};
                        result.obj.forEach(price => {
                            customDates[price.date] = price.price;
                        });
                        logger("Setting new custom prices");
                        logger(customDates);
                        self.props.onSetPlaneCustomDates({airplane:planeId,dates:customDates});
                    }
                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    updatePlaneCustomUnavail = () => {
        const planeId = parseInt(this.props.match.params.planeId);
        logger("UPDATING Unavail-----");
        const self=this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .getCustomPlaneUnavail
                .getCustomPlaneUnavail
                ({id:planeId})
                .then(function (result) {
                    if (result.ok) {
                        logger("Recevied Aircraft Custom Unavails");
                        logger(result.obj);
                        self.props.onSetPlaneCustomUnavail({airplane:planeId,dates:result.obj});
                        self.setState({datesInitialized:true});
                    }
                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    updateDate = (direction) => {
        let planeId;
        if(this.state.match !== null && this.match !== undefined){
            if(this.state.planeId === null){
                planeId = parseInt(this.state.match.params.planeId);
                this.setState({planeId:planeId})
            }
        }
        var currentDate = this.state.date;
        var current;
        if (currentDate.getMonth() == 11) {
            current = new Date(currentDate.getFullYear() + direction, 0, 1);
        } else {
            current = new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1);
        }
        this.setState({date:current});
        this.setState({
            date:current,
            initialized:true}
            );
    };

    render() {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let allUnavails = [];
        const planeId = parseInt(this.props.match.params.planeId);
        var fullYear = this.state.date.getFullYear();
        var month = this.state.date.getMonth();
        var firstDay = new Date(fullYear,month,1);
        var excludedDaysIndex = 0;
        var daysToExclude = firstDay.getDay();
        let newDays = [];
        while(excludedDaysIndex < daysToExclude){
            newDays.push({ date:new Date(),price:0,validDay:false,planeId:planeId});
            daysToExclude --;
        }
        var daysToAdd = 0;
        var nextDay = new Date(this.state.date);
        nextDay.setDate(firstDay.getDate()+daysToAdd);
        var nextMonth = nextDay.getMonth();
        if (this.props.customPlanePrices.customDates[planeId] !== null && this.props.customPlanePrices.customDates[planeId] !== undefined){
            console.warn('Plane has unavailabilities');
            console.warn(this.props.customPlanePrices.customDates[planeId]);
            this.props.customPlanePrices.customDates[planeId].forEach(period => {
                console.warn('Start Unavail', period['end'], ' date obj ', new Date(period['end']));
                console.warn('End Unavail', period['start'], ' date obj ', new Date(period['start']));
                allUnavails.push(
                    [
                        new Date(period['start']),
                        new Date(period['end']),
                        period['id']
                    ]
                );
            });
        }
        while(nextMonth === month){
            let unavailsIndex = 0;
            let unavailabilityDateTimes = [];
            let hasUnavailability = false;
            let price = this.state.defaultPrice;
            let dateString = getApiDateFromDateObject(nextDay);
            //console.log("SEARCHING FOR", dateString);
            //console.log("CUSTOM DATES",this.props.customPlanePrices.customPrices[airplane][dateString],dateString);
            if (this.props.customPlanePrices.customPrices[planeId] !== null && this.props.customPlanePrices.customPrices[planeId] !== undefined){
                if (this.props.customPlanePrices.customPrices[planeId][dateString] !== null && this.props.customPlanePrices.customPrices[planeId][dateString]  !== undefined){
                    price = this.props.customPlanePrices.customPrices[planeId][dateString];
                }
            }
            while(unavailsIndex < allUnavails.length){
                const startDateToCompare = allUnavails[unavailsIndex][0];
                const endDateToCompare = allUnavails[unavailsIndex][1];
                if(startDateToCompare.getDate() === nextDay.getDate() && startDateToCompare.getMonth() === nextDay.getMonth() && startDateToCompare.getFullYear() === nextDay.getFullYear()){
                    hasUnavailability = true;
                    console.warn('start date equals');
                    console.warn(startDateToCompare,nextDay,endDateToCompare);
                    unavailabilityDateTimes.push(allUnavails[unavailsIndex]);
                } else if(endDateToCompare.getDate() === nextDay.getDate() && endDateToCompare.getMonth() === nextDay.getMonth() && endDateToCompare.getFullYear() === nextDay.getFullYear()){
                    hasUnavailability = true;
                    console.warn('end date equals');
                    console.warn(startDateToCompare,nextDay,endDateToCompare);
                    unavailabilityDateTimes.push(allUnavails[unavailsIndex]);
                } else if(startDateToCompare.getTime() <= nextDay.getTime() &&  nextDay.getTime() <= endDateToCompare.getTime()){
                    hasUnavailability = true;
                    console.warn('date is between two');
                    console.warn(startDateToCompare,nextDay,endDateToCompare);
                    unavailabilityDateTimes.push(allUnavails[unavailsIndex]);
                }
                unavailsIndex ++
            }
            newDays.push({ dateString: dateString, date:nextDay,price:price,validDay:true,planeId:planeId,unavailabilityDateTimes:unavailabilityDateTimes,
                hasUnavailability:hasUnavailability});
            console.log(newDays);
            daysToAdd ++;
            nextDay = new Date(this.state.date);
            nextDay.setDate(firstDay.getDate()+daysToAdd);
            nextMonth = nextDay.getMonth();
        }
        return (
            <div className="planePriceCalendar">
                <Row>
                    <Col>
                        <h3 className="planeCalendarDateTitle">{months[this.state.date.getMonth()]} {this.state.date.getFullYear()} </h3>
                    </Col>
                    <Col sm={{span: 3}}>
                        <div className='place-calendar-click-arrow' onClick={ (e) => this.updateDate(-1)}> {'<'} </div>
                        <div className='place-calendar-click-arrow' onClick={ (e) => this.updateDate(1)}> > </div>
                    </Col>
            </Row>
            <Row>
                <Col className='calendarDayHeader'>Sunday</Col>
                <Col className='calendarDayHeader'>Monday</Col>
                <Col className='calendarDayHeader'>Tuesday</Col>
                <Col className='calendarDayHeader'>Wednesday</Col>
                <Col className='calendarDayHeader'>Thursday</Col>
                <Col className='calendarDayHeader'>Friday</Col>
                <Col className='calendarDayHeader'>Saturday</Col>
            </Row>
            <Row>
                <PlaneCalenderDay planeDayPrice={newDays[0]}/>
                <PlaneCalenderDay planeDayPrice={newDays[1]}/>
                <PlaneCalenderDay planeDayPrice={newDays[2]}/>
                <PlaneCalenderDay planeDayPrice={newDays[3]}/>
                <PlaneCalenderDay planeDayPrice={newDays[4]}/>
                <PlaneCalenderDay planeDayPrice={newDays[5]}/>
                <PlaneCalenderDay planeDayPrice={newDays[6]}/>
            </Row>
                <Row>
                    <PlaneCalenderDay planeDayPrice={newDays[7]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[8]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[9]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[10]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[11]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[12]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[13]}/>
                </Row>
                <Row>
                    <PlaneCalenderDay planeDayPrice={newDays[14]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[15]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[16]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[17]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[18]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[19]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[20]}/>
                </Row>
                <Row>
                    <PlaneCalenderDay planeDayPrice={newDays[21]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[22]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[23]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[24]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[25]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[26]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[27]}/>
                </Row>
                <Row>
                    <PlaneCalenderDay planeDayPrice={newDays[28]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[29]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[30]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[31]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[32]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[33]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[34]}/>
                </Row>
                <Row>
                    <PlaneCalenderDay planeDayPrice={newDays[35]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[36]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[37]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[38]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[39]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[40]}/>
                    <PlaneCalenderDay planeDayPrice={newDays[41]}/>
                </Row>
        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        planeAdminCalendar: state.planeAdminCalendar,
        customPlanePrices: state.customPlanePrices
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSetPlaneCustomDates: customDates => {
            dispatch(setCustomPlanePrice(customDates))
        },
        onSetPlaneCustomUnavail: customDates => {
            dispatch(setCustomPlaneUnavail(customDates))
        },
    }
};

const PlaneCalender = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaneCalenderComponent);
export default withRouter(PlaneCalender);