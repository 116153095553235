import React from "react";
import Form from "react-bootstrap/Form";
import RequestResponse from "../Alerts/RequestResult";
import Button from "react-bootstrap/Button";
import FormError from "../../FormError";
import {logger} from "../../Utilities";
import SwaggerUtility from "../../SwaggerUtility";
import validateFields from "../../utils";



class PlaneExtrasChange extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            shouldChargeFee: false,
            cleaningFee: "25.00",
            extrasId: null,
            showResult: false,
            resultError: false,
            resultMessage: "",
            errors:{}
        }
    }

    
    closeRequestResponse = () => {
        this.setState({showResult:false})
    };

    componentDidMount() {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft
                .aircraft_getExtras(
                    {id:this.props.planeId})
                .then(function (result) {
                    if (result.ok) {
                        console.log('FOUND Extras', result)
                        componentSelf.setExtras(result.obj)
                    }
                }).catch(function (error) {
                logger(error)
            })
                .catch(function (error) {
                    logger(error)
                })})
    }

    setExtras = (preferences) => {
        let cleaningFee = "";
        if(preferences.cleaningFee !==  null){
            cleaningFee = preferences.cleaningFee;
        }
        this.setState({
            shouldChargeFee:preferences.shouldChargeFee,
            cleaningFee: cleaningFee,
            extrasId: preferences.id
        });
        console.log("new extras");
        console.log(this.state)
    };

    handleResponse = (response) => {
        let resultMessage = "Error - Unable to update aircraft extras.";
        let error = true;
        if (response){
            if (response.status){
                if (response.status === 200){
                    resultMessage = "Success - Aircraft extras updated.";
                    error = false;
                }
            }
        }
        this.setState({
            showResult: true,
            resultError: error,
            resultMessage: resultMessage
        })
    }

    updateExtras = () => {
        let valid = false;
        console.log(this.state);
        if(!this.state.shouldChargeFee){
            valid = true;
            this.setState({errors: {}});
        } else {
            let result = validateFields(this.state, "aircraftExtras");
            logger("form is");
            logger(result[0]);
            valid = result[0];
            this.setState({errors: result[1]});
        }
        const componentSelf = this;
        if(valid){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            if(this.state.extrasId !== null){
                console.log("updating extras");
                const putData = Object.assign({},this.state);
                if(putData.cleaningFee === ""){
                    putData.cleaningFee = null;
                }
                console.log(putData);
                putData['aircraft'] = this.props.planeId;
                swaggerClient.then((client) => {
                    client.apis
                        .extras
                        .extras_update(
                            {id:this.state.extrasId,data:putData})
                        .then(function (result) {
                            if (result.ok) {
                                logger("Updated extras");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setExtras(result.obj);
                                componentSelf.handleResponse(result);
                            }
                        }).catch(function (error) {
                        logger(error);
                        componentSelf.handleResponse(error);
                    })
                        .catch(function (error) {
                            logger(error);
                            componentSelf.handleResponse(error);
                        })})
            }
        }
    }

    render() {
        return (
            <div className="planeAdminPageWrapper">
                <RequestResponse handleClose={this.closeRequestResponse} error={this.state.resultError} show={this.state.showResult} message={this.state.resultMessage}/>
                <h3 className="blueText plane-admin-heading">Extras</h3>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Charge Cleaning Fee
                    </Form.Label>
                    <br />
                    <Form.Control as="select" value={this.state.shouldChargeFee} onChange={(e) => {
                        let val = false;
                        let fee = this.state.cleaningFee;
                        if(e.target.value === 'true' || e.target.value === true){
                            val = true;
                        } else {
                            fee = "";
                        }
                        this.setState({
                            shouldChargeFee: val,
                            cleaningFee: fee
                        })
                    }}>
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Cleaning Fee
                    </Form.Label>
                    <Form.Control value={this.state.cleaningFee}  type="text" onChange={(e) => this.setState({cleaningFee:e.target.value})} disabled={!this.state.shouldChargeFee}>
                    </Form.Control>
                    <FormError formField="cleaningFee" errors = {this.state.errors} />
                </Form.Group>
                <Button variant="primary" onClick={this.updateExtras}>Update Aircraft Extras</Button>
            </div>
        )
    }

}

export default PlaneExtrasChange;