import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import PlaneAdminMenu from "./PlaneAdminMenu";
import ManagedPlanePhoto from "./ManagedPlanePhoto";
import SwaggerUtility from "../../SwaggerUtility";
import {logger} from "../../Utilities";
import HostPlaneItem from "../Host/HostPlaneListItem";
import Form from "react-bootstrap/Form";
import {FilePond} from "react-filepond";
import FormError from "../../FormError";
import validateFields from "../../utils";
import Button from "react-bootstrap/Button";


class PlanePhotoChange extends React.Component {

    componentDidMount() {
        this.retrievePhotos();
    }

    constructor(props){
        super(props);
        this.state = {
            photos : [],
            newPhoto: null,
            type: 'interior',
            errors: {}
        }
    }

    createPhoto = () => {
        if(this.state.photos.length >= 20){
            alert('Only 20 photos allowed per plane');
        } else {
            let result = validateFields(this.state, "aircraftPhotoForm");
            logger("form is");
            logger(result[0]);
            const componentSelf = this;
            this.setState({errors: result[1]});
            if (result[0]) {
                logger("creating image with data");
                let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
                let photoData = {};
                photoData.image = this.state.newPhoto.file;
                photoData.type = this.state.type;
                photoData.aircraft = this.props.planeId;
                logger(photoData);
                swaggerClient.then((client) => {
                    client.apis
                        .aircraftImage.aircraftImage_create(
                        photoData
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Aircraft Photo");
                                logger(result);
                                logger(result.obj);
                                componentSelf.retrievePhotos();
                                alert('Photo Successfully added');
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    retrievePhotos = () => {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft.aircraft_getPictures(
                {id: this.props.planeId}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Got Existing Plane Photos");
                        logger(result);
                        logger(result.obj);
                        componentSelf.setState(result.obj)
                    }

                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    deleteImage = (imageInfo) => {
        logger('deleting image');
        logger(imageInfo);
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraftImage.aircraftImage_delete(
                {id: imageInfo.id}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Delete Plane Photo");
                        logger(result);
                        componentSelf.retrievePhotos();
                    }

                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    render() {
        let over20Html = null;
        if(this.state.photos.length >= 20){
            over20Html = <p className="field-error"> Only 20 Plane Photos allowed </p>;
        }
        return (
            <div className="planePhotos">
                <h3 className="blueText plane-admin-heading"> Add Photo</h3>
                <Form.Group>
                    <FilePond
                        allowFileTypeValidation={true}
                        allowImageExifOrientation={true}
                        acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                        onupdatefiles={fileItems => {
                            if (fileItems.length > 0){
                                this.setState({
                                    newPhoto: fileItems[0]
                                });
                            }
                        }}
                        allowMultiple={false}
                        maxFiles={1}/>
                    <FormError formField="newPhoto" errors = {this.state.errors} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Image Type
                    </Form.Label>
                    <Form.Control as="select" onChange={(e) => this.setState({type:e.target.value})}>
                        <option>interior</option>
                        <option>exterior</option>
                        <option>medical</option>
                        <option>arrow</option>
                        <option>av1aes</option>
                        <option>license</option>
                    </Form.Control>
                </Form.Group>
                {over20Html}
                <Button variant="primary" onClick={this.createPhoto} disabled={this.state.photos.length >= 20}>Add Photo</Button>
                <h3 className="blueText marginTop"> Plane Photos </h3>
                {this.state.photos.map(photo => <ManagedPlanePhoto key={photo.id} handleClick={this.deleteImage} planeImage={photo}/>)}
            </div>
        )
    }

}

export default PlanePhotoChange;