import React from "react";
import Header from '../Header';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import connect from "react-redux/es/connect/connect";
import {Link, withRouter} from 'react-router-dom';
import Form from "react-bootstrap/Form";
import FormError from "../FormError";
import Button from "react-bootstrap/Button";
import validateFields from "../utils";
import SwaggerUtility from "../SwaggerUtility";
import {configs} from "../configurations";
import { logger } from '../Utilities';
import {logout, setProfile, setProfilePicture} from "../actions.tsx";
import { FilePond } from "react-filepond";
class VerifyIdComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            errors:[],
            picture:null,
            apiMethod:"POST",
            shouldUpdatePictureState:true
        }
    }

    componentDidMount() {
        console.log("DL Profile");
        console.log(this.props.profilePicture.picture);
        const idVerificationStatus = this.props.profile.idVerificationStatus;
        logger("Id Verification Status: "+idVerificationStatus);
        if(idVerificationStatus === "In Review"){
            logger("Profile is in review setting profile to put");
            this.setState({apiMethod:"PUT"});
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.profilePicture.id !== prevProps.profilePicture.id) {
            logger("PROFILE PIC CHANGED");
            this.setState({apiMethod: "PUT"});
        }
    }

    setProfilePhoto(userToken){
        const compSelf = this;
        logger("Settings profile picture information");
        let swaggerClient = SwaggerUtility(userToken);
        swaggerClient.then((client) => {
            client.apis
                .profilePicture.profilePicture_get_user_profile_photo(
                {id:this.props.profile.id}
            )
                .then(function(result){
                    if(result.ok){
                        console.log(result.obj);
                        compSelf.props.onSetProfilePicture(result.obj);
                        console.log(compSelf.props);
                    }
                })
        })
    }

    uploadProfilePicture = () => {
      logger("uploading profile picture");
      console.log(this.state);
        let result = validateFields(this.state, "profilePictureForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if (result[0]) {
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            let driversLicenseData = Object.assign({}, this.state);
            driversLicenseData["user"] = this.props.profile.id;
            driversLicenseData["picture"] = driversLicenseData["picture"];
            logger("profile picture data");
            logger(driversLicenseData);
            if (this.state.apiMethod === "POST") {
                logger("Profile picture API method is post");
                swaggerClient.then((client) => {
                    client.apis
                        .profilePicture.profilePicture_create(
                        driversLicenseData
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Profile Picture");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setProfilePhoto(localStorage.getItem('token'));
                            }

                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            } else {
                logger("Profile Picture API method is PUT");
                driversLicenseData["id"] = this.props.profilePicture.id;
                swaggerClient.then((client) => {
                    client.apis
                        .profilePicture.profilePicture_update((driversLicenseData))
                        .then(function (result) {
                            if (result.ok) {
                                logger("Put Profile picture");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setProfilePhoto(localStorage.getItem('token'));
                            }
                        }).catch(function (error) {
                        logger(error)}).catch(function (error) {
                    logger(error)
                })})
            }
        }
    };

    render(){
        let currentProfilePictureHtml = null;
        if(this.props.profilePicture.picture !== null){
            currentProfilePictureHtml = (
                <Form.Group>
                    <h3>Current Profile Picture</h3>
                    <img className="profilePictureView" src={configs.pictureUrl+this.props.profilePicture.picture}/>
                </Form.Group>
            )
        }
        return(
            <Header>
                <Container>
                    {currentProfilePictureHtml}
                    <Form.Group>
                        <Form.Label className="greyText">
                            Profile Picture
                        </Form.Label>
                    </Form.Group>
                    <FilePond
                        allowImageExifOrientation={true}
                        acceptedFileTypes={['image/png', 'image/jpeg']}
                        onupdatefiles={fileItems => {
                            console.log(fileItems);
                            if (fileItems.length > 0){
                                this.setState({
                                    picture: fileItems[0].file
                                })
                            }

                    }}  allowMultiple={false} maxFiles={1}/>
                    <FormError formField="picture" errors = {this.state.errors} />
                    <Button variant="primary" onClick={this.uploadProfilePicture}>Upload Profile Picture</Button>
                </Container>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        profilePicture: state.profilePicture,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSetProfilePicture: profilePicture => {
            dispatch(setProfilePicture(profilePicture))
        }
    }
};

const VerifyId= connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyIdComponent);
export default withRouter(VerifyId);