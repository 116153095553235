import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import {Link} from 'react-router-dom';

class PlaneAdminMenu extends React.Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="planeAdminCalendarMenu">
        <Row>
            <Col>
                <Link to={"/planeAdmin/calendar/"+this.props.planeId.toString()}><p className={this.props.selected === 'Calendar' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Calendar</p></Link>
            </Col>
        </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/pricing/"+this.props.planeId.toString()}><p className={this.props.selected === 'Pricing' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Pricing</p></Link>
                    </Col>
                </Row>
        <Row>
            <Col>
                <Link to={"/planeAdmin/location/"+this.props.planeId.toString()}><p className={this.props.selected === 'Location' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Location</p></Link>
            </Col>
        </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/photos/"+this.props.planeId.toString()}><p className={this.props.selected === 'Photos' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Photos</p></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/guestInstructions/"+this.props.planeId.toString()}><p className={this.props.selected === 'guestInstructions' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Guest Instructions</p></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/description/"+this.props.planeId.toString()}><p className={this.props.selected === 'description' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Description</p></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/guidelines/"+this.props.planeId.toString()}><p className={this.props.selected === 'guidelines' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Guidelines</p></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/preferences/"+this.props.planeId.toString()}><p className={this.props.selected === 'preferences' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Trip Preferences</p></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to={"/planeAdmin/extras/"+this.props.planeId.toString()}><p className={this.props.selected === 'extras' ? 'highlighted-menu-item cursor-pointer' : 'cursor-pointer'}>Extras</p></Link>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default PlaneAdminMenu;