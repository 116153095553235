import React from "react";
import Header from './Header';
import Container from 'react-bootstrap/Container';
import connect from "react-redux/es/connect/connect";
import SwaggerUtility from "./SwaggerUtility";
import { logger } from './Utilities';

class EmailTokenValidatedComp extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loaded:false,
            successful:false
        }
    }
    componentDidMount() {
        logger("Validating Email");
        logger(this.props);
        const token = this.props.token;
        const componentSelf = this;
        const swaggerClient = SwaggerUtility();
        swaggerClient.then((client) => {
            logger(this.props.signUpInformation);
            logger(client);
            client.apis
                .confirmEmail.confirmEmail_update(
                {data:{token:token}}
            )
                .then(function(result){
                    logger(result);
                    logger(result.ok);
                    if(result.ok){
                        logger("email validated!");
                        componentSelf.setState({successful:true})
                    }
                }).catch(function(error){logger(error)})
        }).catch(function(error){logger(error)});
        componentSelf.setState({loaded:true});
    };


    render(){
        if(this.state.loaded){
            if(this.state.successful){
                return(
                    <Header>
                        <Container className="text-center">
                            <h3 className="emailResponse"> Email Successfully Validated!</h3>
                        </Container>
                    </Header>
                )
            }
            else{
                return(
                    <Header>
                        <Container className="text-center">
                            <h3 className="emailResponse">Unable to validate email address. Please try again!</h3>
                        </Container>
                    </Header>
                )
            }
        }
        return(
            <Header>
                <Container>
                    Email Successfully Validated!
                </Container>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const EmailTokenValidated= connect(
    mapStateToProps
)(EmailTokenValidatedComp);
export default EmailTokenValidated;