import { configs } from "./configurations.js";
import moment from "moment";
export function handleFailedRequest(){
    alert("Request failed. Please try again.")
}

export function getMultiSelectValues(inputId){
    var select1 = document.getElementById(inputId);
    var selected1 = [];
    for (var i = 0; i < select1.length; i++) {
        if (select1.options[i].selected) selected1.push(select1.options[i].value);
    }
    logger("selectedValues");
    logger(selected1);
    return selected1
}

export function getMultiCheckValues(inputId){
    var inputElements = document.getElementsByClassName(inputId);
    let selected1 = [];
    for(var i=0; inputElements[i]; ++i){
        var checkBoxChild = inputElements[i].querySelector('.form-check-input');
        if(checkBoxChild.checked){
            selected1.push(inputElements[i].textContent);
        }
    }
    return selected1
}

export function logger(text){
    try{
        if(configs.debug === 'true'){
            console.log(text);
        }
    }
    catch (e) {
        console.error(e)
    }
}

export const getStringFromNull = (input) => {
    return input === null ? "" : input;
};

export const getFilePondMediaUrl = (urlString) => {
    let newUrl;
    if(urlString !== null){
    if(urlString[0] === '/'){
        newUrl=urlString.substring(1,urlString.length);
    }
    else{
        newUrl = urlString;
    }
    }
    console.log(newUrl);
    return newUrl
};

export const getBlobOrFile = (blob) => {
    let newFile = blob;
    if(blob instanceof Blob){
        console.log("blob received");
        console.log(blob);
        newFile = new File([blob], blob.name, {type: blob.type, lastModified: blob.lastModifiedDate});
    }
    return newFile;
};

export const getFormattedDate = (date) => {
    try {
        const formattedDate = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        logger("formattedDate");
        logger(formattedDate);
        return formattedDate
    } catch (e) {
        console.error("Unable to parse date");
        console.error(e);
        return null;
    }
};

export const getApiDateFromDateObject = (dateObject) => {
    var dd = dateObject.getDate();
    var mm = dateObject.getMonth() + 1; //January is 0!

    var yyyy = dateObject.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return  yyyy + '-' + mm + '-' + dd;
};

export const validateDateFields = function(values, dates, startAndEnd = {}, errorField=null){
    const errors = {};
    let valid = true;
    logger("values received",values);
    let keys = Object.keys(values);
    logger("Validating");
    logger(keys);
    for(let i = 0;i<dates.length;i++){
        const dateToCheck = moment(values[dates[i]]);
        if(!dateToCheck.isValid()){
            errors[dates[i]] = ["Invalid Date"];
            valid = false;
        }
    }
    if(valid){
        if(startAndEnd.hasOwnProperty("start")){
            const startDate = values[startAndEnd["start"]];
            const startTime = values[startAndEnd["startTime"]];
            const endDate = values[startAndEnd["end"]];
            const endTime = values[startAndEnd["endTime"]];
            const startMoment = moment(startDate+' '+startTime, "YYYY-MM-DD hh:mm a");
            const endMoment = moment(endDate+' '+endTime);
            console.log(startMoment,endMoment);
            if(!endMoment.isAfter(startMoment)){
                errors[errorField] = ["End date must be after start date"];
                valid = false;
            }
        }
    }
    logger("Form errors");
    logger(errors);
    return [valid,errors]
};