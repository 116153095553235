import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormError from './FormError'
import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';
import SwaggerUtility from './SwaggerUtility';
import {handleFailedRequest,getFormattedDate} from './Utilities';
import { logger } from './Utilities';
import {
    setSignupBirthdayMonth,
    setSignUpPassword,
    setSignupFirstName,
    setSignUpConfirmPassword,
    setSignUpZipcode,
    setSignupBirthdayDay,
    setSignupUsername,
    setSignupUseBirthdayYear,
    setSignupState,
    setSignupMobileNumber,
    setSignupLastName,
    setSignupCity,
    setSignupAddress,
    setSignupAddressTwo,
    setToken, setAuthenticated,
    setPilotTotalInCommandTime,setPilotHasInsurance,
    setAircraftCategory,setMedicalClass,setMedicalClassIssueDay,setMedicalClassIssueMonth,setMedicalClassIssueYear,setPilotTotalTime,setPilotCertifications,setPilotRatingsAndEndorsements,setPilotInsurancePolicyNumber,setPilotInsuranceIssueMonth,setPilotInsuranceIssueDay,setPilotInsuranceIssueYear,setPilotInsuranceExpirationMonth,setPilotInsuranceExpirationDay,setPilotInsuranceCarrier,setPilotInsuranceExpirationYear
} from './actions.tsx';
import { connect } from 'react-redux';
import validateFields from './utils'
import {withRouter} from "react-router";
import DatePicker from "react-datepicker";
class SignUpComp extends React.Component{

    constructor(props){
      super(props);
      this.state = {
        birthday:null,
         birthdayDatePickerDate:null,
        errors: {},
      };
    }

    birthdayChange = (date) => {
        this.setState({birthday:getFormattedDate(date)});
        this.setState({birthdayDatePickerDate:date});
    };

    register = () => {
        const componentSelf = this;
        const skippedFields = {};
        let signUpData = Object.assign({}, this.props.signUpInformation);
        signUpData.birthday = this.state.birthday;
        const result = validateFields(signUpData,"signUpForm",skippedFields);
        logger("form is");
        logger(result[0]);
        this.setState({errors:result[1]});
        if(result[0]){
            this.sendRegistration(componentSelf);
        }
    };

    sendRegistration = (componentSelf) => {
        logger("profile info");
        const swaggerClient =  SwaggerUtility();
        let signUpData = Object.assign({}, this.props.signUpInformation);
        signUpData.birthday = this.state.birthday;
        swaggerClient.then((client) => {
          logger(signUpData);
          logger(client);
        client.apis
        .users.users_create(
        {data:signUpData}
        )
      .then(function(result){
        logger(result);
        logger(result.ok);
        if(result.ok){
            const createdUser = result.obj;
            logger(createdUser);
            const token = createdUser.token;
            logger(token);
            setToken(token);
            localStorage.setItem('token', token);
            componentSelf.props.history.push("/Profile");
            setAuthenticated(true);
        }
      }).catch(function(error){logger(error);handleFailedRequest()})
        }).catch(function(error){logger(error);handleFailedRequest()})
    };

    render() {
        return (
          <Header>
            <Container>
              <Row>
                <Col sm={{span: 6, offset:3}}>
              <Form.Group>
                  <h3 className="formBorderTitle blueText">Personal Information</h3>
                <Form.Label className="formLabel greyText">
                Email
                </Form.Label>
                <Form.Control type="email" name="email" placeholder="email@email.com" onChange={(e) => this.props.onChangeUsername(e.target.value)} />
                <FormError formField="username" errors = {this.state.errors} /> 
              </Form.Group>
              <Form.Group>
                <Form.Label className="formLabel greyText">
                First Name
                </Form.Label>
                <Form.Control type="text" name="firstName" placeholder="First" onChange={(e) => this.props.onChangeFirstName(e.target.value)} />
                <FormError formField="firstName" errors = {this.state.errors} /> 
              </Form.Group>
              <Form.Group>
                <Form.Label className="formLabel greyText">
                Last Name
                </Form.Label>
                <Form.Control type="text" name="lastName" placeholder="Last" onChange={(e) => this.props.onChangeLastName(e.target.value)} />
                <FormError formField="lastName" errors = {this.state.errors} /> 
              </Form.Group>
              <Form.Group>
                <Form.Label className="formLabel greyText">
                Mobile Number
                </Form.Label>
                <Form.Control type="text" name="phoneNumber" placeholder="5555555555" onChange={(e) => this.props.onChangeNumber(e.target.value)} />
                <FormError formField="mobileNumber" errors = {this.state.errors} /> 
              </Form.Group>
<Form.Group>
<Form.Label className="formLabel greyText">
Birthday
</Form.Label>
    <Form.Row>
        <DatePicker selected={this.state.birthdayDatePickerDate} onChange={this.birthdayChange} />
    </Form.Row>
    <FormError formField="birthday" errors = {this.state.errors} />
</Form.Group>
                    <Form.Group controlId="formGridAddress1">
                        <Form.Label className="formLabel greyText">Address</Form.Label>
                        <Form.Control placeholder="1234 Main St" onChange={(e) => this.props.onChangeAddress(e.target.value)}/>
                        <FormError formField="address" errors = {this.state.errors} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress2">
                        <Form.Label className="formLabel greyText">Address 2</Form.Label>
                        <Form.Control placeholder="Apartment, studio, or floor" onChange={(e) => this.props.onChangeAddressTwo(e.target.value)}/>
                        <FormError formField="addressTwo" errors = {this.state.errors} />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label className="formLabel greyText">City</Form.Label>
                            <Form.Control onChange={(e) => this.props.onChangeCity(e.target.value)}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label className="formLabel greyText">State</Form.Label>
                            <Form.Control as="select" onChange={(e) => this.props.onChangeState(e.target.value)}>
                                <option>AK</option>
                                <option>AL</option>
                                <option>AZ</option>
                                <option>AR</option>
                                <option>CA</option>
                                <option>CO</option>
                                <option>CT</option>
                                <option>DC</option>
                                <option>DE</option>
                                <option>FL</option>
                                <option>GA</option>
                                <option>HI</option>
                                <option>ID</option>
                                <option>IL</option>
                                <option>IN</option>
                                <option>IA</option>
                                <option>KS</option>
                                <option>KY</option>
                                <option>LA</option>
                                <option>ME</option>
                                <option>MD</option>
                                <option>MA</option>
                                <option>MI</option>
                                <option>MN</option>
                                <option>MS</option>
                                <option>MO</option>
                                <option>MT</option>
                                <option>NE</option>
                                <option>NV</option>
                                <option>NH</option>
                                <option>NJ</option>
                                <option>NM</option>
                                <option>NY</option>
                                <option>NC</option>
                                <option>ND</option>
                                <option>OH</option>
                                <option>OK</option>
                                <option>OR</option>
                                <option>PA</option>
                                <option>RI</option>
                                <option>SC</option>
                                <option>SD</option>
                                <option>TN</option>
                                <option>TX</option>
                                <option>UT</option>
                                <option>VT</option>
                                <option>VA</option>
                                <option>WA</option>
                                <option>WV</option>
                                <option>WI</option>
                                <option>WY</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label className="formLabel greyText">Zip</Form.Label>
                            <Form.Control onChange={(e) => this.props.onChangeZipcode(e.target.value)}/>
                        </Form.Group>
                    </Form.Row>

                    <FormError formField="city" errors = {this.state.errors} />
                    <FormError formField="zipcode" errors = {this.state.errors} />
              <Form.Group>
                  <h3 className="formBorderTitle blueText">Password</h3>
                <Form.Label className="formLabel greyText">
                Password
                </Form.Label>
                <Form.Control type="password" name="password" placeholder="Enter Password" onChange={(e) => this.props.onChangePassword(e.target.value)} />
                <FormError formField="password" errors = {this.state.errors} /> 
              </Form.Group>
              <Form.Group>
                <Form.Label className="formLabel greyText">
                Confirm Password
                </Form.Label>
                <Form.Control type="password" name="confirmPassword" placeholder="Confirm Password" onChange={(e) => this.props.onChangeConfirmPassword(e.target.value)}/>
                <FormError formField="confirmPassword" errors = {this.state.errors} /> 
              </Form.Group>

            </Col>
            </Row>
                <Row className="text-center top-bottom-pad">
                    <Col className="text-center"><Button variant="primary" onClick={this.register}>Sign up</Button></Col>
                </Row>
            </Container>
        </Header>
        )
    }

}

const mapStateToProps = state => {
    logger("state update!");
    logger(state);
    return {
      signUpInformation: state.signup
    }
};

const mapDispatchToProps = dispatch => {
    return {
      onChangeUsername: text => {  
        dispatch(setSignupUsername(text))
      },
      onChangePassword: text => {
        dispatch(setSignUpPassword(text))
      },
      onChangeConfirmPassword: text => {
        dispatch(setSignUpConfirmPassword(text))
      },
      onChangeFirstName: text => {
        dispatch(setSignupFirstName(text))
      },
      onChangeLastName: text => {
        dispatch(setSignupLastName(text))
      },
      onChangeBirthdayDay: text => {  
        dispatch(setSignupBirthdayDay(text))
      },
      onChangeBirthdayMonth: text => {
        dispatch(setSignupBirthdayMonth(text))
      },
      onChangeBirthdayYear: text => {
        dispatch(setSignupUseBirthdayYear(text))
      },
      onChangeState: text => {
        dispatch(setSignupState(text))
      },
      onChangeCity: text => {
        dispatch(setSignupCity(text))
      },
      onChangeAddress: text => {
        dispatch(setSignupAddress(text))
      },
      onChangeNumber: text => {
        dispatch(setSignupMobileNumber(text))
      },
      onChangeZipcode: text => {
        dispatch(setSignUpZipcode(text))
      },
      onChangeAddressTwo: text => {
        dispatch(setSignupAddressTwo(text))
      },
      onChangePilotTotalTime: input => {
          dispatch(setPilotTotalTime(input))
      },
      onChangePilotTotalInCommandTime: input => {
          dispatch(setPilotTotalInCommandTime(input))
      },
        onChangeRentersInsurance: input => {
            dispatch(setPilotInsuranceCarrier(input))
        },
        onChangeInsurancePolicyNumber: input => {
            dispatch(setPilotInsurancePolicyNumber(input))
        },
        onChangeInsuranceDateIssuedDay: input => {
            dispatch(setPilotInsuranceIssueDay(input))
        },
        onChangeInsuranceDateIssuedMonth: input => {
            dispatch(setPilotInsuranceIssueMonth(input))
        },
        onChangeInsuranceDateIssuedYear: input => {
            dispatch(setPilotInsuranceIssueYear(input))
        },
        onChangeInsuranceDateExpireDay: input => {
            dispatch(setPilotInsuranceExpirationDay(input))
        },
        onChangeInsuranceDateExpireMonth: input => {
            dispatch(setPilotInsuranceExpirationMonth(input))
        },
        onChangeInsuranceDateExpireYear: input => {
            dispatch(setPilotInsuranceExpirationYear(input))
        },
        onChangeRatingsAndEndorsements: input => {
            dispatch(setPilotRatingsAndEndorsements(input))
        },
        onChangeCertifications: input => {
            dispatch(setPilotCertifications(input))
        },
        onChangeHasInsurance: input => {
            dispatch(setPilotHasInsurance(input))
        },
        onChangeMedicalClassIssueDay: input => {
            dispatch(setMedicalClassIssueDay(input))
        },
        onChangeMedicalClassIssueMonth: input => {
            dispatch(setMedicalClassIssueMonth(input))
        },
        onChangeMedicalClassIssueYear: input => {
            dispatch(setMedicalClassIssueYear(input))
        },
        onChangeMedicalClass: input => {
            dispatch(setMedicalClass(input))
        },
        onChangeAircraftCategory: input => {
            dispatch(setAircraftCategory(input))
        },
    }
};

const SignUp = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpComp);

export default withRouter(SignUp);