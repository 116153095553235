import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import PlaneAdminMenu from "./PlaneAdminMenu";
import PlaneExtrasChange from "./PlaneExtrasChange";



class PlaneExtras extends React.Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Header>
                <Container fluid={true}>
                    <PlaneAdminMenu planeId={this.props.planeId} selected="extras"></PlaneAdminMenu>
                    <PlaneExtrasChange planeId={this.props.planeId}></PlaneExtrasChange>
                </Container>
            </Header>
        )
    }

}

export default PlaneExtras;