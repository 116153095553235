import React from "react";
import Header from './Header';
import BootstrapModal from './BootstrapModal';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import connect from "react-redux/es/connect/connect";
import {Link, withRouter} from 'react-router-dom';
import Form from "react-bootstrap/Form";
import FormError from "./FormError";
import UploadedPhoto from "./UploadedPhoto";
import Button from "react-bootstrap/Button";
import validateFields from "./utils";
import SwaggerUtility from "./SwaggerUtility";
import { logger } from './Utilities';

class VerifyIdComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            errors:[],
            front:null,
            back:null,
            apiMethod:"POST",
            currentDLImageId:null
        }
    }

    componentDidMount() {
        console.log("DL Profile");
        console.log(this.props.profile);
        const idVerificationStatus = this.props.profile.idVerificationStatus;
        logger("Id Verification Status: "+idVerificationStatus);
        if(idVerificationStatus === "In Review"){
            logger("Profile is in review setting profile to put");
            this.setState({apiMethod:"PUT"});

        }

    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.profile.idVerificationStatus !== prevProps.profile.idVerificationStatus ) {
            logger("DRIVERS LICENSE PROFILE CHANGED");
            if(this.props.profile.idVerificationStatus === "In Review"){
                const componentSelf = this;
                logger("Profile is in review setting profile to put");
                this.setState({apiMethod:"PUT"});
                let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
                logger("Profile ID for getting drivers license: "+this.props.profile.id);
                swaggerClient.then((client) => {
                    client.apis
                        .get_license.get_license(
                        {id:this.props.profile.id}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Got Existing License");
                                logger(result);
                                logger(result.obj);
                                logger("setting drivers license ID as: "+result.obj.id)
                                componentSelf.setState({currentDLImageId:result.obj.id})
                            }

                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    }

    uploadDriverLicensePhotos = () => {
      logger("uploading drivers license photos");
        let result = validateFields(this.state, "driverLicensePhotoForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if (result[0]) {
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            let driversLicenseData = Object.assign({}, this.state);
            driversLicenseData["user"] = this.props.profile.id;
            driversLicenseData["id"] = this.state.currentDLImageId;
            driversLicenseData["front"] = driversLicenseData["front"][0];
            driversLicenseData["back"] = driversLicenseData["back"][0];
            logger("driversLicenseData");
            logger(driversLicenseData);
            if (this.state.apiMethod === "POST") {
                logger("Drivers license API method is post");
                swaggerClient.then((client) => {
                    client.apis
                        .driversLicense.driversLicense_create(
                        driversLicenseData
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Drivers License");
                                logger(result);
                                logger(result.obj);
                                componentSelf.props.history.push("/profile");
                            }

                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            } else {
                logger("Drivers license API method is PUT");
                swaggerClient.then((client) => {
                    client.apis
                        .driversLicense.driversLicense_update(driversLicenseData)
                        .then(function (result) {
                            if (result.ok) {
                                logger("Put Drivers License");
                                logger(result);
                                logger(result.obj);
                                componentSelf.props.history.push("/profile");
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    render(){
        return(
            <Header>
                <Container>
                    <Form.Group>
                        <Form.Label className="greyText">
                            Drivers License Front
                        </Form.Label>
                        <Form.Control type="file" name="front" onChange={(e) => this.setState({front:e.target.files})} />
                        <FormError formField="front" errors = {this.state.errors} />
                        <UploadedPhoto photo={this.state.front}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="greyText">
                            Drivers License Back
                        </Form.Label>
                        <Form.Control type="file" name="back" onChange={(e) => this.setState({back:e.target.files})} />
                        <FormError formField="back" errors = {this.state.errors} />
                        <UploadedPhoto photo={this.state.back}/>
                    </Form.Group>
                    <Button variant="primary" onClick={this.uploadDriverLicensePhotos}>Upload Drivers License</Button>
                </Container>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const VerifyId= connect(
    mapStateToProps
)(VerifyIdComponent);
export default withRouter(VerifyId);