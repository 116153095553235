import React from "react";
import Alert from 'react-bootstrap/Alert';


class Alerter extends React.Component {

    render(){
        if(this.props.show){
            return (
                <Alert variant={this.props.variant} onClick={() => this.props.handleClick()} dismissible>
                    <Alert.Heading>{this.props.heading}</Alert.Heading>
                    <p>
                    {this.props.message}
                    </p>
                    {/*<hr />*/}
                    {/*<p className="mb-0">*/}
                        {/*Whenever you need to, be sure to use margin utilities to keep things nice*/}
                        {/*and tidy.*/}
                    {/*</p>*/}
                </Alert>
            )
        }
        else{
            return null
        }
    }
}

export default Alerter;