import React from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import skyKandyLogo from './sky-kandy-logo.png';
import SwaggerUtility from './SwaggerUtility';
import {configs} from "./configurations";
import { NavLink } from 'react-router-dom';
import {
    withRouter
} from 'react-router-dom'

import {
    setProfile, logout, setProfilePicture
} from "./actions.tsx";
import { logger } from './Utilities';
class HeaderContainer extends React.Component{

    constructor(props){
        super(props);
        logger("props");
        logger(this.props);
    }

    componentDidMount(){
        var userToken = localStorage.getItem('token');
        let compSelf = this;
        if(userToken !== undefined){
            let swaggerClient = SwaggerUtility(userToken);
            swaggerClient.then((client) => {
                client.apis
                    .token.token_create(
                    {data:{"token":userToken}}
                )
                    .then(function(result){
                        if(result.ok){
                            let profile = {
                                firstName:null,
                                lastName:null,
                                username:null,
                                token:null,
                                id:null,
                                emailVerified:null,
                                idVerificationStatus:null
                            };
                            profile.firstName = result.obj.user.first_name;
                            profile.lastName = result.obj.user.last_name;
                            profile.username = result.obj.user.username;
                            profile.token = result.obj.token;
                            profile.id = result.obj.user.id;
                            profile.emailVerified = result.obj.user.emailVerified;
                            profile.idVerificationStatus = result.obj.user.idVerificationStatus;
                            compSelf.props.onSetProfile(profile);
                            logger("setting profile");
                            logger(result.obj.user);
                            compSelf.setProfilePhoto(userToken);
                        }
                    }).catch(function(error){logger(error);localStorage.removeItem('token')})
            }).catch(function(error){logger(error);localStorage.removeItem('token')})
        }

    }

    setProfilePhoto(userToken){
        const compSelf = this;
        logger("Settings profile picture information");
        let swaggerClient = SwaggerUtility(userToken);
        swaggerClient.then((client) => {
            client.apis
                .profilePicture.profilePicture_get_user_profile_photo(
                {id:this.props.profile.id}
            )
                .then(function(result){
                    if(result.ok){
                        compSelf.props.onSetProfilePicture(result.obj);
                    }
                })
        })
    }

    handleLogout(){
        logger("logging out");
        logger(this.props);
        localStorage.removeItem('token');
        this.props.onLogOut();
        this.props.history.push('/')
    }

    render(){
        let items= null;
        let profilePictureHtml = null;
        if(!this.props.profile.isAuthenticated){
            if(configs.signupEnabled === true){
                items = (
                    <Nav className="justify-content-end ml-auto" >
                        {/*
                        <Nav.Item>
                            <NavLink to="/signup/owner">OWNER SIGNUP</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/signup/pilot">PILOT SIGNUP</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/login">LOGIN</NavLink>
                        </Nav.Item>
                        */}
                    </Nav>
                )
            } else {
                items = null;
            }
        }
        else{
            if(this.props.profilePicture.picture !== null){
                profilePictureHtml = (
                    <Nav.Item>
                        <Nav.Link href="/profile"><img className="profilePicture" src={configs.pictureUrl+this.props.profilePicture.picture}/></Nav.Link>
                    </Nav.Item>)
            }
            items = (
                <Nav className="justify-content-end ml-auto" >
                    <Nav.Item>
                        <NavDropdown title="Host" id="basic-nav-dropdown1">
                            <NavDropdown.Item href="/list-your-plane">List Plane</NavDropdown.Item>
                            <NavDropdown.Item href="/hostPlanes">Planes</NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                        <Nav.Item>
                    <Nav.Link href="/signup">Trips</Nav.Link>
                        </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/signup">Messages</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <NavDropdown title="Profile" id="basic-nav-dropdown">
                            {/*<NavDropdown.Item href="list-your-plane">List Your Plane</NavDropdown.Item>*/}
                            {/*<NavDropdown.Divider />*/}
                            <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={(e) => {return this.handleLogout()}}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                    {profilePictureHtml}
                </Nav>
        )
        }
        return (
          <div>
          <Navbar className="blurred-border-bottom" collapseOnSelect expand="md">
              <Navbar.Brand><img id="skyKandyLogo" src={skyKandyLogo}></img></Navbar.Brand> <span className="betaBrand">Beta</span>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-end ml-auto" >
                {items}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
    {this.props.children}
    </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetProfilePicture: profilePicture => {
            dispatch(setProfilePicture(profilePicture))
        },
        onSetProfile: profile => {
            dispatch(setProfile(profile))
        },
        onLogOut: () => {
            dispatch(logout());
        }
    }
};
const mapStateToProps = state => {
  return {
    profile: state.profile,
    profilePicture: state.profilePicture,
  }
};

const Header = connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderContainer);
export default withRouter(Header);