import React from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import { connect } from "react-redux";
import Header from "./Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import pilotBackgroundImage from "./images/Skykandy_BG_1024x744.png";
import coinsImage from "./images/Earn.png";
import calenderImage from "./images/Book.png";
import planeImage from "./images/Owners Insurance.png";
import frontPageAirplaneImage from "./images/frontPageAirPlane.jpg";
import BetaSignUpModal from "./BetaSignUpModal";

export default class Home extends React.Component {
  render() {
    return (
      <Header>
        <Container
          fluid={true}
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: "url(" + pilotBackgroundImage + ")",
            height: 744,
            objectFit: "cover",
            marginTop: -40,
          }}
        >
          <div className="home-page-pic-overlay">
            <Container>
              <div className="half-width-title">
                <p className="whiteText">AN AIRCRAFT RENTAL MARKETPLACE</p>
                <p className="whiteText frontPageSubHeader">
                  WHERE OPTIONS MEAN EVERYTHING!
                </p>
                <h3 className="whiteText">COMING SOON!</h3>
                {/*<input className="signupBox" placeholder="Sign up for first access to our beta.." type="text"/>*/}
                {/* <BetaSignUpModal /> */}
              </div>
            </Container>
          </div>
        </Container>
        <Container className="iconRow">
          <Row>
            <h2 className="pilotRow skykandyGreen videoTitle">
              AN AIRCRAFT MARKETPLACE FOR THE PEOPLE BY THE PEOPLE
            </h2>
          </Row>
          <Row>
            <Col md={{ span: 4 }}>
              <Row>
                <img className="headerIcon" src={coinsImage} />{" "}
              </Row>
              <Row>
                <p className="bythepeopleheader">Competitive Pricing</p>
                <p className="bythepeoplesubheader">
                  We compare our prices to the competition so that you always
                  get the best deals.
                </p>
              </Row>
            </Col>
            <Col md={{ span: 4 }}>
              <Row>
                <img className="headerIcon" src={calenderImage} />{" "}
              </Row>
              <Row>
                <p className="bythepeopleheader">
                  Open Scheduling Opportunities
                </p>
                <p className="bythepeoplesubheader">
                  No more reserving weeks in advance. Take flight quicker and
                  easier.
                </p>
              </Row>
            </Col>
            <Col md={{ span: 4 }}>
              <Row>
                <img className="headerIcon" src={planeImage} />{" "}
              </Row>
              <Row>
                <p className="bythepeopleheader">Free Cancellations</p>
                <p className="bythepeoplesubheader">
                  Cancel up to 24hrs before your rental for no cost.
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid={true}>
          <iframe
            width="1066"
            height="600"
            src="https://www.youtube.com/embed/DsLw0gMc9dI?&rel=0&modestbranding=1"
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            style={{
              marginBottom: "1px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "160px",
              display: "block",
            }}
            allowFullScreen
          ></iframe>
        </Container>
        <Container
          fluid={true}
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: "url(" + frontPageAirplaneImage + ")",
            height: 744,
            objectFit: "cover",
            marginTop: 55,
          }}
        >
          <div className="home-page-pic-overlay-2">
            <Container>
              <div className="home-page-aircraft-div">
                <h2 className="whiteText frontPageHeader">
                  TAKE LIFE TO GREAT HEIGHTS
                </h2>
                <div className="aircraft-div-spacer">
                  <hr className="home-page-hr" />
                </div>
                <p className="whiteText frontPageSubHeader">
                  Fulfill your dreams and have a great time or relax and earn
                  without the stress. The choice is up to you.
                </p>
                <BetaSignUpModal />
              </div>
            </Container>
          </div>
        </Container>
        {/*<Container className="about-us">*/}
        {/*<h3 className="frontPageBlueHeading blueText">ABOUT US</h3>*/}
        {/*<p className="jusitifiedSubHeader">Founded in 2017 and based in Washington DC, SkyKandy is a marketplace for the aviation community, where aircraft owners can list their aircrafts for extra money and pilots can rent aircrafts from all over either online or from a mobile phone or tablet.</p>*/}
        {/*<p className="jusitifiedSubHeader">SkyKandy is a safe way to support the aviation community and make it stronger. Whether you’re practicing maneuvers in a Cessna 172 for a day or taking your family on vacation in a piper Navajo for a weekend.</p>*/}
        {/*<p className="jusitifiedSubHeader">As we support and provide you with with a safe and fun service, SkyKandy gives provides you with a variety of options like a kid in a candy store.</p>*/}
        {/*</Container>*/}
        <Container className="about-us">
          <Row>
            <h2 className="pilotRow skykandyBlue videoTitle">
              LET YOUR AIRCRAFT PAY FOR ITSELF
            </h2>
          </Row>
          <div className="aircraftPayRow">
            <Row>
              <Col md={{ span: 4 }}>
                <h4 className="frontPageBlueHeading blueText">
                  WHY RENT YOUR AIRCRAFT
                </h4>
                <p className="jusitifiedSubHeader">
                  Let your aircraft pay for itself. Sit back and relax while
                  your aircraft makes money for you in an easy and convenient
                  way.
                </p>
              </Col>
              <Col md={{ span: 4 }}>
                <h4 className="frontPageBlueHeading blueText">
                  IT IS ALL UP TO YOU
                </h4>
                <p className="jusitifiedSubHeader">
                  You are in complete control. We will provide estimates of
                  rental prices based on market size, value, and time of the
                  year but ultimately its up to you!
                </p>
              </Col>
              <Col md={{ span: 4 }}>
                <h4 className="frontPageBlueHeading blueText">
                  WE HAVE YOUR BACK
                </h4>
                <p className="jusitifiedSubHeader">
                  Skykandy offers 24/7 support, tools, checklist, everything you
                  need to successfully make money with no problems at all.
                </p>
              </Col>
            </Row>
          </div>
          <div className="aircraftPayRow">
            <Row>
              <Col>
                <div className="frontPageBlueHeading blueText">
                  DON’T WORRY YOU’RE COVERED
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="jusitifiedSubHeader" md={{ span: 6 }}>
                No matter what kind of aircraft you have SkyKandy makes it
                simple and secure to rent your aircraft. You can even choose for
                the pilot to complete our 30 minute checkout with you before the
                rental for more assurance.
              </Col>
              <Col
                className="jusitifiedSubHeader mobileMargin"
                md={{ span: 6 }}
              >
                To keep your aircraft safe it is mandatory for pilots to have
                renters insurance and complete an annual flight review. The
                amount of aircraft damage coverage will be based off the type of
                aircraft being rented.
              </Col>
            </Row>
          </div>
        </Container>
        {/*
                <Container fluid={true}>
                    <iframe 
                    width="1066" 
                    height="600" 
                    src="https://www.youtube.com/embed/02m5hgsKuAY?&rel=0&modestbranding=1" 
                    frameBorder="0"   
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                    style={{marginBottom:"150px", marginLeft: "auto", marginRight:"auto",marginTop:"80px",display:"block"}}
                            allowFullScreen>
                    </iframe>
                </Container>
                */}
        <Container className="mainfooter" fluid={true}>
          <span className="whiteText">
            ©2019 SkyKandy. All rights reserved.{" "}
          </span>
        </Container>
      </Header>
    );
  }
}
