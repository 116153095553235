import React from "react";

class ErrorHandler extends React.Component {

    render(){
        let errorHtml = "Unable to process request - Error Code 500";
        if(this.props.error !== null) {
            let errorHtml = "Unable to process request - Error Code 500";
            console.log('API ERROR');
            console.log(this.props.error);
            console.log('API ERROR Response');
            console.log(this.props.error.response);
            if (this.props.error.response !== null && this.props.error.response !== undefined) {
                if(this.props.error.response.status === 400){
                    if(this.props.error.response.body){
                        if(this.props.error.response.body.email){
                            if(this.props.error.response.body.email.length > 0){
                                if(this.props.error.response.body.email[0] === "beta sign up with this email already exists."){
                                    errorHtml="Email Address has already been used to sign up"
                                }
                            }
                        }
                    }
                }
            }
            return (
                <p className="field-error">{errorHtml}</p>
            )
        } else {
            return null;
        }
        }
    }

export default ErrorHandler;