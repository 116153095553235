import Modal from "react-bootstrap/Modal";
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import SwaggerUtility from "./SwaggerUtility";
import { handleFailedRequest, logger } from "./Utilities";
import Form from "react-bootstrap/Form";
import FormError from "./FormError";
import validateFields from "./utils";
import ErrorHandler from "./Components/ErrorHandling/ErrorHandler";
import RequestResponse from "./Components/Alerts/RequestResult";
import loadingCircle from "./images/loadingCircle.gif";
import LoadingGif from "./Components/Alerts/LoadingGif";
import AutoCompleteInput from "./AutoCompleteInput";
import firebase from "./firebase";
class BetaSignUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      userType: "Aircraft Owner",
      firstName: "",
      lastName: "",
      email: "",
      errors: {},
      apiCallError: null,
      showSuccess: false,
      loading: false,
      locationName: null,
      locationId: null,
      place_id: null,
    };
  }

  handleClose = () => this.setShow(false);
  handleShow = () => this.setShow(true);

  onChangePilotAirport = (locationInfo) => {
    this.setState({ locationId: locationInfo.id });
    this.setState({ place_id: locationInfo.place_id });
    this.setState({ locationName: locationInfo.description });
  };

  closeRequestResponse = () => {
    this.setState({ showSuccess: false });
  };
  setShow = (boolean) => this.setState({ show: boolean });
  userSignup = () => {
    {
      /* console.log(this.state);
        let result = validateFields(this.state, "betaSignupForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if(result[0]){
            this.setState({loading: true});
            console.log('valid');
            console.log('sending data');
            console.log(this.state);
            const swaggerClient =  SwaggerUtility();
            const self = this;
            swaggerClient.then((client) => {
                client.apis
                    .betaSignup
                    .betaSignup_create(
                    {data:this.state}
                )
                    .then(function(result){
                        logger(result);
                        logger(result.ok);
                        if(result.ok){
                            self.handleClose();
                            self.setState({showSuccess:true,loading:false})
                        }
                    }).catch(function(error){logger(error);self.setState({apiCallError:error,loading:false})})
            }).catch(function(error){logger(error);self.setState({apiCallError:error,loading:false})})
        }
    */
    }

    firebase
      .firestore()
      .collection("notification-list")
      .add({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        userType: this.state.userType,
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      })
      .then(() => {
        this.handleClose();
      });
  };
  render() {
    return (
      <>
        <RequestResponse
          handleClose={this.closeRequestResponse}
          error={false}
          show={this.state.showSuccess}
          message="Success - Beta Signup Complete!"
        />
        {/*
                <button className="signupButton" onClick={this.handleShow}>SIGN UP NOW</button>
                */}
        <button className="signupButton" onClick={this.handleShow}>
          Get Notified
        </button>
        {/*
                    TODO add onclick funtion to get notified
                */}
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Beta Sign Up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LoadingGif show={this.state.loading} />
            <div style={{ marginBottom: "25px" }}></div>
            <Container>
              <ErrorHandler error={this.state.apiCallError} />
              <Form.Group>
                <Form.Label className="formLabel greyText">
                  First Name*
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstNAme"
                  value={this.state.firstName}
                  placeholder="First Name"
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />
                <FormError formField="firstName" errors={this.state.errors} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="formLabel greyText">
                  Last Name*
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  placeholder="Last Name"
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />
                <FormError formField="lastName" errors={this.state.errors} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="formLabel greyText">Email*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={this.state.email}
                  placeholder="email@address.com"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <FormError formField="email" errors={this.state.errors} />
              </Form.Group>

              <Form.Group>
                <Form.Label className="formLabel greyText">
                  Aircraft Owner or Licensed Pilot
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => this.setState({ userType: e.target.value })}
                >
                  <option>Aircraft Owner</option>
                  <option>Licensed Pilot</option>
                  <option>Both</option>
                </Form.Control>
                <FormError formField="username" errors={this.state.errors} />
              </Form.Group>
            </Container>
            <div style={{ marginBottom: "65px" }}></div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.userSignup}>
              SIGN UP
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default BetaSignUpModal;
