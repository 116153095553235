import React from "react";
import Form from "react-bootstrap/Form";
import RequestResponse from "../../Components/Alerts/RequestResult";
import Button from "react-bootstrap/Button";
import {logger} from "../../Utilities";
import SwaggerUtility from "../../SwaggerUtility";




class PlanePreferenceUpdate extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            shortest: "1hrs",
            longest: "1day",
            preferenceId: null,
            showSuccess: false,
            showError: false,
        }
    }

    
    closeRequestResponse = () => {
        this.setState({showSuccess:false})
    };

    componentDidMount() {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft
                .aircraft_getPreferences(
                    {id:this.props.planeId})
                .then(function (result) {
                    if (result.ok) {
                        console.log('FOUND PREFERENCES', result)
                        componentSelf.setPreferences(result.obj)
                    }
                }).catch(function (error) {
                logger(error)
            })
                .catch(function (error) {
                    logger(error)
                })})
    }

    setPreferences = (preferences) => {
        this.setState({
            shortest:preferences.shortest,
            longest: preferences.longest,
            preferenceId: preferences.id
        });
        console.log("new preferences");
        console.log(this.state)
    };

    updatePreferences = () => {
        console.log("PLANE PREFERENCE STATE", this.state);
        console.log(this.state);
        const componentSelf = this;
        if(true){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            if(this.state.preferenceId !== null){
                console.log("updating Preferences");
                const putData = Object.assign({},this.state);
                logger(putData);
                putData['aircraft'] = this.props.planeId;
                swaggerClient.then((client) => {
                    client.apis
                        .preferences
                        .preferences_update(
                            {id:this.state.preferenceId,data:putData})
                        .then(function (result) {
                            if (result.ok) {
                                logger("Updated price");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setPreferences(result.obj);
                                componentSelf.setState({showSuccess:true});
                                console.log('showed success');
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                        .catch(function (error) {
                            logger(error)
                        })})
            }
        }
    }

    render() {
        return (
            <div className="planeAdminPageWrapper">
                <RequestResponse handleClose={this.closeRequestResponse} error={false} show={this.state.showSuccess} message="Success - Airplane preferences updated."/>
                <h3 className="blueText plane-admin-heading">Trip Preferences</h3>
                <p>Set the shortest and longest trips that guest can book.</p>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Shortest Trip
                    </Form.Label>
                    <br />
                    <Form.Control as="select" value={this.state.shortest} onChange={(e) => this.setState({shortest: e.target.value})}>
                                    <option value={"1hrs"}>1 Hour</option>
                                    <option value={"2hrs"}>2 Hours</option>
                                    <option value={"3hrs"}> 3 Hours</option>
                                    <option value={"4hrs"}> 4 Hours</option>
                                    <option value={"5hrs"}>5 Hours</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Longest Trip
                    </Form.Label>
                    <br />
                    <Form.Control as="select" value={this.state.longest} onChange={(e) => this.setState({longest: e.target.value})}>
                                    <option value={"1day"}>1 Day</option>
                                    <option value={"2days"}>2 Days</option>
                                    <option value={"3days"}>3 Days</option>
                                    <option value={"4days"}>4 Days</option>
                                    <option value={"5days"}>5 Days</option>
                                    <option value={"any"}>Any</option>
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={this.updatePreferences}>Update Aircraft Trip Preferences</Button>
            </div>
        )
    }

}

export default PlanePreferenceUpdate;