import React from "react";

export default class RequestResponse extends React.Component {

    hideAlert = () => {
        setTimeout(() => {
            this.props.handleClose();
        },4500)
    }

    render(){
        let className = "request-alert alert-success";
        if(this.props.error){
            className = "request-alert alert-error";
        }
        if(this.props.show){
            this.hideAlert();
        return (
            <div className={className}>
                <button onClick={ (e) => this.props.handleClose} type="button" className="close-alert">×</button>
                {this.props.message}
            </div>
        )
        } else {
            return null
        }
    }
    }
