import React from "react";
import Header from "../../Header";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import FormError from "../../FormError";
import validateFields from "../../utils";
import {validateDateFields} from "../../Utilities";
import {logger, getApiDateFromDateObject, getFormattedDate} from "../../Utilities";
import SwaggerUtility from "../../SwaggerUtility";
import {setRWUnavail, setCustomPlanePrice, setShowEdit, setShowAvail, setCustomPlaneUnavail,setNewPrice} from "../../actions";
import DatePicker from "react-datepicker";
import moment from "moment";


class PlaneCalendarRightWindowComponent extends React.Component {

    componentDidMount() {
        this.updatePlaneDefaultPrice();
    }

    updatePlaneDefaultPrice = () => {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft
                .aircraft_getPrice(
                    {id:this.props.planeAdminCalendar.planeId})
                .then(function (result) {
                    if (result.ok) {
                        console.error("got default price", result);
                        componentSelf.setState({customPrice:result.obj.price})
                    }
                }).catch(function (error) {
                console.error(error)
            })
                .catch(function (error) {
                    console.error(error)
                })})
    };

    constructor(props){
        super(props);
        this.state = {
            customPrice:"125",
            startDay:null,
            endDay:null,
            startDayDatePickerDate:null,
            endDayDatePickerDate:null,
            startTime:'12:00 AM',
            endTime:'12:00 AM',
            errors:{}
        };
    }

    getTimeString = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    updatePlaneCustomPrices = (planeId) => {
        console.log("UPDATING PRICES--------------------");
        const self=this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .customPrice.customPrice_read({id:planeId})
                .then(function (result) {
                    if (result.ok) {
                        logger("Recevied Aircraft Custom Prices");
                        logger(result);
                        let customDates = {};
                        result.obj.forEach(price => {
                            customDates[price.date] = price.price;
                        });
                        logger("Setting new custom prices");
                        logger(customDates);
                        self.props.onSetPlaneCustomDates({airplane:planeId,dates:customDates});
                    }
                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    updatePlaneCustomUnavail = (planeId) => {
        logger("UPDATING Unavail-----");
        const self=this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .getCustomPlaneUnavail
                .getCustomPlaneUnavail
                ({id:planeId})
                .then(function (result) {
                    if (result.ok) {
                        logger("Recevied Aircraft Custom Unavails");
                        logger(result.obj);
                        self.props.onSetPlaneCustomUnavail({airplane:planeId,dates:result.obj});
                    }
                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    deleteUnavailibility = (unavailId, planeId) => {
        const self=this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .unavail.unavail_delete_delete({id:unavailId}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Deleting Aircraft Custom Price");
                        logger(result);
                        logger("updating unavails with Id");
                        logger(planeId);
                        self.updatePlaneCustomUnavail(planeId);
                        self.props.setRwUnavail({dateTimes:[],hasUnavailability:false});
                    }
                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    };

    getUnavailDateString = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const endings = {
            1: "st",
            2: "nd",
            3: "rd",
            4: "th",
            5: "th",
            6: "th",
            7: "th",
            8: "th",
            9: "th",
            10: "th",
            11: "th",
            12: "th",
            13: "th",
            14: "th",
            15: "th",
            16: "th",
            17: "th",
            18: "th",
            19: "th",
            20: "th",
            21: "st",
            22: "nd",
            23: "rd",
            24: "th",
            25: "th",
            26: "th",
            27: "th",
            28: "th",
            29: "th",
            30: "th",
            31: "st"
        };
        return <span>{this.getTimeString(date)} {months[date.getMonth()]} {date.getDate()}{endings[date.getDate()]}</span>;
    };

    startDayChange = (date) => {
        this.setState({startDay:getFormattedDate(date)});
        this.setState({startDayDatePickerDate:date});
    };

    endDayChange = (date) => {
        this.setState({endDay:getFormattedDate(date)});
        this.setState({endDayDatePickerDate:date});
    };

    updatePrice = () => {
        const self = this;
        console.log('update price state');
        console.log(this.state);
        let result = validateFields(this.state,"customPriceForm");
        logger("Form result");
        logger(result);
        this.setState({errors:result[1]});
        if(result[0]) {
            logger("Creating custom place price with data");
            const data = Object.assign({}, this.state);
            data['price'] = data.customPrice;
            data['date'] = getApiDateFromDateObject(this.props.planeAdminCalendar.date);
            data['aircraft'] = this.props.planeAdminCalendar.planeId;
            logger(data);
            let currentCustomPlanePrices = Object.assign({},this.props.customPlanePrices.customPrices);
            let currentAircraftPrice = {};
            if(currentCustomPlanePrices[this.props.planeAdminCalendar.planeId] !== null && currentCustomPlanePrices[this.props.planeAdminCalendar.planeId] !== undefined){
                currentAircraftPrice = currentCustomPlanePrices[this.props.planeAdminCalendar.planeId];
            }
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            swaggerClient.then((client) => {
                client.apis
                    .customPrice.customPrice_create({
                    data:data}
                )
                    .then(function (result) {
                        if (result.ok) {
                            logger("Created Aircraft Custom Price");
                            logger(result);
                            logger(result.obj);
                            currentAircraftPrice[data.date] = result.obj.price;
                            logger("SEtting new aircraft dates");
                            logger(currentAircraftPrice);
                            self.props.onSetPlaneCustomDates({airplane:self.props.planeAdminCalendar.planeId,dates:currentAircraftPrice});
                            self.props.onSetNewPrice({price: result.obj.price});
                            self.props.onSetShowEdit({
                                showEditPrice: !self.props.planeAdminCalendar.showEditPrice,
                            });
                            self.setState({
                                customPrice:result.obj.price
                            })
                        }
                    }).catch(function (error) {
                    logger(error)
                })
            }).catch(function (error) {
                logger(error)
            })
        }
    };

    updateUnavail = () => {
        const self = this;
        let result = validateFields(this.state,"dateUnavailForm");
        logger("Form result");
        logger(result);
        this.setState({errors:result[1]});
        if(result[0]) {
            logger("Creating custom place price with data");
            result = validateDateFields(this.state, ["startDay", "endDay"], {
                "start": "startDay",
                "end": "endDay",
                "startTime": "startTime",
                "endTime": "endTime"
            }, "startEndDay");
            this.setState({errors: result[1]});
            if (result[0]) {
                let data = Object.assign({}, this.state);
                const startMoment = moment(this.state.endDay + ' ' + this.state.endTime, "YYYY-MM-DD hh:mm a");
                const endMoment = moment(this.state.startDay + ' ' + this.state.startTime, "YYYY-MM-DD hh:mm a");
                data['end'] = startMoment.toISOString();
                data['start'] = endMoment.toISOString();

                data['aircraft'] = this.props.planeAdminCalendar.planeId;
                logger("sending data");
                logger(data);
                let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
                swaggerClient.then((client) => {
                    client.apis
                        .unavail.unavail_create({
                            data: data
                        }
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Aircraft Custom Unavailibility");
                                logger(result);
                                logger(result.obj);
                                self.updatePlaneCustomUnavail(self.props.planeAdminCalendar.planeId);
                                self.props.onSetShowAvail(false);
                                self.props.setRwUnavail({dateTimes:[
                                    [new Date(result.obj.start),
                                     new Date(result.obj.end),
                                     result.obj.id
                                    ]
                                    ]
                                    ,hasUnavailability:true});
                            }
                        }).catch(function (error) {
                        logger(error);
                        self.setState({errors: {"startEndDay":["Unavailability periods cannot overlap"]}});
                    })
                }).catch(function (error) {
                    logger(error)
                })
            }
        }
    };

    render() {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const endings = {
            1: "st",
            2: "nd",
            3: "rd",
            4: "th",
            5: "th",
            6: "th",
            7: "th",
            8: "th",
            9: "th",
            10: "th",
            11: "th",
            12: "th",
            13: "th",
            14: "th",
            15: "th",
            16: "th",
            17: "th",
            18: "th",
            19: "th",
            20: "th",
            21: "st",
            22: "nd",
            23: "rd",
            24: "th",
            25: "th",
            26: "th",
            27: "th",
            28: "th",
            29: "th",
            30: "th",
            31: "st"
        };
        console.log("NEWW STATE", this.state);
        console.log("RIGHT WINDOW PROPS");
        console.log(this.props);
        let html = (
            <div><h3>Calendar</h3>
                <p>Block off time when your plane is unavailable in order to get trips that work for you. View your
                    prices, and set custom prices for specific days.</p>
            </div>
        );
        let editHtml = (
            <Row>
                <Col>Set a custom price to override your standard pricing settings.</Col>
            </Row>
        );
        let placeholder = 125;
        if (this.props.planeAdminCalendar.showEditAvailability) {
            html = (
                <div>
                    <p className="calendarNavDate">{days[this.props.planeAdminCalendar.date.getDay()]}, {months[this.props.planeAdminCalendar.date.getMonth()]} {this.props.planeAdminCalendar.date.getDate()}{endings[this.props.planeAdminCalendar.date.getDate()]}</p>
                    <div className="right-plane-admin-cancel cursor-pointer" onClick={(e) => {
                        this.props.onSetShowAvail({showEditAvailability: !this.props.planeAdminCalendar.showEditAvailability})
                    }}> X </div>
                <Row>
                    <Col>
                        <h3>Add unavailability</h3>
                        <p>Block off when your plane is unavailable to be booked. Remember, you’ll get more trips by making your plane available, especially on weekends and holidays.</p>

                        <Form.Label className="formLabel greyText"> Start Date</Form.Label>
                        <Row>
                            <Col>
                                <DatePicker selected={this.state.startDayDatePickerDate} onChange={this.startDayChange} />
                            </Col>
                        </Row>
                        <Form.Label className="formLabel greyText"> Start Time</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={(e) => this.setState({startTime: e.target.value})}>
                                    <option>12:00 AM</option>
                                    <option>12:30 AM</option>
                                    <option>1:00 AM</option>
                                    <option>1:30 AM</option>
                                    <option>2:00 AM</option>
                                    <option>2:30 AM</option>
                                    <option>3:00 AM</option>
                                    <option>3:30 AM</option>
                                    <option>4:00 AM</option>
                                    <option>4:30 AM</option>
                                    <option>5:00 AM</option>
                                    <option>5:30 AM</option>
                                    <option>6:00 AM</option>
                                    <option>6:30 AM</option>
                                    <option>7:00 AM</option>
                                    <option>7:30 AM</option>
                                    <option>8:00 AM</option>
                                    <option>8:30 AM</option>
                                    <option>9:00 AM</option>
                                    <option>9:30 AM</option>
                                    <option>10:00 AM</option>
                                    <option>10:30 AM</option>
                                    <option>11:00 AM</option>
                                    <option>11:30 AM</option>
                                    <option>12:00 PM</option>
                                    <option>12:30 PM</option>
                                    <option>1:00 PM</option>
                                    <option>1:30 PM</option>
                                    <option>2:00 PM</option>
                                    <option>2:30 PM</option>
                                    <option>3:00 PM</option>
                                    <option>3:30 PM</option>
                                    <option>4:00 PM</option>
                                    <option>4:30 PM</option>
                                    <option>5:00 PM</option>
                                    <option>5:30 PM</option>
                                    <option>6:00 PM</option>
                                    <option>6:30 PM</option>
                                    <option>7:00 PM</option>
                                    <option>7:30 PM</option>
                                    <option>8:00 PM</option>
                                    <option>8:30 PM</option>
                                    <option>9:00 PM</option>
                                    <option>9:30 PM</option>
                                    <option>10:00 PM</option>
                                    <option>10:30 PM</option>
                                    <option>11:00 PM</option>
                                    <option>11:30 PM</option>
                                </Form.Control>
                             </Col>
                        </Row>
                        <FormError formField="startDay" errors={this.state.errors}/>
                        <hr></hr>
                        <Form.Label className="formLabel greyText"> End Date</Form.Label>
                        <Row>
                            <Col>
                                <DatePicker selected={this.state.endDayDatePickerDate} onChange={this.endDayChange} />
                            </Col>
                            </Row>
                        <Form.Label className="formLabel greyText"> End Time</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={(e) => this.setState({endTime: e.target.value})}>
                                    <option>12:00 AM</option>
                                    <option>12:30 AM</option>
                                    <option>1:00 AM</option>
                                    <option>1:30 AM</option>
                                    <option>2:00 AM</option>
                                    <option>2:30 AM</option>
                                    <option>3:00 AM</option>
                                    <option>3:30 AM</option>
                                    <option>4:00 AM</option>
                                    <option>4:30 AM</option>
                                    <option>5:00 AM</option>
                                    <option>5:30 AM</option>
                                    <option>6:00 AM</option>
                                    <option>6:30 AM</option>
                                    <option>7:00 AM</option>
                                    <option>7:30 AM</option>
                                    <option>8:00 AM</option>
                                    <option>8:30 AM</option>
                                    <option>9:00 AM</option>
                                    <option>9:30 AM</option>
                                    <option>10:00 AM</option>
                                    <option>10:30 AM</option>
                                    <option>11:00 AM</option>
                                    <option>11:30 AM</option>
                                    <option>12:00 PM</option>
                                    <option>12:30 PM</option>
                                    <option>1:00 PM</option>
                                    <option>1:30 PM</option>
                                    <option>2:00 PM</option>
                                    <option>2:30 PM</option>
                                    <option>3:00 PM</option>
                                    <option>3:30 PM</option>
                                    <option>4:00 PM</option>
                                    <option>4:30 PM</option>
                                    <option>5:00 PM</option>
                                    <option>5:30 PM</option>
                                    <option>6:00 PM</option>
                                    <option>6:30 PM</option>
                                    <option>7:00 PM</option>
                                    <option>7:30 PM</option>
                                    <option>8:00 PM</option>
                                    <option>8:30 PM</option>
                                    <option>9:00 PM</option>
                                    <option>9:30 PM</option>
                                    <option>10:00 PM</option>
                                    <option>10:30 PM</option>
                                    <option>11:00 PM</option>
                                    <option>11:30 PM</option>
                                </Form.Control>
                            </Col>
                        </Row>
                            <FormError formField="endDay" errors={this.state.errors}/>
                            <FormError formField="startEndDay" errors={this.state.errors}/>
                            <Button className="top-padded-button" onClick={(e) => this.updateUnavail()}>Set Unavailability</Button>
                    </Col>
                </Row>
                </div>
            )
        } else if (this.props.planeAdminCalendar.showEditPrice) {
                html = (
                    <Row>
                        <Col>
                            <div className="right-plane-admin-cancel cursor-pointer" onClick={(e) => {
                                this.props.onSetShowEdit({showEditPrice: !this.props.planeAdminCalendar.showEditPrice})
                            }}> X </div>
                            <Form.Group>
                                <Form.Label className="formLabel greyText"> Custom Price ($)</Form.Label>
                                <Form.Control value={this.state.customPrice} type="text" name="customPrice"
                                              onChange={(e) => this.setState({customPrice: e.target.value})}/>
                                <FormError formField="customPrice" errors={this.state.errors}/>
                            </Form.Group>
                            <Button onClick={(e) => this.updatePrice()}>Update Price </Button>
                        </Col>
                    </Row>
                )
        } else if (this.props.planeAdminCalendar.hasData) {
            let unavailHtml = null;
            if(this.props.planeAdminCalendar.hasUnavailability){
                const startTime = this.props.planeAdminCalendar.unavailabilityDateTimes[0][0];
                const endTime = this.props.planeAdminCalendar.unavailabilityDateTimes[0][1];
                unavailHtml = (
                    <div>
                        <hr></hr>
                        <p> From: {this.getUnavailDateString(startTime)} </p>
                        <p> To: {this.getUnavailDateString(endTime)} </p>
                        <p className="add-unavailable-link" onClick={(e) => {this.deleteUnavailibility(this.props.planeAdminCalendar.unavailabilityDateTimes[0][2],this.props.planeAdminCalendar.planeId)}}>Remove</p>
                        <hr></hr>
                    </div>
                )
            }
            let pricehtml;
            pricehtml = <p className="calendarNavPrice">${this.props.planeAdminCalendar.price}</p>;
            let showAddUnavailHtml;
            if(!this.props.planeAdminCalendar.hasUnavailability){
                showAddUnavailHtml = (
                    <p className='add-unavailable-link' onClick={(e) => this.props.onSetShowAvail({showEditAvailability: !this.props.planeAdminCalendar.showEditAvailability})}>+
                        Add an unavailable period
                    </p>
                )
            } else {
                showAddUnavailHtml = null;
            }

            html =
                <div>
                    <p className="calendarNavDate">{days[this.props.planeAdminCalendar.date.getDay()]}, {months[this.props.planeAdminCalendar.date.getMonth()]} {this.props.planeAdminCalendar.date.getDate()}{endings[this.props.planeAdminCalendar.date.getDate()]}</p>
                    <Row>
                        <Col>
                            {pricehtml}
                        </Col>
                        <Col>
                            <p className='add-unavailable-link' onClick={(e) => {
                                this.props.onSetShowEdit({showEditPrice: !this.props.planeAdminCalendar.showEditPrice})
                            }}>Change Price</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <p className="unavail-header">Unavailability</p>
                            {unavailHtml}
                        <p>Set your plane’s unavailability to block bookings during these periods.</p>
                            {showAddUnavailHtml}
                        </Col>
                    </Row>
                    {editHtml}
                </div>
            }
        return (
         <div className='planeCalendarRightWindow'>
             {html}
         </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        planeAdminCalendar: state.planeAdminCalendar,
        customPlanePrices: state.customPlanePrices
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSetPlaneCustomDates: customDates => {
            dispatch(setCustomPlanePrice(customDates))
        },
        onSetShowEdit: action => {
            dispatch(setShowEdit(action))
        },
        onSetNewPrice: action => {
            dispatch(setNewPrice(action))
        },
        onSetShowAvail: action => {
            dispatch(setShowAvail(action))
        },
        onSetPlaneCustomUnavail: customDates => {
            dispatch(setCustomPlaneUnavail(customDates))
        },
        setRwUnavail: action => {
            dispatch(setRWUnavail(action))
        }
    }
};

const PlaneCalendarRightWindow = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaneCalendarRightWindowComponent);
export default PlaneCalendarRightWindow;