import Autosuggest from 'react-autosuggest';
import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    setAircraftLocation,
    setPilotProfileLocation
} from "./actions.tsx";
import {createUuidv4} from "./utils";
import SwaggerUtility from "./SwaggerUtility";
import { logger } from './Utilities';
// Imagine you have a list of languages that you'd like to autosuggest.


// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.description}
    </div>
);

class AutoCompleteInputComp extends React.Component {
    constructor() {
        super();
        const sessionUUID = createUuidv4();
        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: [],
            UUID4: sessionUUID,
            initialized:false
        };
    }

    componentDidMount() {
        if(!this.state.initialized){
            if(this.props.initValue !== null && this.props.initValue !== undefined){
                this.setState({value:this.props.initValue,initialized:true});
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.initValue !== undefined){
            if(this.props !== undefined && prevProps.props !== undefined){
                if(this.props.initValue !== prevProps.props.initValue) {
                    this.setState({value:this.props.initValue})
                }
            }
        }
    }

    getSuggestionValue = (suggestion) => {
        logger("Updating Suggestion");
        logger(suggestion);
        if(this.props.parentElement === "profile"){
            this.props.onChange(suggestion);
        }
        else if(this.props.parentElement === "aircraft"){
            logger("setting aircraft location");
            this.props.onChange(suggestion);
        }
        return suggestion.description;
    };

    getGooglePlaces = (queryString) => {
        var userToken = localStorage.getItem('token');
        let compSelf = this;
        if(userToken !== undefined){
            let swaggerClient = SwaggerUtility(userToken);
            swaggerClient.then((client) => {
                client.apis
                    .locationsSearch.locationsSearch_create(
                    {data:{"name":queryString,"UUID":compSelf.state.UUID4}}
                )
                    .then(function(result){
                        if(result.ok){
                            logger(result);
                            compSelf.setState({ suggestions:result.obj.locations})
                        }
                    }).catch(function(error){logger(error)})
            }).catch(function(error){logger(error)})
        }
    };

    onChange = (event, { newValue }) => {
        this.getGooglePlaces(newValue);
        this.setState({
            value: newValue
        });

    };

    getSuggestions = value => {
        logger(value);
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.suggestions;
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const value = this.state.value;
        // Autosuggest will pass through all these props to the input.

        const inputProps = {
            placeholder: 'Type an airport',
            value:this.state.value,
            onChange: this.onChange
        };

        if(this.props.initValue !== null){
            inputProps.value=this.props.initValue;
        }

        return (
            <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

const mapStateToProps = state => {
    logger("state update!");
    logger(state);
    return {
        aircraftInformation: state.aircraftInfo,
        profileInformation:state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeLocation: locationInfo => {
            dispatch(setAircraftLocation(locationInfo))
        },
        onChangeHomeAirportLocation: locationInfo => {
            dispatch(setPilotProfileLocation(locationInfo))
        }
    }
};

const AutoCompleteInput = connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoCompleteInputComp);

export default AutoCompleteInput;