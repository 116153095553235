import React from "react";


export default class SiteAdmin extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            rendered:false,
            isAdmin:null,
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.profile.id !== prevProps.profile.id) {
            if(!this.state.props.profile){
                this.initPage();
            }
        }
    }

    render(){
        if(this.state.rendered){
            return <div>Hi</div>
        }
        else {
            return null
        }
    }
}