import Swagger from "swagger-client";
import spec from "./ApiSwaggerSpec";
import {configs} from "./configurations";

spec.host = configs.serverUrl;
spec.schemes = [configs.serverProtocol];
const SwaggerClient = (userToken) => {
    if(userToken !== undefined){
        return Swagger({ spec: spec,headers:{"Accept": "application/json"},requestInterceptor(req) {
                req.headers['Authorization'] = `Token ${userToken}`;
                return req;
            }})
    }
    else{
        return Swagger({ spec: spec,headers:{"Accept": "application/json"}})
    }
};

export default SwaggerClient;