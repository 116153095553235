import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.css';
import { connect } from 'react-redux';
import Header from './Header';
import FormError from './FormError'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
    setUsername, setToken, setPassword, setAuthenticated
} from './actions.tsx'
import SwaggerClient from "./SwaggerUtility";
import validateFields from "./utils";
import {withRouter} from "react-router";
import { logger } from './Utilities';

class LoginContainer extends React.Component{

    constructor(props){
        super(props);
        this.state =
            {errors:{}};
        this.componentSelf = this;
        logger("History props");
        logger(this.props);
        logger(this.props.history);
    }

    login = () => {
        let result = validateFields(this.props.profile,"logInForm");
        logger("form is");
        logger(result[0]);
        this.setState({errors:result[1]});
        if(result[0]){
            this.sendLogin(this.componentSelf);
        }

    };

    sendLogin = (componentSelf) => {
        logger(this.props);
        const compSelf = this;
        const loginInfo = {
            username:this.props.profile.username,
            password:this.props.profile.password
        };
        const swaggerclient = SwaggerClient();
        swaggerclient.then((client) => {
            client.apis
                .login.login_create(
                {data:loginInfo}
            )
                .then(function(result){
                    logger(result);
                    logger(result.ok);
                    if(result.ok){
                        const createdUser = result.obj;
                        logger(createdUser);
                        const token = createdUser.token;
                        logger(token);
                        localStorage.setItem('token', token);
                        componentSelf.props.onSetToken(token);
                        componentSelf.props.onSetAuthenticated(true);
                        componentSelf.props.onChangeUsername(null);
                        componentSelf.props.onChangePassword(null);
                        componentSelf.props.history.push("/profile");
                    }
                    else{
                        this.setState({
                            errors:{
                                "formResult":["Incorrect Username or Password"]
                            }
                        })
                    }
                }).catch(function(error){logger(error);compSelf.setState({
                errors:{
                    "formResult":["Incorrect Username or Password"]
                }
            })})
        }).catch(function(error){logger(error);compSelf.setState({
            errors:{
                "formResult":["Incorrect Username or Password"]
            }
        })})
    };

    render() {
        return (
            <Header>
                <Container>
                    <Row>
                        <Col sm={{span: 6, offset:3}}>
                <h1 className="text-center blueText">Welcome!</h1>
                    <Form.Group>
                        <FormError formField="formResult" errors = {this.state.errors} />
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control type="text" name="username" placeholder="email@email.com" onChange={(e) => this.props.onChangeUsername(e.target.value)} />
                        <FormError formField="username" errors = {this.state.errors} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Password
                        </Form.Label>
                        <Form.Control type="password" name="password" onChange={(e) => this.props.onChangePassword(e.target.value)}  />
                        <FormError formField="password" errors = {this.state.errors} />
                    </Form.Group>
                <Button className="greenButton" onClick={this.login}>LOGIN</Button>
                        </Col>
                    </Row>
                </Container>
            </Header>
        )
    }

}

const mapStateToProps = state => {
    logger("state update!");
    logger(state);
    return {
      profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
      onChangeUsername: text => {  
        dispatch(setUsername(text))
      },
      onChangePassword: text => {
        dispatch(setPassword(text))
      },
      onSetAuthenticated: boolean => {
          dispatch(setAuthenticated(boolean))
      },
      onSetToken: token => {
          dispatch(setToken(token))
      },

  }
};

const Login = connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginContainer)

export default withRouter(Login);