import React from "react";
import imageCompression from 'browser-image-compression';
export default class UploadedPhotos extends React.Component {

    static defaultProps = {
        height: "300px",
        width: "300px",
        alt: "Uploaded file",
        borderRadius: 0,
        border:null,
        fileType:"upload",
        rotation:"rotate(0)"
    };

    constructor(props){
        super(props);
        this.state = {
            rotation:"rotate(0)"
        }
    }

    render(){
        let imageSrc;
        if(this.props.fileType === "upload"){
            if(this.props.photo !== null){
                console.log("GETTING PIC FROMMMMMMMMMMMM");
                console.log(this.props.photo);
                imageSrc = window.URL.createObjectURL(this.props.photo[0]);
            }
        }
        else if(this.props.fileType === "url"){
          imageSrc = this.props.photo;
        }
        return this.props.photo === null ? null : <img alt="Drivers License" style={{border:this.props.border,marginBottom:"10px",marginTop:"30px",borderRadius:this.props.borderRadius,transform:this.state.rotation}} width={this.props.width} height={this.props.height} src={imageSrc}></img>;
    }
}