import { logger } from './Utilities';

const formIdFieldMap = {
    signUpForm:{
        "username":"emailRequired",
        "firstName":"textRequired",
        "lastName":"textRequired",
        "mobileNumber":"phoneNumberRequired",
        "password":"password",
        "confirmPassword":"confirmPassword",
        "address":"addressRequired",
        "addressTwo":"addressNotRequired",
        "zipcode":"zipCodeRequired",
        "city":"cityRequired",
        "birthday":"textRequired"
    },
    logInForm:{
        "username":"textRequired",
        "password":"textRequired"
    },
    aircraftInfoForm:{
        "year":"numberRequired",
        "avionics":"textRequired",
        "make":"textRequired",
        "model":"textRequired",
        "totalTime":"numberRequired",
        "aircraftLocation":"textRequired",
        "steamGauges":"textRequired",
        "description":"textRequired",
        "performanceSpecifications":"textRequired",
        "interior":"imageListRequired",
        "exterior":"imageListRequired",
        "arrow":"imageListRequired",
        "av1aes":"imageListRequired",
        "license":"imageListRequired",
        "medical":"imageListRequired",
        "location":"textRequired",
        "eltPicture":"imageRequired",
        "oneHundredHourInspectionImage":"imageRequired",
        "altimeterImage":"imageRequired",
        "vorInspectionImage":"imageRequired",
        "staticInspectionImage":"imageRequired",
        "transponderImage":"imageRequired",
        "eltDate":"dateRequired",
        "oneHundredHourInspectionDate":"dateRequired",
        "vorInspectionDate":"dateRequired",
        "staticInspectionDate":"dateRequired",
        "transponderDate":"dateRequired",
        "altimeterDate":"dateRequired",
        "locationName":"textRequired"
    },
    pilotLicensePhotoForm:{
        "pilotsLicenseFront":"imageRequired",
        "pilotsLicenseBack":"imageRequired",
    },
    driverLicensePhotoForm:{
        "driversLicenseFront":"imageRequired",
        "driversLicenseBack":"imageRequired",
    },
    pilotInsuranceForm:{
        "insuranceNumber":"textRequired",
        "insurer":"textRequired",
        "hasInsurance":"booleanRequired",
        "issueDate":"dateRequired",
        "expirationDate":"dateRequired"
    },
    profilePictureForm:{
        "picture":"imageRequired",
    },
    certificationsForm:{
        "totalTime":"decimalRequired",
        "totalPilotInCommandTime":"decimalRequired",
    },
    medicalClassForm:{
        "medicalIssueDate":"dateRequired",
        "medicalExpirationDate":"dateRequired",
        "medicalCertificationNumber":"textRequired",
    },
    homeAirportForm:{
        "locationName":"textRequired"
    },
    customPriceForm:{
        "customPrice":"numberRequired",
    },
    dateUnavailForm:{
        "startDay":"dateRequired",
        "endDay":"dateRequired",
    },
    aircraftLocationForm:{
        "editLocationName":"textRequired"
    },
    aircraftPhotoForm:{
        "newPhoto":"imageRequired"
    },
    guestInstructionsForm:{
        "instructions":"textRequired",
        "guide":"textRequired"
    },
    planeDescriptionForm:{
        "description":"textRequired"
    },
    betaSignupForm:{
        "firstName":"textRequired",
        "lastName":"textRequired",
        "email":"textRequired"
    },
    planeGuidelinesForm:{
        "rules":"textRequired"
    },
    planePriceForm:{
        "price":"decimalRequired"
    }, 
    aircraftPricing: {
        "defaultPrice":"decimalRequired",
    },
    aircraftExtras:{
        "cleaningFee":"decimalRequired",
    }
};

function testZipcode(inputtxt){
    var phoneno = /^\d{5}$/;
    return inputtxt.match(phoneno);
}

function testPhoneNumber(inputtxt){
  var phoneno = /^\d{10}$/;
  return inputtxt.match(phoneno)
}

function testIsNumber(str){
    if(typeof str === 'number'){
        return true;
    } else {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;

    }
}


function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return true;
  }
    return false;
}

function validateDecimal(val){
    logger("val received");
    logger(val);
    const valid = (val.match(/^\d+(?:\.\d+)?$/));
    return valid
}

let formValidator = function(type,value,allValues){
    let errors = [];
    let valid = true;
    if(type === "emailRequired"){
        if(value === null){
            errors.push("Field is required");
        }
        else if(value.trim().length <= 0){
            errors.push("Field is required");
        }
        else if(!ValidateEmail(value)){
            errors.push("Valid email address is required")
        }
    }
    else if(type === "textRequired"){
        if(value === null){
            errors.push("Field is required");
        }
        else if(value.trim().length <= 0){
            errors.push("Field is required");
        }
    }
    else if(type === "dateRequired"){
        if(value === null){
            errors.push("Field is required");
        }
    }
    else if(type === "booleanRequired"){
        if(value === null){
            errors.push("Field is required");
        }
        else if(value !== false && value !== true){
            errors.push("True or False required");
        }
    }
    else if(type === "decimalRequired"){
        if(value === null){
            errors.push("Field is required");
        }
        else if(!validateDecimal(value.toString())){
            errors.push("Valid positive decimal value required");
        }
    }
    else if(type === "phoneNumberRequired"){
        if(value === null){
            errors.push("Field is required")
        }
        else if(!testPhoneNumber(value)){
            errors.push("Valid 10 digit phone number Required")
        }
    }
    else if(type === "numberRequired"){
        if(value === null){
            errors.push("Field is required")
        }
        else if(!testIsNumber(value)){
            errors.push("Valid number required")
        }
    }
    else if(type === "password"){
        if(value === null){
            errors.push("Field is required")
        }
        else if(value.length < 8){
            errors.push("Password must be at least 8 characters long")
        }
    }
    else if(type === "confirmPassword"){
        logger("confirm value "+value);
        logger("value " +allValues["password"]);
        if(allValues["password"] !== value){
            errors.push("Passwords do not match")
        }
    }
    else if(type === "addressRequired"){
        if(value === null){
            errors.push("Field is required")
        }
    }
    else if(type === "cityRequired"){
        if(value === null){
            errors.push("City is required")
        }
    }
    else if(type === "imageRequired"){
        if(value === null){
            errors.push("Field is required")
        }
        else if(value.length === 0){
            errors.push("Field is required")
        }
        else{
            try {
                let picimagefile = value;
                logger("picimagefile");
                logger(picimagefile);
                console.log("picimagefile name"+ picimagefile.name);
                console.log("picimagefile file"+ picimagefile.file);
                let fileEndingList;
                if(picimagefile.name !== undefined){
                    fileEndingList = picimagefile.name.split(".");
                } else if(picimagefile.file !== undefined){
                    fileEndingList = picimagefile.file.name.split(".");
                }

                let fileEnding = fileEndingList[fileEndingList.length - 1];
                let lowerFileEnding = fileEnding.toLowerCase();
                if (lowerFileEnding !== "jpg" && lowerFileEnding !== "png" && lowerFileEnding !== "jpeg") {
                    errors.push("Images must be png, jpg, or jpeg format");
                }
            }
            catch (e) {
                console.error('Invalid image was uploaded',e);
                errors.push('Must upload valid image');
            }
        }
    }
    else if(type === "imageListRequired"){
        if(value === null){
            errors.push("Field is required")
        }
        else if(value.length === 0){
            errors.push("Field is required")
        }
        else{
            valid = true;
            value.forEach(image => {
                try {
                    let picimagefile = image;
                    logger("picimagefile");
                    logger(picimagefile);
                    let fileEndingList;
                    if(picimagefile.hasOwnProperty('name')){
                        logger("Pic has property name" + picimagefile.name);
                        fileEndingList = picimagefile.name.split(".");
                    } else if(picimagefile.hasOwnProperty('file')){
                        logger("Pic has property file" + picimagefile.file.name);
                        fileEndingList = picimagefile.file.name.split(".");
                    }
                    let fileEnding = fileEndingList[fileEndingList.length - 1];
                    let lowerFileEnding = fileEnding.toLowerCase();
                    if (lowerFileEnding !== "jpg" && lowerFileEnding !== "png" && lowerFileEnding !== "jpeg") {
                        if(valid){
                            errors.push("Images must be png, jpg, or jpeg format");
                            valid = false
                        }
                    }
                }
                catch (e) {
                    console.error('Invalid image was uploaded',e);
                    if(valid){
                        errors.push('Must upload valid image');
                        valid = false
                    }
                }
            })
        }
    }
    else if(type === "addressNotRequired"){
    }
    else if(type === "zipCodeRequired"){
        if(value === null){
            errors.push("Zipcode is required")
        }
        else if(!testZipcode(value)){
            errors.push("Valid 5 digit zipcode required")
        }
    }
    if(errors.length === 0){
        valid = true;
    }
    else{
        valid = false;
    }
    return [errors,valid]
};

let validateFields = function(values,formId,skippedFields = {}){
    const errors = {};
    let valid = true;
    logger("values received",values);
    let keys = Object.keys(values);
    logger("Validating");
    logger(keys);
    logger("skippedFields");
    logger(skippedFields);
    for(let i = 0;i<keys.length;i++){
        logger(keys[i]);
        logger("is skipped");
        logger(skippedFields.hasOwnProperty(keys[i]));
        if(formIdFieldMap[formId][keys[i]] !== undefined && skippedFields.hasOwnProperty(keys[i]) !== true){
            let result = formValidator(formIdFieldMap[formId][keys[i]],values[keys[i]],values);
            logger(values[keys[i]]);
            logger(result);
            errors[keys[i]] = result[0];
            let fieldsValid = result[1];
            if(!fieldsValid){
                valid = false;
            }
        }
    }
    logger("Form errors");
    logger(errors);
    return [valid,errors]
};

export const createUuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
};

export default validateFields;