import React from 'react';

class FormError extends React.Component{
    
    render() {
        let items = null;
        const formField = this.props.formField;
        const errors = this.props.errors
        const validationErrors = errors[formField];
        if (errors.hasOwnProperty(formField)){
            if(validationErrors.length > 0){
                items = [];
                for(let i = 0;i<validationErrors.length;i++){
                    items.push(<div className="field-error" key={formField+i}>{validationErrors[i]}</div>)
                    i++
                }
            }
        }
        return (
            items
        )
    }
}

export default FormError;