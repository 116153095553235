import React from "react";
import {Link} from 'react-router-dom';
class Planes extends React.Component{

    render(){
        return(
            <div>
                <p>{this.props.planeObject.model} {this.props.planeObject.year}</p>
                <Link to={'planeAdmin/calendar/'+String(this.props.planeObject.id)}><p>Edit Listing</p></Link>
            </div>
        )
    }
}

export default Planes;