import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormError from "../../FormError";
import Button from "react-bootstrap/Button";
import validateFields from "../../utils";
import {logger} from "../../Utilities";
import SwaggerUtility from "../../SwaggerUtility";




class GuestInstructionsUpdate extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            errors:{},
            description: "",
            descriptionId:null,
        }
    }

    componentDidMount() {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft
                .aircraft_getDescription(
                    {id:this.props.planeId})
                .then(function (result) {
                    if (result.ok) {
                        componentSelf.setDescription(result.obj)
                    }
                }).catch(function (error) {
                logger(error)
            })
                .catch(function (error) {
                    logger(error)
                })})
    }

    setDescription = (instructs) => {
        this.setState({
            description:instructs.description,
            descriptionId: instructs.id,
        });
        console.log("new description");
        console.log(this.state)
    };

    updateDescription = () => {
        console.log(this.state);
        let result = validateFields(this.state, "planeDescriptionForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if(result[0]){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            let description = {
                description: this.state.description,
                aircraft: this.props.planeId,
            };
            if(this.state.descriptionId === null){
                logger("Creating Description");
                logger(description);
                swaggerClient.then((client) => {
                    client.apis
                        .description
                        .description_create(
                            {data:description})
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Description");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setDescription(result.obj)
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                        .catch(function (error) {
                            logger(error)
                        })})
            } else {
                logger("updating Guest instructions");
                logger(description);
                swaggerClient.then((client) => {
                    client.apis
                        .description
                        .description_update(
                            {id:this.state.descriptionId,data:description})
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Description");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setDescription(result.obj)
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                        .catch(function (error) {
                            logger(error)
                        })})
            }
        }
    }

    render() {
        return (
            <div className="planeAdminPageWrapper">
                <h3 className="blueText plane-admin-heading"> Aircraft Description</h3>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Description
                    </Form.Label>
                    <div>Write a detailed description of your aircraft. Information about your aircraft, how beautiful it is, what the aircraft means to you, and why you are renting it.</div>
                    <Form.Control as="textarea" rows="3" value={this.state.description}  type="text" onChange={(e) => this.setState({description:e.target.value})}>
                    </Form.Control>
                    <FormError formField="description" errors = {this.state.errors} />
                </Form.Group>
                <Button variant="primary" onClick={this.updateDescription}>Update Aircraft Description</Button>
            </div>
        )
    }

}

export default GuestInstructionsUpdate;