import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormError from "../../FormError";
import Button from "react-bootstrap/Button";
import validateFields from "../../utils";
import {logger} from "../../Utilities";
import SwaggerUtility from "../../SwaggerUtility";




class GuestInstructionsUpdate extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            errors:{},
            instructions: "",
            guide: "",
            guestInstructionsId:null,
        }
    }

    componentDidMount() {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft
                .aircraft_getInstructions(
                    {id:this.props.planeId})
                .then(function (result) {
                    if (result.ok) {
                        componentSelf.setGuestInstructions(result.obj)
                    }
                }).catch(function (error) {
                logger(error)
            })
                .catch(function (error) {
                    logger(error)
                })})
    }

    setGuestInstructions = (instructs) => {
        this.setState({
            guide:instructs.guide,
            instructions:instructs.instructions,
            guestInstructionsId: instructs.id,
        });
        console.log("udpated instructs")
        console.log(this.state)
    };

    updateInstructions = () => {
        console.log(this.state);
        let result = validateFields(this.state, "guestInstructionsForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if(result[0]){
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            let guestInstructions = {
                instructions: this.state.instructions,
                guide: this.state.guide,
                aircraft: this.props.planeId,
            };
            if(this.state.guestInstructionsId === null){
                logger("Creating Guest instructions");
                logger(guestInstructions);
                swaggerClient.then((client) => {
                    client.apis
                        .guestInstructions
                        .guestInstructions_create(
                            {data:guestInstructions})
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created GuestInstructions");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setGuestInstructions(result.obj)
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                        .catch(function (error) {
                            logger(error)
                        })})
            } else {
                logger("updating Guest instructions");
                logger(guestInstructions);
                swaggerClient.then((client) => {
                    client.apis
                        .guestInstructions
                        .guestInstructions_update(
                            {id:this.state.guestInstructionsId,data:guestInstructions})
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created GuestInstructions");
                                logger(result);
                                logger(result.obj);
                                componentSelf.setGuestInstructions(result.obj)
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                        .catch(function (error) {
                            logger(error)
                        })})
            }
        }
    }

    render() {
        return (
            <div className="planeAdminPageWrapper">
                <h3 className="blueText plane-admin-heading"> Guest Instructions</h3>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Pick Up and Return Instructions
                    </Form.Label>
                    <div>Share any special instructions you would like the pilot to know about pickups and returns,
                        like where to park the aircraft</div>
                    <Form.Control as="textarea" rows="3" value={this.state.instructions}  type="text" onChange={(e) => this.setState({instructions:e.target.value})}>
                    </Form.Control>
                    <FormError formField="instructions" errors = {this.state.errors} />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Aircraft Guide
                    </Form.Label>
                    <div>Share any tips about operating your aircraft like how many shots of prime you should give it.</div>
                    <Form.Control as="textarea" value={this.state.guide} rows="3"  type="text" onChange={(e) => this.setState({guide:e.target.value})}>
                    </Form.Control>
                    <FormError formField="guide" errors = {this.state.errors} />
                </Form.Group>
                <Button variant="primary" onClick={this.updateInstructions}>Update Guest Instructions</Button>
            </div>
        )
    }

}

export default GuestInstructionsUpdate;