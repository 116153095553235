import React from "react";
import Header from './Header';
import BootstrapModal from './BootstrapModal';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import connect from "react-redux/es/connect/connect";
import {Link} from 'react-router-dom';
import { logger } from './Utilities';
import Button from 'react-bootstrap/Button';

class ProfileComponent extends React.Component{

    render(){
        logger("comp history props");
        let idVerificationContent;
        const IdVerificationField = this.props.profile.idVerificationStatus;
        if(IdVerificationField === "Need To Upload"){
            idVerificationContent = <Link className="blueText linkText" to="/verifyId">Verify ID</Link>
        }
        else if(IdVerificationField === "In Review"){
            idVerificationContent = <Link className="blueText linkText" to="/verifyId">ID in Review. Click to re-upload.</Link>
        }
        else if(IdVerificationField === "Verified"){
            idVerificationContent = <div> ID is verified </div>
        }
        const emailField = (this.props.profile.emailVerified) ?
            this.props.profile.username : <BootstrapModal userId={this.props.profile.id} modalTitle="Verify Email Address" modalText="An email verification link will be sent to your email address. Check your email and follow the link to verify your email address." toggleText="Verify Email Address"/>;
        return(
            <Header>
                <Container>
                <Row>
                    <Col>
                        <Row>
                            <Col className="profileName"><h2>{this.props.profile.firstName}</h2></Col>
                        </Row>
                        <Row className="profilePaddedRow">
                            <Col>
                                Verified Info
                            </Col>
                        </Row>
                        <Row className="profilePaddedRow">
                            <Col>
                                Approved To fly
                            </Col>
                            <Col>
                                {idVerificationContent}
                            </Col>
                        </Row>
                        <Row className="profilePaddedRow">
                            <Col>
                                Email Address
                            </Col>
                            <Col>
                                {emailField}
                            </Col>
                        </Row>
                        <Row className="profilePaddedRow">
                            <Col>
                                Phone Number
                            </Col>
                            <Col>
                                Verify Phone Number
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link className="blueText linkText" to="/changeProfilePicture">Upload Profile Picture</Link>
                            </Col>
                        </Row>
                        <Row className="profilePaddedRow">
                            <Col>
                                <Link to="/pilotSignup"><Button size="sm">Complete Pilot Signup</Button></Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        Reviews From Hosts
                    </Col>

                </Row>
                </Container>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        profilePicture: state.profile,
    }
};

const Profile= connect(
    mapStateToProps
)(ProfileComponent);
export default Profile;