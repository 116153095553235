import React from "react";
import Header from './../../Header';
import HostPlaneItem from './HostPlaneListItem';
import Container from 'react-bootstrap/Container';
import SwaggerUtility from "../../SwaggerUtility";
import {logger} from "../../Utilities";
import {connect} from "react-redux";

class PlanesComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            planes:[]
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profile.id !== this.props.profile.id){
            var userToken = localStorage.getItem('token');
            let compSelf = this;
            if(userToken !== undefined){
                let swaggerClient = SwaggerUtility(userToken);
                console.log("sending",{id:this.props.profile.id});
                swaggerClient.then((client) => {
                    client.apis
                        .aircraft.aircraft_getOwnerAircrafts(
                        {id:this.props.profile.id}
                    )
                        .then(function(result){
                            if(result.ok){
                                logger('Received owner aircrafts');
                                logger(result.obj);
                                compSelf.setState({planes:result.obj});
                            }
                        }).catch(function(error){logger(error);localStorage.removeItem('token')})
                }).catch(function(error){logger(error);localStorage.removeItem('token')})
            }
        }
    }

    render(){
        return(
            <Header>
            <Container>
                <h2>Planes</h2>
                {this.state.planes.map(plane => <HostPlaneItem key={plane.id} planeObject={plane}/>)}
            </Container>
            </Header>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile
    }
};

const Planes = connect(
    mapStateToProps,
    null
)(PlanesComponent);
export default Planes;
