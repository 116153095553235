import Col from "react-bootstrap/Col";
import React from "react";
import {setCalenderDay} from "../../actions";
import {connect} from "react-redux";

class PlaneCalenderDayComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showEditPrice:false
        }
    }

    render() {
        const planeDayPrice = this.props.planeDayPrice;
        var html;
        if(planeDayPrice === null || planeDayPrice === undefined || !planeDayPrice.validDay){
            html = <Col className='calendarDay invalid-plane-calendar-date'>invalid</Col>;
        } else {
            let className = 'calendarDay';
            if(this.props.planeDayPrice.hasUnavailability){
                className = 'calendarDay calendar-day-with-unavailability'
            }
            if(this.props.planeAdminCalendar){
                if(this.props.planeAdminCalendar.date !== null){
                    if(this.props.planeDayPrice.date.getTime() == this.props.planeAdminCalendar.date.getTime()){
                        className = 'calendarDay selected-calendar-day'
                    }
                }
            }
            html = <Col onClick={ (e) => this.props.onSetCalendarState(this.props.planeDayPrice)} className={className}><strong>{planeDayPrice.date.getDate()}</strong><br/>${planeDayPrice.price}</Col>;
        }
        return (
            html
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onSetCalendarState: calendarState => {
            dispatch(setCalenderDay(calendarState))
        }
    }
};

const mapStateToProps = state => {
    return {
        planeAdminCalendar: state.planeAdminCalendar
    }
};


const PlaneCalenderDay = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaneCalenderDayComponent);

export default PlaneCalenderDay;