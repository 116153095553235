import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import SwaggerUtility from "../../SwaggerUtility";
import {logger} from "../../Utilities";
import Form from "react-bootstrap/Form";
import AutoCompleteInput from "../../AutoCompleteInput";
import poweredByGoogleLogo from "../../images/powered_by_google_on_white.png";
import FormError from "../../FormError";
import validateFields from "../../utils";
import Button from "react-bootstrap/Button";



class PlaneLocationChange extends React.Component {

    componentDidMount() {
        const componentSelf = this;
        let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
        swaggerClient.then((client) => {
            client.apis
                .aircraft.aircraft_location_read(
                {id:this.props.planeId}
            )
                .then(function (result) {
                    if (result.ok) {
                        logger("Got Existing Plane Location");
                        logger(result);
                        logger(result.obj);
                        componentSelf.setState(result.obj)
                    }

                }).catch(function (error) {
                logger(error)
            })
        }).catch(function (error) {
            logger(error)
        })
    }

    constructor(props){
        super(props);
        this.state = {
            locationName: null,
            locationId: null,
            placeId: null,
            showChangeLocation:false,
            editLocationName:null,
            editLocationId:null,
            editPlaceId:null,
            errors:{}
        }
    }

    onChangePilotAirport = (locationInfo) => {
        console.log('GOT NEW LOCATION')
        console.log(locationInfo)
        this.setState({
            editLocationId:locationInfo.id,
        editPlaceId:locationInfo.place_id,
        editLocationName:locationInfo.description});
    };

    updatePlaneLocation = () => {
        console.log(this.state);
        let result = validateFields(this.state, "aircraftLocationForm");
        logger("form is");
        logger(result[0]);
        const componentSelf = this;
        this.setState({errors: result[1]});
        if (result[0]) {
            let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
            let aircraftLocationData = Object.assign({},this.state);
            aircraftLocationData["locationName"] =  aircraftLocationData["editLocationName"];
            aircraftLocationData["locationId"] =    aircraftLocationData["editLocationId"];
            aircraftLocationData["placeId"] =       aircraftLocationData["editPlaceId"];
            aircraftLocationData["id"] =       this.props.planeId;
            logger("aircraftLocationData");
            logger(aircraftLocationData);
            swaggerClient.then((client) => {
                client.apis
                    .aircraft.aircraft_location_update(
                    aircraftLocationData
                )
                    .then(function (result) {
                        if (result.ok) {
                            logger("Updated Aircraft Location");
                            logger(result);
                            logger(result.obj);
                            componentSelf.setState(
                                {
                                    locationName: result.obj.locationName,
                                    locationId: result.obj.locationId,
                                    placeId: result.obj.placeId
                                }
                            );
                        }
                    }).catch(function (error) {
                    logger(error)
                })
            }).catch(function (error) {
                logger(error)
            })
        }
    };

    render() {
        let showLocationHtml = null;
        if(this.state.showChangeLocation){
            showLocationHtml = (
                <div>
                <Form.Group>
                    <Form.Label className="formLabel greyText">
                        Plane Airport
                    </Form.Label>
                    <AutoCompleteInput initValue={this.state.editLocationName} onChange={this.onChangePilotAirport} parentElement="profile"/>
                    <img src={poweredByGoogleLogo}></img>
                    <FormError formField="editLocationName" errors = {this.state.errors} />
                </Form.Group>
                <Button variant="primary" onClick={this.updatePlaneLocation}>Update Location</Button>
                </div>

            )
        }
        return (
            <div className="planePriceCalendar">
                <h3 className="blueText plane-admin-heading"> Plane Airport </h3>
                <p>{this.state.locationName}</p>
                <p className="add-unavailable-link"
                onClick={(e) => this.setState({showChangeLocation: !this.state.showChangeLocation})}>Change</p>
                {showLocationHtml}
            </div>
        )
    }

}

export default PlaneLocationChange;