
import { combineReducers } from 'redux';
import { logger } from './Utilities';

import {
    SET_AIRCRAFT_PERFORMANCE_SPECS,
    SET_AIRCRAFT_AVIONICS,
    SET_USERNAME,
    SET_AIRCRAFT_DESCRIPTION,
    SET_AIRCRAFT_GAUGES,
    SET_AIRCRAFT_LOCATION,
    SET_AIRCRAFT_TOTAL_TIME,
    SET_AIRCRAFT_MODEL,
    SET_AIRCRAFT_MAKE,
    SET_AIRCRAFT_YEAR,
    LOGOUT,
    initialState,
    SET_PROFILE,
    SET_AUTHENTICATED,
    SET_SIGNUP_BIRTHDAY_MONTH,
    SET_SIGNUP_BIRTHDAY_DAY,
    SET_SIGNUP_BIRTHDAY_YEAR,
    SET_SIGNUP_PHONE_NUMBER,
    SET_SIGNUP_STATE,
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_PASSWORD,
    SET_TOKEN,
    SET_SIGNUP_FIRST_NAME,
    SET_SIGNUP_LAST_NAME,
    SET_SIGNUP_PASSWORD,
    SET_SIGNUP_USERNAME,
    SET_SIGNUP_ADDRESS,
    SET_SIGNUP_ADDRESS_TWO,
    SET_SIGNUP_CITY,
    SET_SIGNUP_CONFIRM_PASSWORD,
    SET_SIGNUP_ZIPCODE,
    SET_AIRCRAFT_INTERIOR,
    SET_AIRCRAFT_MEDICAL,
    SET_AIRCRAFT_AV1AES,
    SET_AIRCRAFT_LICENSE,
    SET_AIRCRAFT_ARROW,
    SET_AIRCRAFT_EXTERIOR,
    SET_PILOT_HAS_INSURANCE,
    SET_PILOT_TOTAL_TIME,
    SET_PILOT_TOTAL_IN_COMMAND_TIME,
    SET_PILOT_HOME_AIRPORT,
    SET_PILOT_INSURANCE_POLICY_NUMBER,
    SET_PILOT_INSURANCE_CARRIER,
    SET_PILOT_INSURANCE_EXPIRATION_YEAR,
    SET_PILOT_INSURANCE_EXPIRATION_MONTH,
    SET_PILOT_INSURANCE_EXPIRATION_DAY,
    SET_PILOT_INSURANCE_ISSUE_YEAR,
    SET_PILOT_INSURANCE_ISSUE_MONTH,
    SET_PILOT_CERTIFICATIONS,
    SET_PILOT_INSURANCE_ISSUE_DAY,
    SET_PILOT_RATINGS_AND_ENDORSEMENTS,
    SET_MEDICAL_ISSUE_DAY, SET_MEDICAL_ISSUE_MONTH,
    SET_MEDICAL_ISSUE_YEAR,
    SET_MEDICAL_CLASS,
    SET_AIRCRAFT_CATEGORY,
    SET_PROFILE_PICTURE,SET_CALENDER_DAY,
    SET_SHOW_EDIT, SET_SHOW_AVAIL,SET_CUSTOM_UNAVAIL,SET_NEW_PRICE, SET_NEW_UNAVAIL, SET_ALERT,TOGGLE_ALERT

} from './actions.tsx'
import {SET_CUSTOM_PLANE_PRICE} from "./actions";

function requestAlert(state=initialState.requestAlert,action){
    switch(action.type){
        case SET_ALERT:
            return Object.assign({}, state, {
                error:action.text.picture,
                message:action.text.user,
                show:action.text.show,
            });
        case TOGGLE_ALERT:
            return Object.assign({}, state, {
                error:null,
                message:null,
                show:action.text.show,
            });
        default:
            return state;
    }
}

function profilePicture(state=initialState.profilePicture,action){
    switch(action.type){
        case SET_PROFILE_PICTURE:
            logger("profile picture action");
            logger(action);
            return Object.assign({}, state, {
                picture:action.text.picture,
                user:action.text.user,
                id:action.text.id,
            });
        default:
            return state;
    }
}

function profile(state=initialState.profile,action){
    switch(action.type){
        case SET_USERNAME:
          return Object.assign({}, state, {
            username: action.text
          });
        case SET_LAST_NAME:
            return Object.assign({}, state, {
              lastName: action.text
            });
        case SET_FIRST_NAME:
          return Object.assign({}, state, {
            firstName: action.text
          });
       case SET_PASSWORD:
          return Object.assign({}, state, {
            password: action.text
          });
        case SET_TOKEN:
          return Object.assign({}, state, {
            token: action.text
          });
        case SET_AUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: action.boolean
            });
        case SET_PROFILE:
            logger(action);
            logger("setting prof with above params");
            return Object.assign({}, state, {
                username:action.profile.username,
                lastName:action.profile.lastName,
                firstName:action.profile.firstName,
                isAuthenticated: true,
                token:action.profile.token,
                id:action.profile.id,
                emailVerified:action.profile.emailVerified,
                idVerificationStatus:action.profile.idVerificationStatus,
                is_staff:action.profile.is_staff
            });
        case LOGOUT:
            return Object.assign({}, state, {
                username:null,
                lastName:null,
                firstName:null,
                isAuthenticated: null,
                token:null,
                id:null
            });
        default:
            return state;
    }
}

function signup(state=initialState.signUpInformation,action){
  switch(action.type){
      case SET_SIGNUP_USERNAME:
        return Object.assign({}, state, {
          username: action.text
        });
      case SET_SIGNUP_LAST_NAME:
          return Object.assign({}, state, {
            lastName: action.text
          });
      case SET_SIGNUP_FIRST_NAME:
        return Object.assign({}, state, {
          firstName: action.text
        });
     case SET_SIGNUP_PASSWORD:
        return Object.assign({}, state, {
          password: action.text
        });
     case SET_SIGNUP_BIRTHDAY_DAY:
          return Object.assign({}, state, {
            birthdayDay: action.text
          });
     case SET_SIGNUP_BIRTHDAY_MONTH:
       return Object.assign({}, state, {
         birthdayMonth: action.text
       });
     case SET_SIGNUP_BIRTHDAY_YEAR:
         return Object.assign({}, state, {
           birthdayYear: action.text
         });
      case SET_PILOT_TOTAL_TIME:
          return Object.assign({}, state, {
              totalTime: action.input
          });
      case SET_PILOT_TOTAL_IN_COMMAND_TIME:
          return Object.assign({}, state, {
              totalPilotInCommandTime: action.input
          });
     case SET_SIGNUP_PHONE_NUMBER:
       return Object.assign({}, state, {
         mobileNumber: action.text
       });
       case SET_SIGNUP_ADDRESS:
        return Object.assign({}, state, {
          address: action.text
        });
     case SET_SIGNUP_ADDRESS_TWO:
        return Object.assign({}, state, {
          addressTwo: action.text
        });
      case SET_SIGNUP_CITY:
        return Object.assign({}, state, {
          city: action.text
        });
      case SET_SIGNUP_STATE:
        return Object.assign({}, state, {
          state: action.text
        });
      case SET_SIGNUP_ZIPCODE:
        return Object.assign({}, state, {
          zipcode: action.text
        });
      case SET_SIGNUP_CONFIRM_PASSWORD:
              return Object.assign({}, state, {
                confirmPassword: action.text
              });

      case SET_PILOT_HOME_AIRPORT:
          logger("action");
          logger(action);
          logger(action.pilotAirportLocationInfo);
          return Object.assign({}, state, {
              pilotAirportLocationId: action.pilotAirportLocationInfo.place_id,
              pilotAirportLocationName: action.pilotAirportLocationInfo.description,
          });
      case SET_PILOT_CERTIFICATIONS:
          logger("certifications");
          logger(action);
          return Object.assign({}, state, {
              certifications: action.text
          });
      case SET_PILOT_RATINGS_AND_ENDORSEMENTS:
          return Object.assign({}, state, {
              ratingsAndEndorsements: action.text
          });
      case SET_PILOT_INSURANCE_EXPIRATION_DAY:
          return Object.assign({}, state, {
              pilotInsuranceExpirationDay: action.text
          });
      case SET_PILOT_INSURANCE_EXPIRATION_MONTH:
          return Object.assign({}, state, {
              pilotInsuranceExpirationMonth: action.text
          });
      case SET_PILOT_INSURANCE_EXPIRATION_YEAR:
          return Object.assign({}, state, {
              pilotInsuranceExpirationYear: action.text
          });
      case SET_PILOT_INSURANCE_ISSUE_DAY:
          return Object.assign({}, state, {
              pilotInsuranceIssueDay: action.text
          });
      case SET_PILOT_INSURANCE_ISSUE_MONTH:
          return Object.assign({}, state, {
              pilotInsuranceIssueMonth: action.text
          });
      case SET_PILOT_INSURANCE_ISSUE_YEAR:
          return Object.assign({}, state, {
              pilotInsuranceIssueYear: action.text
          });
      case SET_MEDICAL_ISSUE_DAY:
          return Object.assign({}, state, {
              medicalIssueDay: action.text
          });
      case SET_MEDICAL_ISSUE_MONTH:
          return Object.assign({}, state, {
              medicalIssueMonth: action.text
          });
      case SET_MEDICAL_ISSUE_YEAR:
          return Object.assign({}, state, {
              medicalIssueYear: action.text
          });
      case SET_MEDICAL_CLASS:
          return Object.assign({}, state, {
              medicalClass: action.text
          });
      case SET_PILOT_INSURANCE_CARRIER:
          return Object.assign({}, state, {
              insuranceCarrier: action.text
          });
      case SET_PILOT_INSURANCE_POLICY_NUMBER:
          return Object.assign({}, state, {
              insurancePolicyNumber: action.text
          });
      case SET_PILOT_HAS_INSURANCE:
          return Object.assign({}, state, {
              hasInsurance: action.text
          });
       case SET_AIRCRAFT_CATEGORY:
          return Object.assign({}, state, {
              aircraftCategory: action.text
          });
      default:
          return state;
  }
}


function aircraftInfo(state=initialState.aircraftListInformation,action){
    switch(action.type){
        case SET_AIRCRAFT_YEAR:
            return Object.assign({}, state, {
                year: action.text
            });
        case SET_AIRCRAFT_MAKE:
            return Object.assign({}, state, {
                make: action.text
            });
        case SET_AIRCRAFT_MODEL:
            return Object.assign({}, state, {
                model: action.text
            });
        case SET_AIRCRAFT_TOTAL_TIME:
            return Object.assign({}, state, {
                totalTime: action.text
            });
        case SET_AIRCRAFT_LOCATION:
            logger("action");
            logger(action.aircraftLocationInfo);
            const placeInfo = action.aircraftLocationInfo;
            return Object.assign({}, state, {
                locationId: placeInfo.place_id,
                locationName: placeInfo.description,
            });
        case SET_AIRCRAFT_GAUGES:
            return Object.assign({}, state, {
                steamGauges: action.text
            });
        case SET_AIRCRAFT_DESCRIPTION:
            return Object.assign({}, state, {
                description: action.text
            });
        case SET_AIRCRAFT_AVIONICS:
            return Object.assign({}, state, {
                avionics: action.text
            });
        case SET_AIRCRAFT_PERFORMANCE_SPECS:
            return Object.assign({}, state, {
                performanceSpecifications: action.text
            });
        case SET_AIRCRAFT_INTERIOR:
            return Object.assign({}, state, {
                interior: action.imageList
            });
        case SET_AIRCRAFT_EXTERIOR:
            return Object.assign({}, state, {
                exterior: action.imageList
            });
        case SET_AIRCRAFT_MEDICAL:
            return Object.assign({}, state, {
                 medical: action.imageList
            });
        case SET_AIRCRAFT_ARROW:
            return Object.assign({}, state, {
                arrow: action.imageList
            });
        case SET_AIRCRAFT_AV1AES:
            return Object.assign({}, state, {
                av1aes: action.imageList
            });
        case SET_AIRCRAFT_LICENSE:
            return Object.assign({}, state, {
                license: action.imageList
            });
        default:
            return state;
    }
}

function planeAdminCalendar(state=initialState.planeAdminCalendar,action){
    switch(action.type){
        case SET_CALENDER_DAY:
            console.log('action',action.action);
            console.log('action',action.action.date);
            console.log('action',action.action.price);
            console.log('action',action.action.planeId);
            return Object.assign({}, state, {
                date: action.action.date,
                price: action.action.price,
                hasData: true,
                showEditPrice:false,
                planeId:action.action.planeId,
                hasUnavailability: action.action.hasUnavailability,
                unavailabilityDateTimes: action.action.unavailabilityDateTimes
            });
        case SET_SHOW_EDIT:
            console.log("SHOW ACTION EDIT");
            console.log(action);
            return Object.assign({}, state, {
                showEditPrice:action.action.showEditPrice
            });
        case SET_SHOW_AVAIL:
            console.log("SHOW AVAIL EDIT");
            console.log(action);
            return Object.assign({}, state, {
                showEditAvailability:action.action.showEditAvailability
            });
        case SET_NEW_PRICE:
            console.log("SET NEW PRICE");
            console.log(action);
            return Object.assign({}, state, {
                price:action.action.price
            });
        case  SET_NEW_UNAVAIL:
            console.log('SET UNAVAIL');
            console.log(action);
            return Object.assign({}, state, {
                unavailabilityDateTimes:action.action.dateTimes,
                hasUnavailability:action.action.hasUnavailability,
            });
        default:
            return state;
    }
}

function customPlanePrices(state=initialState.customPlanePrices,action){
    switch(action.type){
        case SET_CUSTOM_PLANE_PRICE:
            console.log('plane price action',action);
            console.log('plane price action action',action.action);
            return Object.assign({}, state, {
                customPrices: {
                    ...state.customPrices, [action.action.airplane]: action.action.dates
                }
            });
        case SET_CUSTOM_UNAVAIL:
            console.log('plane unavail action',action);
            console.log('plane unavail action action',action.action);
            return Object.assign({}, state, {
                customDates: {
                    ...state.customDates, [action.action.airplane]: action.action.dates
                }
            });
        default:
            return state;
    }
}

const skyKandyApp = combineReducers({
    profile,
    signup,
    aircraftInfo,
    profilePicture,
    planeAdminCalendar,
    customPlanePrices,
    requestAlert,
  });
  
  export default skyKandyApp;