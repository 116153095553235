import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import PlaneAdminMenu from "./PlaneAdminMenu";
import PlanePhotoChange from "./PlanePhotoChange";
import {configs} from "../../configurations";
import {logger} from "../../Utilities";
import Button from "react-bootstrap/Button";



class PlanePhoto extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="managed-plane-image">
                <img width="300px" src={this.props.planeImage.image}></img>
                <p>Type: {this.props.planeImage.type}</p>
                <Button variant="danger" onClick={(e) => this.props.handleClick(this.props.planeImage)}>Delete</Button>
            </div>
        )
    }

}

export default PlanePhoto;