import { logger } from './Utilities';

export const SET_USERNAME = 'SET_USERNAME';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_SIGNUP_FIRST_NAME = 'SET_SIGNUP_FIRST_NAME';
export const SET_SIGNUP_LAST_NAME = 'SET_SIGNUP_LAST_NAME';
export const SET_SIGNUP_PASSWORD = 'SET_SIGNUP_PASSWORD';
export const SET_SIGNUP_CONFIRM_PASSWORD = 'SET_SIGNUP_CONFIRM_PASSWORD';
export const SET_SIGNUP_USERNAME = 'SET_SIGNUP_USERNAME';
export const SET_SIGNUP_ADDRESS = 'SET_SIGNUP_ADDRESS';
export const SET_SIGNUP_ADDRESS_TWO = 'SET_SIGNUP_ADDRESS_TWO';
export const SET_SIGNUP_BIRTHDAY_YEAR = 'SET_SIGNUP_BIRTHDAY_YEAR';
export const SET_SIGNUP_BIRTHDAY_MONTH = 'SET_SIGNUP_BIRTHDAY_MONTH';
export const SET_SIGNUP_BIRTHDAY_DAY = 'SET_SIGNUP_BIRTHDAY_DAY';
export const SET_SIGNUP_CITY = 'SET_SIGNUP_CITY';
export const SET_SIGNUP_PHONE_NUMBER = 'SET_SIGNUP_PHONE_NUMBER';
export const SET_SIGNUP_STATE = 'SET_SIGNUP_STATE';
export const SET_SIGNUP_ZIPCODE = 'SET_SIGNUP_ZIPCODE';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_PROFILE = 'SET_PROFILE';
export const LOGOUT = 'LOGOUT';
export const SET_AIRCRAFT_YEAR = 'SET_AIRCRAFT_YEAR';
export const SET_AIRCRAFT_MAKE = 'SET_AIRCRAFT_MAKE';
export const SET_AIRCRAFT_MODEL ='SET_AIRCRAFT_MODEL';
export const SET_AIRCRAFT_TOTAL_TIME = 'SET_AIRCRAFT_TOTAL_TIME';
export const SET_AIRCRAFT_LOCATION = 'SET_AIRCRAFT_LOCATION';
export const SET_AIRCRAFT_GAUGES = 'SET_AIRCRAFT_GAUGES';
export const SET_AIRCRAFT_DESCRIPTION = 'SET_AIRCRAFT_DESCRIPTION';
export const SET_AIRCRAFT_AVIONICS = 'SET_AIRCRAFT_AVIONICS';
export const SET_AIRCRAFT_PERFORMANCE_SPECS = 'SET_AIRCRAFT_PERFORMANCE_SPECS';
export const SET_AIRCRAFT_INTERIOR = 'SET_AIRCRAFT_INTERIOR';
export const SET_AIRCRAFT_EXTERIOR = 'SET_AIRCRAFT_EXTERIOR';
export const SET_AIRCRAFT_AV1AES = 'SET_AIRCRAFT_AV1AES';
export const SET_AIRCRAFT_LICENSE = 'SET_AIRCRAFT_LICENSE';
export const SET_AIRCRAFT_MEDICAL = 'SET_AIRCRAFT_MEDICAL';
export const SET_AIRCRAFT_ARROW = 'SET_AIRCRAFT_ARROW';
export const SET_PILOT_TOTAL_TIME = 'SET_PILOT_TOTAL_TIME';
export const SET_PILOT_TOTAL_IN_COMMAND_TIME = 'SET_PILOT_TOTAL_IN_COMMAND_TIME';
export const SET_PILOT_HOME_AIRPORT = 'SET_PILOT_HOME_AIRPORT';
export const SET_PILOT_CERTIFICATIONS = 'SET_PILOT_CERTIFICATIONS';
export const SET_PILOT_RATINGS_AND_ENDORSEMENTS = 'SET_PILOT_RATINGS_AND_ENDORSEMENTS';
export const SET_PILOT_INSURANCE_ISSUE_DAY = 'SET_PILOT_INSURANCE_ISSUE_DAY';
export const SET_PILOT_INSURANCE_ISSUE_MONTH = 'SET_PILOT_INSURANCE_ISSUE_MONTH';
export const SET_PILOT_INSURANCE_ISSUE_YEAR = 'SET_PILOT_INSURANCE_ISSUE_YEAR';
export const SET_PILOT_INSURANCE_EXPIRATION_DAY = 'SET_PILOT_INSURANCE_EXPIRATION_DAY';
export const SET_PILOT_INSURANCE_EXPIRATION_MONTH = 'SET_PILOT_INSURANCE_EXPIRATION_MONTH';
export const SET_PILOT_INSURANCE_EXPIRATION_YEAR = 'SET_PILOT_INSURANCE_EXPIRATION_YEAR';
export const SET_PILOT_INSURANCE_CARRIER = 'SET_PILOT_INSURANCE_CARRIER';
export const SET_PILOT_INSURANCE_POLICY_NUMBER = 'SET_PILOT_INSURANCE_POLICY_NUMBER';
export const SET_PILOT_HAS_INSURANCE = 'SET_PILOT_HAS_INSURANCE';
export const SET_MEDICAL_ISSUE_DAY = 'SET_MEDICAL_ISSUE_DAY';
export const SET_MEDICAL_ISSUE_MONTH = 'ET_MEDICAL_ISSUE_MONTH';
export const SET_MEDICAL_ISSUE_YEAR = 'SET_MEDICAL_ISSUE_YEAR';
export const SET_MEDICAL_CLASS = 'SET_MEDICAL_CLASS';
export const SET_AIRCRAFT_CATEGORY = 'SET_AIRCRAFT_CATEGORY';
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const SET_CALENDER_DAY = 'SET_CALENDER_DAY';
export const SET_CUSTOM_PLANE_PRICE = 'SET_CUSTOM_PLANE_PRICE';
export const SET_SHOW_EDIT = 'SET_SHOW_EDIT';
export const SET_SHOW_AVAIL = 'SET_SHOW_AVAIL';
export const SET_CUSTOM_UNAVAIL = 'SET_CUSTOM_UNAVAIL';
export const SET_NEW_PRICE = 'SET_NEW_PRICE';
export const SET_NEW_UNAVAIL = 'SET_NEW_UNAVAIL';
export const SET_ALERT = 'SET_ALERT';
export const TOGGLE_ALERT = 'TOGGLE_ALERT';

export const initialState = {
    profile:{
        emailVerified: null,
        username: null,
        firstName:null,
        lastName:null,
        password:null,
        token:null,
        isAuthenticated:false,
        id:null,
        is_staff:null,
    },
    signUpInformation:{
        username: null,
        firstName:null,
        lastName:null,
        mobileNumber:null,
        birthdayDay:"01",
        birthdayMonth:"01",
        birthdayYear:"2019",
        password:null,
        confirmPassword:null,
        address:null,
        addressTwo:null,
        city:null,
        state:null,
        zipcode:null,
        totalTime:null,
        totalPilotInCommandTime:null,
        pilotAirportLocationId:null,
        pilotAirportLocationName:null,
        certifications:[],
        ratingsAndEndorsements:[],
        pilotInsuranceExpirationDay:"01",
        pilotInsuranceExpirationMonth:"01",
        pilotInsuranceExpirationYear:"1920",
        pilotInsuranceIssueDay:"01",
        pilotInsuranceIssueMonth:"01",
        pilotInsuranceIssueYear:"1920",
        insuranceCarrier:null,
        insurancePolicyNumber:null,
        hasInsurance:null,
        medicalClass:null,
        medicalClassIssueDay:"01",
        medicalClassIssueMonth:"01",
        medicalClassIssueYear:"1920",
        idVerificationStatus:"Need To Upload",
        aircraftCategory:[]
    },
    aircraftListInformation:{
        year:null,
        make:null,
        model:null,
        totalTime:null,
        locationId:null,
        steamGauges:null,
        description:null,
        avionics:null,
        performanceSpecifications:null,
        latitude:null,
        longitude:null,
        locationName:null,
        interior:[],
        exterior:[],
        medical:[],
        arrow:[],
        av1aes:[],
        license: [],
        owner:[]
    },
    profilePicture:{
        picture:null,
        user:null,
        id:null,
    },
    planeAdminCalendar:{
        price: 0,
        date: null,
        hasData: false,
        showEditPrice: false,
        planeId:null,
        showEditAvailability:false,
        hasUnavailability: false,
        unavailabilityDateTimes: []
    },
    customPlanePrices: {
        customPrices:{},
        customDates:{}
    },
    requestAlert: {
        message:null,
        error:false,
        show:false
    }
};

export function setSignupUsername(text: any){
    return {
        type: SET_SIGNUP_USERNAME,
        text
    }
}

export function setSignupFirstName(text: any){
    return {
        type: SET_SIGNUP_FIRST_NAME,
        text
    }
}
export function setSignupLastName(text: any){
    return {
        type: SET_SIGNUP_LAST_NAME,
        text
    }
}
export function setSignupBirthdayDay(text: any){
    return {
        type: SET_SIGNUP_BIRTHDAY_DAY,
        text
    }
}
export function setSignupBirthdayMonth(text: any){
    return {
        type: SET_SIGNUP_BIRTHDAY_MONTH,
        text
    }
}
export function setSignupUseBirthdayYear(text: any){
    return {
        type: SET_SIGNUP_BIRTHDAY_YEAR,
        text
    }
}
export function setSignupMobileNumber(text: any){
    return {
        type: SET_SIGNUP_PHONE_NUMBER,
        text
    }
}
export function setSignUpPassword(text: any){
    return {
        type: SET_SIGNUP_PASSWORD,
        text
    }
}
export function setSignUpConfirmPassword(text: any){
    return {
        type: SET_SIGNUP_CONFIRM_PASSWORD,
        text
    }
}
export function setSignupAddress(text: any){
    return {
        type: SET_SIGNUP_ADDRESS,
        text
    }
}
export function setSignupAddressTwo(text: any){
    return {
        type: SET_SIGNUP_ADDRESS_TWO,
        text
    }
}
export function setSignupCity(text: any){
    return {
        type: SET_SIGNUP_CITY,
        text
    }
}
export function setSignupState(text: any){
    return {
        type: SET_SIGNUP_STATE,
        text
    }
}
export function setSignUpZipcode(text: any){
    return {
        type: SET_SIGNUP_ZIPCODE,
        text
    }
}

export function setUsername(text: any){
    return {
        type: SET_USERNAME,
        text
    }
}

export function setPassword(text: any){
    return {
        type: SET_PASSWORD,
        text
    }
}

export function setFirstName(text: any){
    return {
        type: SET_FIRST_NAME,
        text
    }
}

export function setLastName(text: any){
    return {
        type: SET_LAST_NAME,
        text
    }
}

export function setToken(text: any){
    return {
        type: SET_TOKEN,
        text
    }
}

export function setAuthenticated(boolean: boolean){
    return {
        type: SET_AUTHENTICATED,
        boolean
    }
}

export function setProfile(profile: any){
    return {
        type: SET_PROFILE,
        profile
    }
}

export function setAircraftMake(text: any){
    return {
        type: SET_AIRCRAFT_MAKE,
        text
    }
}

export function setAircraftYear(text: any){
    return {
        type: SET_AIRCRAFT_YEAR,
        text
    }
}
export function setAircraftModel(text: any){
    return {
        type: SET_AIRCRAFT_MODEL,
        text
    }
}
export function setAircraftTotalTime(text: any){
    return {
        type: SET_AIRCRAFT_TOTAL_TIME,
        text
    }
}
export function setAircraftLocation(aircraftLocationInfo: any){
    logger("received aircraft location");
    logger(aircraftLocationInfo);
    return {
        type: SET_AIRCRAFT_LOCATION,
        aircraftLocationInfo
    }
}
export function setAircraftGauges(text: any){
    return {
        type: SET_AIRCRAFT_GAUGES,
        text
    }
}
export function setAircraftDescription(text: any){
    return {
        type: SET_AIRCRAFT_DESCRIPTION,
        text
    }
}

export function setAircraftAvionics(text: any){
    return {
        type: SET_AIRCRAFT_AVIONICS,
        text
    }
}

export function setAircraftPerformanceSpecs(text: any){
    return {
        type: SET_AIRCRAFT_PERFORMANCE_SPECS,
        text
    }
}

export function setAircraftInterior(imageList: any){
    return {
        type: SET_AIRCRAFT_INTERIOR,
        imageList
    }
}

export function setAircraftExterior(imageList: any){
    return {
        type: SET_AIRCRAFT_EXTERIOR,
        imageList
    }
}
export function setAircraftArrow(imageList: any){
    return {
        type: SET_AIRCRAFT_ARROW,
        imageList
    }
}
export function setAircraftAv1aes(imageList: any){
    return {
        type: SET_AIRCRAFT_AV1AES,
        imageList
    }
}
export function setAircraftLicense(imageList: any){
    return {
        type: SET_AIRCRAFT_LICENSE,
        imageList
    }
}

export function setAircraftMedical(imageList: any){
    return {
        type: SET_AIRCRAFT_MEDICAL,
        imageList
    }
}

export function setPilotTotalTime(input: any){
    return {
        type: SET_PILOT_TOTAL_TIME,
        input
    }
}

export function setPilotTotalInCommandTime(input: any){
    return {
        type: SET_PILOT_TOTAL_IN_COMMAND_TIME,
        input
    }
}

export function setPilotCertifications(text: any){
    return {
        type: SET_PILOT_CERTIFICATIONS,
        text
    }
}

export function setPilotRatingsAndEndorsements(text: any){
    return {
        type: SET_PILOT_RATINGS_AND_ENDORSEMENTS,
        text
    }
}

export function setPilotInsuranceIssueDay(text: any){
    return {
        type: SET_PILOT_INSURANCE_ISSUE_DAY,
        text
    }
}

export function setPilotInsuranceIssueMonth(text: any){
    return {
        type: SET_PILOT_INSURANCE_ISSUE_MONTH,
        text
    }
}
export function setPilotInsuranceIssueYear(text: any){
    return {
        type: SET_PILOT_INSURANCE_ISSUE_YEAR,
        text
    }
}

export function setPilotInsuranceExpirationDay(text: any){
    return {
        type: SET_PILOT_INSURANCE_EXPIRATION_DAY,
        text
    }
}

export function setPilotInsuranceExpirationMonth(text: any){
    return {
        type: SET_PILOT_INSURANCE_EXPIRATION_MONTH,
        text
    }
}
export function setPilotInsuranceExpirationYear(text: any){
    return {
        type: SET_PILOT_INSURANCE_EXPIRATION_YEAR,
        text
    }
}
export function setMedicalClassIssueYear(text: any){
    return {
        type: SET_MEDICAL_ISSUE_YEAR,
        text
    }
}

export function setMedicalClassIssueDay(text: any){
    return {
        type: SET_MEDICAL_ISSUE_DAY,
        text
    }
}

export function setMedicalClassIssueMonth(text: any){
    return {
        type: SET_MEDICAL_ISSUE_MONTH,
        text
    }
}

export function setMedicalClass(text: any){
    return {
        type: SET_MEDICAL_CLASS,
        text
    }
}

export function setPilotInsuranceCarrier(text: any){
    return {
        type: SET_PILOT_INSURANCE_CARRIER,
        text
    }
}
export function setPilotInsurancePolicyNumber(text: any){
    return {
        type: SET_PILOT_INSURANCE_POLICY_NUMBER,
        text
    }
}


export function setPilotProfileLocation(pilotAirportLocationInfo: any){
    return {
        type: SET_PILOT_HOME_AIRPORT,
        pilotAirportLocationInfo
    }
}

export function setPilotHasInsurance(text: any){
    return {
        type: SET_PILOT_HAS_INSURANCE,
        text
    }
}

export function setAircraftCategory(text: any){
    return {
        type: SET_AIRCRAFT_CATEGORY,
        text
    }
}

export function setProfilePicture(text: any){
    if(text.picture.includes('://')){
        const textList = text.picture.split('://');
        text.picture = textList[1];
    }
    return {
        type: SET_PROFILE_PICTURE,
        text
    }
}

export function setCalenderDay(action: any){
    return {
        type: SET_CALENDER_DAY,
        action
    }
}

export function setCustomPlanePrice(action: any){
    return {
        type: SET_CUSTOM_PLANE_PRICE,
        action
    }
}

export function setShowEdit(action: any){
    return {
        type: SET_SHOW_EDIT,
        action
    }
}

export function setShowAvail(action: any){
    return {
        type: SET_SHOW_AVAIL,
        action
    }
}

export function setCustomPlaneUnavail(action: any) {
    return {
        type: SET_CUSTOM_UNAVAIL,
        action
    }
}

export function setNewPrice(action: any){
    return {
        type: SET_NEW_PRICE,
        action
    }
}

export function setRWUnavail(action: any){
    return {
        type: SET_NEW_UNAVAIL,
        action
    }
}

export function setRequestAlert(text: any){
    return {
        type: SET_ALERT,
        text
    }
}

export function setAlertToggle(text: any){
    return {
        type: TOGGLE_ALERT,
        text
    }
}

export function logout(){
    return {
        type: LOGOUT
    }
}

