import React from 'react';
import './App.css';
import SignUp from './SignUp.js';
import Login from './Login.js';
import Profile from './Profile.js';
import ListYourPlane from './ListYourPlane.js';
import Planes from './Components/Host/Planes';
import AdminPlanePage from './Components/PlaneAdmin/AdminPlanePage.js';
import PilotSignup from './Components/PilotSignup.js';
import SiteAdmin from './Components/Admin/SiteAdmin.js';
import UploadProfilePicture from './Components/UploadProfilePicture';
import VerifyId from './VerifyId.js';
import Home from './Home.js';
import { logger } from './Utilities';
// import HostPlanes from './HostPlanes.js';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'; // Changes image
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Register the plugins
import EmailTokenValidated from './EmailTokenValidated.js';
import { Route, Switch,Redirect,BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import skyKandyApp from './reducers';
import 'react-dates/initialize';
import { unregister } from './serviceWorker';
import PlaneLocation from "./Components/PlaneAdmin/PlaneLocation";
import PlanePhotos from "./Components/PlaneAdmin/PlanePhotos";
import GuestInstructions from "./Components/PlaneAdmin/GuestInstructions";
import Guidelines from "./Components/PlaneAdmin/Guidelines";
import PlaneDescription from "./Components/PlaneAdmin/PlaneDescription";
import PlanePricing from "./Components/PlaneAdmin/PlanePricing";
import PlanePreferences from "./Components/PlaneAdmin/PlanePreferences";
import PlaneExtras from "./Components/PlaneAdmin/PlaneExtras";
registerPlugin(FilePondPluginImageExifOrientation,FilePondPluginImagePreview,FilePondPluginFileValidateType);

const store = createStore(skyKandyApp);
unregister();
function App() {

  function getLoggedIn(){
    let authenticated = store.getState().profile.isAuthenticated;
    if(!authenticated){
      if(localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null){
        authenticated = true;
      }
    }
    logger("is user authenticated");
    logger(authenticated);
    return authenticated
  }

  return (
    <Provider store={store}>
        <Router>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/signup/:profileType" render={(props) => (
                    getLoggedIn() ? (
                        <Redirect to="/profile"/>
                    ) : (
                        <SignUp profileType={props.match.params.profileType}/>
                    ))}/>
                <Route path="/login" render={() => (
                    getLoggedIn() ? (
                        <Redirect to="/profile"/>
                    ) : (
                        <Login/>
                    ))}/>
                <Route path="/admin" render={() => (
                    getLoggedIn()? (
                        <SiteAdmin />
                    ) : (
                        <Login/>
                ))}/>
                <Route path="/profile" render={() => (
                    getLoggedIn() ? (
                        <Profile/>
                    ) : (
                    <Redirect to="/"/>
                    ))}/>
                <Route path="/list-your-plane" render={() => (
                      getLoggedIn() ? (
                          <ListYourPlane/>
                      ) : (
                        <Redirect to="/"/>
                      ))}/>
                <Route path="/hostPlanes" render={() => (
                    getLoggedIn() ? (
                        <Planes/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                  <Route path="/validateEmail/:token" render={(props) => (
                      getLoggedIn() ? (
                          <EmailTokenValidated token={props.match.params.token}/>
                      ) : (
                          <Redirect to="/"/>
                      ))}/>
                <Route path="/verifyId" render={(props) => (
                    getLoggedIn() ? (
                        <VerifyId/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/pilotSignup" render={() => (
                    getLoggedIn() ? (
                        <PilotSignup/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/changeProfilePicture" render={() => (
                    getLoggedIn() ? (
                        <UploadProfilePicture/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/calendar/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <AdminPlanePage planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/location/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <PlaneLocation planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/photos/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <PlanePhotos planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/guestInstructions/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <GuestInstructions planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/description/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <PlaneDescription planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/guidelines/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <Guidelines planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/pricing/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <PlanePricing planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/preferences/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <PlanePreferences planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                <Route path="/planeAdmin/extras/:planeId" render={(props) => (
                    getLoggedIn() ? (
                        <PlaneExtras planeId={props.match.params.planeId}/>
                    ) : (
                        <Redirect to="/"/>
                    ))}/>
                {/*<Route path="/hostPlanes" render={(props) => (*/}
                    {/*getLoggedIn() ? (*/}
                        {/*<HostPlanes/>*/}
                    {/*) : (*/}
                        {/*<Redirect to="/"/>*/}
                    {/*))}/>*/}
                  <Route component={Login}/>
            </Switch>
    </Router>

    </Provider>
  );
}

export default App;
