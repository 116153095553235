import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import PlaneAdminMenu from "./PlaneAdminMenu";
import GuestInstructionsUpdate from "./GuestInstructionsUpdate";



class GuestInstructions extends React.Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Header>
                <Container fluid={true}>
                    <PlaneAdminMenu planeId={this.props.planeId} selected="guestInstructions"></PlaneAdminMenu>
                    <GuestInstructionsUpdate planeId={this.props.planeId}></GuestInstructionsUpdate>
                </Container>
            </Header>
        )
    }

}

export default GuestInstructions;