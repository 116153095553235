import Modal from 'react-bootstrap/Modal'
import React from "react";
import Button from "react-bootstrap/Button";
import SwaggerUtility from './SwaggerUtility';
import { logger } from './Utilities';
class  BootstrapModal extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            show:false,
        }
    }

    createEmailToken = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    sendVerificationEmail = () => {
                logger("Sending validation email");
                const userId = this.props.userId;
                let swaggerClient = SwaggerUtility(localStorage.getItem('token'));
                const token = this.createEmailToken(20);
                logger({
                    token:token,
                    user:userId
                });
                const componentSelf = this;
                swaggerClient.then((client) => {
                    client.apis
                        .validateEmail.validateEmail_create(
                        {data:{
                                token:token,
                                user:userId
                            }}
                    )
                        .then(function (result) {
                            if (result.ok) {
                                logger("Created Email Token");
                                logger(result);
                                logger(result.obj);
                            }
                        }).catch(function (error) {
                        logger(error)
                    })
                }).catch(function (error) {
                    logger(error)
                });
                componentSelf.setShow(false);
            };


    handleClose = () => this.setShow(false);
    handleShow = () => {
        this.setShow(true)
    };
    setShow = (boolean) => this.setState({show:boolean});
    render() {
        return (
            <>
                <div className="blueText linkText" onClick={this.handleShow}>
                    {this.props.toggleText}
                </div>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.props.modalText}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.sendVerificationEmail}>
                            Send Verification Email
                        </Button>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default BootstrapModal;