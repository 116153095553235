import React from "react";
import loadingCircle from "../../images/loadingCircle.gif";

export default class LoadingGif extends React.Component {
    render(){
        if(this.props.show){
            return <img className="loadingGif" src={loadingCircle} />
        }
        else {
            return null
        }
    }
}
