import React from "react";
import Header from "../../Header";
import Container from "react-bootstrap/Container";
import PlaneAdminMenu from "./PlaneAdminMenu";
import PlanePricingUpdate from "./PlanePricingUpdate";

class PlanePricing extends React.Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Header>
                <Container fluid={true}>
                    <PlaneAdminMenu planeId={this.props.planeId} selected="Pricing"></PlaneAdminMenu>
                    <PlanePricingUpdate planeId={this.props.planeId}></PlanePricingUpdate>
                </Container>
            </Header>
        )
    }

}

export default PlanePricing;